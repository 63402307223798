import { LazyLoadImage as Image } from "react-lazy-load-image-component";
import React, { useRef, useState } from "react";
import { Box, Grid } from "@mui/material";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import _ from "lodash";
import EmptyImage from "assets/img/empty_space.svg";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";

// import required modules

import { Autoplay, Navigation, Pagination, Mousewheel, Keyboard } from "swiper";
import { useNavigate } from "react-router-dom";

const maxWidth = 1170;

export default function ResponsiveSwiper(props) {
  return (
    <>
      <Grid
        container
        maxWidth={maxWidth}
        sx={{
          display: {
            xs: "none",
            md: "block",
          },
          aspectRatio: "3/1",
          ...props.sx,
        }}
        {..._.omit(props, ["swiperProps", "sx"])}
      >
        <CustomSwiper data={props.data} {...props.swiperProps} />
      </Grid>

      <Grid
        container
        maxWidth={maxWidth}
        sx={{
          display: { xs: "block", md: "none" },
          p: 1,
          aspectRatio: "16/9",
          ...props.sx,
        }}
        {..._.omit(props, ["swiperProps", "sx"])}
      >
        <CustomSwiper
          navigation={false}
          data={props.data}
          {...props.swiperProps}
        />
      </Grid>
    </>
  );
}

function CustomSwiper(props) {
  const navigate = useNavigate();
  return (
    <Swiper
      navigation
      updateOnWindowResize
      spaceBetween={30}
      pagination={{
        clickable: true,
      }}
      centeredSlides
      autoplay={
        props.autoplay ?? {
          delay: 4500,
          disableOnInteraction: false,
        }
      }
      modules={[Autoplay, Navigation, Pagination, Keyboard]}
      className="mySwiper"
      {...props}
    >
      {props.data?.[0] ? (
        props.data?.map((e) => (
          <SwiperSlide onClick={() => navigate(e?.to)}>
            <Box
              component={Image}
              src={e?.img ?? e?.image_url ?? e}
              sx={{
                cursor: "pointer",
                objectFit: props.objectFit ?? { xs: "cover", md: "contain" },
              }}
            />
          </SwiperSlide>
        ))
      ) : (
        <SwiperSlide onClick={() => navigate(props?.to)}>
          <Box
            component={Image}
            src={EmptyImage}
            sx={{
              cursor: "pointer",
              objectFit: props.objectFit ?? { xs: "cover", md: "contain" },
            }}
          />
        </SwiperSlide>
      )}
    </Swiper>
  );
}
