import { useQuery } from "react-query";
import * as spaceService from "services/space.service";
import spaceStore from "common/stores/space.store";
import _ from "lodash";

export const useSpaceInfo = (spaceId) => {
  const setSpace = spaceStore((state) => state.setSpace);

  return useQuery({
    queryKey: ["spaceDetail", spaceId],
    queryFn: () => spaceService.getSpaceInfo({ space_id: spaceId }),
    onSuccess: (space) => {
      if (!_.isEmpty(space)) setSpace(space);
    },
  });
};

export const useSpace = (payload) => {
  const setSpaces = spaceStore((e) => e.setSpaces);

  return useQuery({
    queryKey: ["getSpace", JSON.stringify(payload)],
    queryFn: () => spaceService.getSpace(payload),
    onSuccess: (spaces) => {
      if (!_.isEmpty(spaces)) setSpaces(spaces);
    },
  });
};

export const useSpaceAvailableSpace = (spaceId) => {
  const setAvailableSpace = spaceStore((e) => e.setAvailableSpace);

  return useQuery({
    queryKey: ["getAvailableSpace", spaceId],
    queryFn: () =>
      spaceService.getAvailableSpace({
        space_id: spaceId,
        fully_booked: false,
      }),
    enabled: !!spaceId,
    onSuccess: (availableSpace) => {
      if (!_.isEmpty(availableSpace)) {
        setAvailableSpace(availableSpace);
      }
    },
  });
};

export const useSpaceGroupingInfo = (group_id) => {
  const setSpaceGroupingInfo = spaceStore(
    (state) => state.setSpaceGroupingInfo
  );
  return useQuery({
    queryKey: ["spaceGroupingInfo", group_id],
    queryFn: () => spaceService.getSpaceGroupingInfo(group_id),
    onSuccess: (space) => {
      if (!_.isEmpty(space)) setSpaceGroupingInfo(space);
    },
  });
};

export const useSpaceGrouping = (payload) => {
  const setSpaceGrouping = spaceStore((state) => state.setSpaceGrouping);
  return useQuery({
    queryKey: ["spaceGroupingInfo", payload],
    queryFn: () => spaceService.getSpaceGrouping(payload),
    onSuccess: (space) => {
      if (!_.isEmpty(space)) setSpaceGrouping(space);
    },
  });
};
