import React, { useState, useEffect } from "react";
import { Button, Grid, Typography } from "@mui/material";
import Link from "common/components/link";
import { LocationOn } from "@mui/icons-material";
import Breadcrumbs from "common/components/breadcrumbs";
import SearchBar from "./search-bar";

export default function Header(props) {
  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12}>
        <Breadcrumbs
          menu={[{ text: "พื้นที่เช่า", to: "/space/mall" }, { text: "ค้นหา" }]}
        />
      </Grid>
      <Grid item xs={12} pt={1} pb={2}>
        <Typography fontSize={{ xs: 20, md: 24 }} fontWeight="bold">
          ค้นหาพื้นที่เช่า
        </Typography>
      </Grid>
      <Grid item xs={12} md={10} pb={{ xs: 2, md: 0 }}>
        <SearchBar />
      </Grid>
      <Grid item xs={12} md={2}>
        <Button
          variant="outlined"
          size="large"
          fullWidth
          sx={{ fontSize: 14, borderRadius: 3 }}
          onClick={props.resetLocation}
        >
          ค้นหาทั้งหมด
        </Button>
      </Grid>
    </Grid>
  );
}
