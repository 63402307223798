import React from "react";
import { Grid, Box, Typography, CircularProgress, Avatar } from "@mui/material";
import Breadcrumbs from "common/components/breadcrumbs";
import Sidebar from "common/components/sidebar";
import _ from "lodash";
import userStore from "common/stores/user.store";
import "./index.css";
import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto";
import shopStore from "common/stores/shop.store";
import shopImage from "assets/img/main-page/shop.png";
import profileImage from "assets/img/main-page/profile.png";
import { LazyLoadImage as Image } from "react-lazy-load-image-component";

export function ProfileSidebar(props) {
  const { user } = userStore();

  if (user === null) {
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Grid
      sx={{
        pt: {
          xs: 0,
          md: 10,
        },
        bgcolor: "#fff",
      }}
    >
      <Grid container>
        <Grid
          md={2.5}
          item
          sx={{
            mt: {
              xs: 0,
              md: 2,
            },
            display: {
              xs: "none",
              md: "initial",
            },
          }}
          px={4}
        >
          <Avatar
            sx={{
              height: 40,
              width: 40,
              bgcolor: "primary.main",
              textTransform: "uppercase",
            }}
          >
            {user.email?.[0] ?? user.first_name?.[0]}
          </Avatar>
          <Typography fontSize={24} fontWeight="bold" color="#595959" noWrap>
            คุณ {user?.first_name} {user?.last_name}
          </Typography>
          <Typography
            color="#595959"
            noWrap
            sx={{
              display: {
                xs: "none",
                md: "inherit",
              },
            }}
          >
            {user?.email}
          </Typography>
          <Grid
            sx={{
              display: {
                xs: "none",
                md: "inherit",
              },
              mt: 4,
            }}
          >
            <Sidebar
              footer
              menuList={[
                {
                  text: "ข้อมูลส่วนตัว",
                  icon: <Image src={profileImage} width={22} height={22} />,
                  to: "/settings/profile",
                },
                {
                  text: "ร้านค้า",
                  icon: <Image src={shopImage} width={22} height={22} />,
                  to: "/settings/store",
                },
              ]}
              selected={props.selected}
              width={300}
            />
          </Grid>
        </Grid>
        <Grid
          xs={12}
          md={9.5}
          item
          sx={{
            bgcolor: "#fff",
          }}
        >
          <Grid sx={{ position: "sticky" }}>
            <HeaderBreadcrumb
              breadcrumbs={props.breadcrumbs}
              showFlag={props.showFlag}
            />
          </Grid>
          <Grid
            bgcolor="#FAFAFC"
            sx={{
              overflowY: "scroll",
              overflowX: "clip",
              height: "70vh",
              pb: {
                xs: 10,
                md: 0,
              },
            }}
          >
            {props.children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export function HeaderBreadcrumb(props) {
  const [isPrimaryShop] = shopStore((state) => [state.isPrimaryShop]);
  return (
    <Grid container pl={2}>
      <Grid item xs={12} bgcolor="#fff" sx={{ pb: 6 }}>
        <Breadcrumbs menu={props.breadcrumbs} />
        <Typography
          fontSize={20}
          fontWeight="bold"
          color="#272626"
          mt={1}
          display="flex"
          alignItems="center"
        >
          {!_.isEmpty(props.breadcrumbs) &&
            props.breadcrumbs[props.breadcrumbs.length - 1].text}
          {isPrimaryShop && props.showFlag && (
            <AssistantPhotoIcon sx={{ color: "#18A0FB", ml: 2 }} />
          )}
        </Typography>
      </Grid>
    </Grid>
  );
}
