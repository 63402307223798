import { useQuery, useMutation } from "react-query";
import * as themeService from "services/theme.service";
import _ from "lodash";
import themeStore from "common/stores/theme.store";

export const useTheme = (domain) => {
  const { theme, setTheme } = themeStore();

  return useQuery({
    queryKey: ["getTheme", domain],
    queryFn: () => themeService.getThemeByBaseUrl(domain ?? "localhost"),
    select: (newTheme) => {
      if (_.isEmpty(newTheme)) return theme;

      return {
        ...newTheme,
        muiTheme: _.merge(
          _.omit(theme.muiTheme, "typography"),
          newTheme.muiTheme
        ),
      };
    },
    onSuccess: (newTheme) => {
      setTheme(newTheme);
    },
    onError: (error) => {},
  });
};
