import { useQuery } from "react-query";
import * as bookingService from "services/booking.service";
import bookingStore from "common/stores/booking.store";
import _ from "lodash";
import userStore from "common/stores/user.store";

export const useBookingDetailQuery = ({ bookingId }) => {
  const setBooking = bookingStore((state) => state.setBooking);
  const isAuthenticated = userStore((e) => e.isAuthenticated);

  return useQuery({
    queryKey: ["bookingDetail", bookingId],
    queryFn: () => bookingService.getBookingDetail({ booking_id: bookingId }),
    enabled: isAuthenticated && !_.isEmpty(bookingId),
    onSuccess: (booking) => {
      if (!_.isEmpty(booking)) {
        setBooking(booking);
      }
    },
  });
};
