import { useState } from "react";
import { css } from "@emotion/react";
import BarLoader from "react-spinners/BarLoader";
import { Container, Box, Grid } from "@mui/material";
import { LazyLoadImage as Image } from "react-lazy-load-image-component";
import themeStore from "common/stores/theme.store";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function Loader(props) {
  return props.disabledContainer ? (
    <OriginLoaderBody />
  ) : (
    <Container children={<OriginLoaderBody />} />
  );
}

export function OriginLoaderBody(props) {
  const theme = themeStore((e) => e.theme);
  const color = theme?.muiTheme?.palette?.primary?.main;

  return (
    <Grid
      container
      textAlign="center"
      direction="column"
      sx={{
        mx: "auto",
        mb: 0,
        mt: { xs: "43vh", md: "35vh" },
        transform: "translateY(-50%)",
        ...(props.style || props.sx),
      }}
    >
      <Grid item>
        <Box
          component={Image}
          src={theme?.square_logo}
          height={200}
          width={200}
        />
      </Grid>
      <Grid item>
        <BarLoader
          width={190}
          color={color}
          loading
          css={override}
          size={props.size ?? 150}
          // speedMultiplier={0.7}
        />
      </Grid>
    </Grid>
  );
}
