import { BottomNavigation, BottomNavigationAction } from "@mui/material/";
import { useTheme } from "@mui/material/styles";
import userStore from "common/stores/user.store";
import _ from "lodash";
import { useEffect, useState } from "react";

export default function SimpleBottomNavigation(props) {
  const { user } = userStore();
  const [value, setValue] = useState(0);
  const theme = useTheme();

  useEffect(() => {
    if (!_.isNil(props.selected)) {
      const selected = props.action.findIndex(
        (e) => e.label === props.selected
      );
      setValue(selected);
    }
  }, [props.selected]);

  return (
    <BottomNavigation
      showLabels
      value={value}
      onChange={(event, newValue) => {
        !_.isEmpty(user) ? setValue(newValue) : setValue(0);
      }}
      sx={{
        "& .MuiBottomNavigationAction-label": {
          mt: 1,
          fontSize: 12,
        },
      }}
    >
      {props.action.map((action) => {
        return (
          <BottomNavigationAction
            label={action?.label}
            icon={action?.icon}
            onClick={action?.onClick}
            sx={{
              color: action?.color,
              "& svg": {
                fill: "#666666",
              },
              "&.Mui-selected": {
                color: "primary.main",
                "& svg": {
                  fill: theme.palette.primary.main,
                },
              },
            }}
          />
        );
      })}
    </BottomNavigation>
  );
}
