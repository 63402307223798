import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Chip,
  Grid,
  Divider,
  IconButton,
  Paper,
  Typography,
  Tab,
  Tabs,
} from "@mui/material";
import { TbStack2 } from "react-icons/tb";
import { LazyLoadImage as Image } from "react-lazy-load-image-component";
import { PlaceIcon } from "modules/space/mall.component";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import GoogleMapReact from "google-map-react";
import { useModal } from "mui-modal-provider";
import SwiperDialog from "common/modals/swiper";
import _ from "lodash";

export default function MallDetail({ mall, value }) {
  const { showModal } = useModal();

  return (
    <Grid container pb={2} pt={2}>
      <TabPanel value={value} index={0} style={{ width: "100%" }}>
        <Box
          component={Image}
          src={mall?.images?.[0]?.image_url}
          height={{ xs: 172, md: 331 }}
          borderRadius={{ xs: 0, md: 2 }}
          width="100%"
          mb={2}
          sx={{
            flexGrow: 1,
            objectFit: "cover",
          }}
        />
      </TabPanel>
      <TabPanel value={value} index={1} style={{ width: "100%" }}>
        <Box
          height={{ xs: 172, md: 331 }}
          borderRadius={{ xs: 0, md: 2 }}
          width="100%"
          mb={2}
          sx={{
            flexGrow: 1,
            objectFit: "cover",
          }}
        >
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyCiQOnoorqvV4lPUBOxK_c5RRO91ZPU0mo",
            }}
            center={mall?.lat_lng}
            zoom={12}
          >
            <Box
              sx={{
                display: "flex",
                minWidth: "250px",
                width: "100%",
              }}
              key={mall?.id}
              lat={mall?.lat_lng?.lat}
              lng={mall?.lat_lng?.lng}
            >
              <Box>
                <FmdGoodIcon sx={{ color: "#E34745", fontSize: "30px" }} />
              </Box>
              <Box
                sx={{
                  backgroundColor: "#fff",
                  padding: "2px 8px",
                  borderRadius: "5px",
                  marginLeft: "-14px",
                  zIndex: "-1",
                  maxHeight: "30px",
                  alignSelf: "center",
                }}
              >
                <Typography fontSize="16px" fontWeight="700">
                  {mall?.name}
                </Typography>
              </Box>
            </Box>
          </GoogleMapReact>
        </Box>
      </TabPanel>

      <Container>
        <Typography fontSize={{ xs: 20, md: 24 }} fontWeight="bold" mb={1}>
          {mall?.name}
        </Typography>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography fontSize={16} color="grey.500">
            <PlaceIcon />3 กม.
          </Typography>
          <Button
            color="primary"
            size="large"
            sx={{ textTransform: "capitalize", fontSize: 16 }}
            startIcon={<TbStack2 fontSize={20} />}
            disabled={_.isEmpty(
              _.filter(mall?.images, ["image_type", "floor_plan"])
            )}
            onClick={() =>
              showModal(SwiperDialog, {
                images: _.filter(mall?.images, ["image_type", "floor_plan"]),
              })
            }
          >
            Floor Plan
          </Button>
        </Grid>
      </Container>
    </Grid>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}
