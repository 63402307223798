import React from "react";
import { Grid } from "@mui/material";
import { Outlet } from "react-router-dom";
// import { isMobile } from "common/utils/mediaQuery";
import DesktopHeader from "common/components/nav/header.desktop";
import DesktopFooter from "common/components/nav/footer.desktop";

export default function Layout() {
  return (
    <Grid container>
      <Grid container sx={{ display: { xs: "none", md: "block" } }}>
        <DesktopHeader />
      </Grid>
      <Grid pt={{ xs: 0, md: "66px" }} container>
        <Grid item xs={12}>
          <Outlet />
        </Grid>
        <Grid item xs={12} sx={{ display: { xs: "none", md: "block" } }}>
          <DesktopFooter />
        </Grid>
      </Grid>
    </Grid>
  );
}
