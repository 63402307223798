import * as React from "react";
import { WorkOutlineRounded, ViewSidebarOutlined } from "@mui/icons-material";
import { Footer, BottomNav } from "common/components/nav";
import { useNavigate, Link, useLocation } from "react-router-dom";
// import ERROR from "./error.constant";
import userStore from "common/stores/user.store";
import _ from "lodash";
import { ModalLogin } from "common/components/modal";

export default function SimpleBottomNavigation(props) {
  const navigate = useNavigate();
  const { showLoginModal } = ModalLogin();
  const { user } = userStore();
  const { pathname } = useLocation();
  const selected = _.includes(pathname, "/space/booking")
    ? "การจอง"
    : "พื้นที่เช่า";

  const action = [
    {
      label: "พื้นที่เช่า",
      icon: <WorkOutlineRounded />,
      onClick: () => navigate("/space"),
    },
    {
      label: "การจอง",
      icon: <ViewSidebarOutlined />,
      onClick: () => {
        _.isEmpty(user) ? showLoginModal() : navigate("/space/booking");
      },
    },
  ];

  return (
    <Footer border pb={0} sx={{ display: { xs: "block", md: "none" } }}>
      <BottomNav selected={selected} action={action} />
    </Footer>
  );
}
