import axios from "axios";

const storageUrl =
  "https://storage.googleapis.com/storage.lotuss.originsme.com";

export const getThemeByBaseUrl = (baseUrl) => {
  return axios({
    url: `${storageUrl}/theme/${baseUrl}.json`,
    params: { time: Date.now() },
    method: "get",
  }).then((res) => res?.data);
};
