import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  Grid,
  Paper,
  SwipeableDrawer,
  IconButton,
  Typography,
  TextField,
} from "@mui/material";
import { FilterAlt, Close } from "@mui/icons-material";
import { getProvinces } from "@bilions/thailand-address";
import { useModal } from "mui-modal-provider";
import { useCompany } from "common/hooks/use-query/company.hook";
import { useBusinessType } from "common/hooks/use-query/shop.hook";
import useStore from "common/stores/mall.store";

export default function ResponsiveFilter(props) {
  const { setFilter, clearFilter } = useStore();

  return (
    <>
      <Grid sx={{ display: { xs: "block", md: "none" } }}>
        <Grid
          container
          alignContent="center"
          justifyContent="space-between"
          pt={3}
          pb={1}
          pr={1}
          pl={2}
        >
          <Typography fontSize={24} fontWeight="bold" pt={0.5}>
            ตัวกรอง
          </Typography>
          <IconButton size="large" onClick={props.onClose}>
            <Close />
          </IconButton>
        </Grid>
        <FilterContent />
        <Grid
          container
          direction="column"
          rowSpacing={2}
          px={3}
          sx={{
            position: "absolute",
            bottom: 30,
          }}
        >
          <Grid item xs={12}>
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={() => {
                setFilter();
                props.onClose();
              }}
            >
              ยืนยัน
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="text"
              size="large"
              fullWidth
              color="blue"
              onClick={() => {
                clearFilter();
              }}
            >
              ล้างตัวกรอง
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid sx={{ display: { xs: "none", md: "block" } }}>
        <Paper variant="outlined" sx={{ borderRadius: 3 }}>
          <FilterContent />
        </Paper>
      </Grid>
    </>
  );
}

function FilterContent(props) {
  const [tempFilter, setTempFilter, clearFilter, setFilter] = useStore((e) => [
    e.tempFilter,
    e.setTempFilter,
    e.clearFilter,
    e.setFilter,
  ]);
  const { data: companies } = useCompany();
  const { data: businessType } = useBusinessType();
  const provinces = getProvinces()
    .map((e) => e.name_in_thai)
    .sort((a, b) => a.localeCompare(b));

  return (
    <Grid container py={2} px={2} direction="column">
      <Autocomplete
        value={tempFilter?.company_name}
        onChange={(e, newValue) => setTempFilter({ company_name: newValue })}
        options={companies?.[0] ? companies?.map((e) => e.name) : []}
        renderInput={(params) => <TextField {...params} label="แบรนด์" />}
        sx={{ pb: 3 }}
      />
      <Autocomplete
        value={tempFilter?.business_type}
        onChange={(e, newValue) => setTempFilter({ business_type: newValue })}
        options={businessType ?? []}
        renderInput={(params) => <TextField {...params} label="ประเภทธุรกิจ" />}
        sx={{ pb: 3 }}
      />
      <Autocomplete
        value={tempFilter?.province}
        onChange={(e, newValue) => setTempFilter({ province: newValue })}
        options={provinces ?? []}
        renderInput={(params) => <TextField {...params} label="จังหวัด" />}
        sx={{ pb: 3 }}
      />
      <Grid
        container
        direction="column"
        rowSpacing={2}
        pt={2}
        sx={{ display: { xs: "none", md: "block" } }}
      >
        <Grid item>
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={() => setFilter()}
          >
            ยืนยัน
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            size="large"
            fullWidth
            onClick={clearFilter}
          >
            ล้างตัวกรอง
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export function FilterButton(props) {
  const { showModal } = useModal();

  function handleClick() {
    showModal(FilterDrawer);
  }

  return (
    <Grid sx={{ position: "relative", display: { xs: "block", md: "none" } }}>
      <IconButton
        sx={{ position: "absolute", right: 1, bottom: 23 }}
        onClick={handleClick}
      >
        <FilterAlt sx={{ color: "grey.900" }} />
      </IconButton>
    </Grid>
  );
}

function FilterDrawer(props) {
  return (
    <SwipeableDrawer {...props} anchor="right">
      <Box width="100vw">
        <ResponsiveFilter onClose={props.onClose} />
      </Box>
    </SwipeableDrawer>
  );
}
