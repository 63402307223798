import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import { useParams, useLocation } from "react-router-dom";
import { useBookingDetailQuery } from "common/hooks/use-query/booking.hook";
import paymentStore from "common/stores/payment.store";
import bookingStore from "common/stores/booking.store";
import userStore from "common/stores/user.store";
import errorStore from "common/stores/error.store";
import ERROR_MESSAGE from "common/constants/error/payment";
import * as bookingService from "services/booking.service";
import * as omiseService from "services/omise.service";

export default function PaymentHook(props) {
  const { bookingId } = useParams();
  const { user } = userStore();
  const booking = bookingStore((e) => e.booking);
  const payment = paymentStore((e) => e.payment);
  const { setError, setOpen: setErrorModal } = errorStore();
  const { setRemainDuration, setPendingPayment } = paymentStore();

  const { refetch } = useBookingDetailQuery({ bookingId });

  const [paymentCondition, setPaymentCondition] = useState({
    isInstantPayment: false,
    isRequireApprove: false,
    isDirectLandLord: false,
  });

  useEffect(() => {
    if (props && "payment_service" in props && "required_approval" in props) {
      setPaymentCondition({
        isInstantPayment:
          props.payment_service == 1 && props.required_approval != 1,
        isRequireApprove:
          props.payment_service == 1 && props.required_approval == 1,
        isDirectLandLord:
          props.payment_service != 1 && props.required_approval == 1,
      });
    }
  }, [props]);

  async function handleSubmit() {
    try {
      booking.booking_total_price > 0
        ? await intialPayment(booking.booking_total_price)
        : await createFreePayment({ booking_id: booking.booking_id });
    } catch (err) {
      throw err;
    }
  }

  async function intialPayment(total_price) {
    total_price = total_price * 100; //convert to satang

    const payload = {
      type: payment.type,
      amount: total_price,
      currency: "THB",
      phone_number: user.phone,
    };

    try {
      const data = await omiseService.createSource(payload);
      await handleCreateSource(data);
      // handleCreateSource
    } catch (err) {
      throw err;
    }
  }

  async function handleCreateSource(data) {
    const paymentRes = await createPayment(data);
    if (payment.type === "truemoney") handleTrueMoney(paymentRes);
    if (payment.type === "promptpay") handlePromptpay(paymentRes);
  }

  async function createPayment(data) {
    const { origin, pathname } = window.location;

    return await bookingService.createPayment({
      booking_id: booking.booking_id,
      return_uri: `${origin}${pathname}?type=${payment.type}#handle`,
      source: data,
    });
  }

  async function checkPaymenStatus() {
    await refetch();
    if (booking.transaction_status === "paid") {
      props.goToNamedStep("promptpay-success");
      throw new Error({ message: "paid" });
    }
  }

  async function createFreePayment(payload) {
    await bookingService.createFreePayment(payload);
  }

  function handleTrueMoney(payload) {
    window.location.href = payload.payment_data;
  }

  async function handlePromptpay() {
    await refetch();
    props.goToNamedStep("promptpay-qrcode");
  }

  function handlePromptpayRemaining() {
    if (
      booking.transaction_status === "paid" ||
      booking.transaction_payment_channel !== "promptpay"
    )
      return;

    if (
      !_.isEmpty(booking.transaction_expires_at) &&
      booking.transaction_status === "pending_payment"
    ) {
      getPromtpayRemaining();
    } else if (booking?.transaction_status === "failed") {
      if (window.location.hash === "#promptpay-qrcode")
        handleFailedPromptpayProcess();
    } else {
      setRemainDuration(0);
      setPendingPayment(false);
      return;
    }
  }

  function getPromtpayRemaining() {
    const expireDiff = moment(booking.transaction_expires_at).diff(moment());
    const secondDuration = moment.duration(expireDiff);
    const millisecondDuration = secondDuration._milliseconds;
    if (millisecondDuration > 0) {
      setRemainDuration(millisecondDuration);
      setPendingPayment(true);
      return;
    }
  }

  function handleFailedPromptpayProcess() {
    setError({
      ...ERROR_MESSAGE.PROMPTPAY.FAILED_PROCESSING,
      onClick: () => {
        refetch();
        setPendingPayment(false);
        setErrorModal(false);
        props.goToNamedStep("preview");
      },
    });
    setErrorModal(true);
    return;
  }

  return {
    payment,
    refetch,
    checkPaymenStatus,
    handleSubmit,
    handlePromptpay,
    paymentCondition,
    handlePromptpayRemaining,
  };
}
