import React, { useState, useEffect } from "react";
import Page from "common/components/page";
import { AppBar } from "common/components/nav";

import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { FooterButton } from "common/components/button";
import { Footer } from "common/components/nav";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import paymentStore from "common/stores/payment.store";
import { Item } from "common/components/form";
import Radio from "@mui/material/Radio";
import { useFormContext, useForm, useWatch } from "react-hook-form";
import { PAYMENT } from "common/constants/booking";
import { LazyLoadImage as Image } from "react-lazy-load-image-component";
import { ChevronRight } from "@mui/icons-material";

export default function PaymentMethod(props) {
  return (
    <Container>
      <AppBar onClick={() => props.goToNamedStep("preview")}>
        เลือกรูปแบบการชำระเงิน
      </AppBar>
      <Grid pt={4} pb={1} container rowSpacing={1}>
        {_.map(PAYMENT, (value, key) => {
          return <Method {...value} {...props} />;
        })}
      </Grid>
    </Container>
  );
}

export function Method(props) {
  let [searchParams, setSearchParams] = useSearchParams();
  const setPayment = paymentStore((state) => state.setPayment);

  const handleClick = () => {
    setSearchParams({ type: props.type });
    setPayment({ src: props.src, name: props.name, type: props.type });
    props.goToNamedStep("preview");
  };

  useEffect(() => {
    const type = searchParams.get("type");
    if (type === props.type) {
      setPayment({ src: props.src, name: props.name, type: props.type });
    }
  }, []);

  return (
    <Grid
      xs={12}
      onClick={props.onClick ?? handleClick}
      sx={{
        display: "flex",
        flexFlow: "row",
        alignItems: "center",
        cursor: "pointer",
        mt: 1,
        border: 1,
        borderRadius: 2,
        borderColor: "grey.300",
        p: 2,
        height: "69px",
      }}
    >
      {props.src ? (
        <>
          <Image
            src={props.src}
            width="50px"
            style={{ height: "fit-content" }}
          />
          <Item sx={{ ml: 2, fontSize: "16px" }}>{props.name}</Item>
        </>
      ) : (
        <>
          <Item sx={{ fontSize: "16px" }}>{props.name}</Item>
        </>
      )}
      {props.rightArrow && <ChevronRight />}
    </Grid>
  );
}
