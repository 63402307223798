import React, { useState, useEffect } from "react";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled, Grid } from "@mui/material";
import { useNavigate } from "react-router";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#5AC98D" : "#308fe8",
  },
}));

export default function ProgressWithTime(props) {
  const navigate = useNavigate();
  const max_time = props?.time ?? 5;
  const [seconds, setSeconds] = useState(0);
  const path = localStorage.getItem("from_service");

  function checkIsfromService() {
    if (path === "/register") {
      return navigate("/recruit");
    }
    localStorage.getItem("from_service")
      ? navigate(localStorage.getItem("from_service"))
      : navigate("/recruit/add-shop");
  }

  useEffect(() => {
    const isDevMode = localStorage.getItem("dev");
    if (seconds < max_time) {
      setTimeout(() => setSeconds(seconds + 0.2), 100);
    }
    if (!isDevMode) {
      if (Math.floor(seconds) === max_time) {
        checkIsfromService();
      }
    }
  }, [seconds]);
  return (
    <Grid sx={props?.sx}>
      <BorderLinearProgress
        variant="determinate"
        value={(seconds * 100) / max_time}
        sx={{ my: 4 }}
      />
    </Grid>
  );
}
