import React, { useState, useEffect } from "react";
import * as lineService from "services/line.service";
import { Grid, Box, Button, Typography } from "@mui/material";
import { LazyLoadImage as Image } from "react-lazy-load-image-component";
import Page from "common/components/page";
import { useLineFriendShipQuery } from "common/hooks/use-query/line.hook";
import SmeLogo from "assets/img/sme_logo.png";
import Success from "./success";
import Skeleton from "./skeleton";
import themeStore from "common/stores/theme.store";

export default function CheckFriendShip(props) {
  const { isLoading, data } = useLineFriendShipQuery();
  const theme = themeStore((e) => e.theme);

  // async function getLineFriendShip() {
  //   try {
  //     return await lineService.getFriendShip();
  //   } catch (err) {
  //     console.log("add line pa -> getFriendShip");
  //     return {};
  //   }
  // }

  // async function handleLineStatus(friendShip) {
  //   if (friendShip?.friend_flag === true) {
  //     navigate("/recruit");
  //   }
  // }

  useEffect(() => {}, []);

  return (
    <>
      <Page>
        <Grid xs={12} textAlign="center">
          <Image
            alt="sme-logo"
            height={72}
            src={SmeLogo}
            style={{ marginTop: "15%" }}
          />
          <Typography variant="h6" mt={3} mb={1}>
            <b>เชื่อมต่อ LINE กับ {theme.title}</b>
          </Typography>
          <Typography variant="h6" mb={1} sx={{ fontSize: "15px" }}>
            ไม่พลาดทุกการแจ้งเตือนสำคัญ
          </Typography>
          {isLoading ? (
            <Skeleton />
          ) : data?.friend_flag === true ? (
            <Success />
          ) : (
            (window.location.href = "https://lin.ee/uNNS4sV")
          )}
        </Grid>
      </Page>
    </>
  );
}
