import React, { useState, useEffect, useCallback } from "react";
// import { Drawer, Modal, Divider } from "antd";
import { useNavigate, useLocation, Link } from "react-router-dom";
import userStore from "common/stores/user.store";
import _ from "lodash";
import { useModal } from "mui-modal-provider";
import ErrorModal from "common/modals/error";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "modules/space/style.css";

export function ModalLogin(props) {
  const navigate = useNavigate();
  const { user } = userStore();
  const { showModal } = useModal();
  const location = useLocation();

  function checkAuth(payload) {
    if (_.isEmpty(user)) {
      showLoginModal(payload);
      return false;
    }
    return true;
  }

  function showLoginModal(payload) {
    const modal = showModal(ErrorModal, {
      title: "ไม่พบโปรไฟล์ของคุณ",
      body: "เพื่อให้สามารถเข้าใช้บริการของเราได้ โปรดทำการ “เข้าสู่ระบบ” หรือ ”ลงทะเบียน” เพื่อสร้างโปรไฟล์ใหม่เพื่อรับสิทธิพิเศษมากมาย",
      button: "เข้าสู่ระบบ",
      onClick: () => {
        localStorage.setItem(
          "from_service",
          payload?.afterLogin ?? location.pathname
        );
        navigate("/auth/login");
        modal.hide();
      },
      secondButton: "ลงทะเบียน",
      secondOnClick: () => {
        localStorage.setItem(
          "from_service",
          payload?.afterRegister ?? location.pathname
        );
        navigate("/register");
        modal.hide();
      },
    });
  }

  return { checkAuth, showLoginModal };
}
