import create from "zustand";
import _ from "lodash";

export const storeSchema = (set, customHook = () => {}) => ({
  form: {
    phone: "",
  },
  setForm: (form) =>
    set((e) => ({
      form: { ...(e.form || {}), ...form },
    })),
  dupForm: {},
  setDupForm: (dupForm) =>
    set((e) => ({
      dupForm: { ...(e.dupForm || {}), ...dupForm },
    })),
  clearForm: () => set(() => ({ form: {} })),
  setByKey: (props) => set((e) => handleSetByKey(e, props)),
  clearByKey: (key) =>
    set((e) => ({
      form: { ...e.form, [key]: {} },
    })),
  ...customHook(set),
});

function handleSetByKey(state, props) {
  let form = state.form;

  if ("key" in props && "obj" in props) {
    form[props.key] = { ...props.obj };
  } else {
    _.forEach(props, (value, key) => {
      form[key] = { ...value };
    });
  }
  return form;
}

export const createStore = (customHook) => create((set) => storeSchema(set, customHook));
export const useStore = create((set) => storeSchema(set));
export default useStore;
