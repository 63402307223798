import { useEffect } from "react";
import loadable from "@loadable/component";

const BusinessMatchingLayout = loadable(() => import("modules/recruit/layout"));

const BusinessMatchingForm = loadable(() => import("pages/business-matching/form"));
const BusinessMatchingStatus = loadable(() => import("pages/business-matching/status"));
const BusinessMatchingPreview = loadable(() => import("pages/business-matching/preview"));
const RegisteredBusinessMatchingDetail = loadable(() => import("pages/business-matching/status/registration-id"));

const basePath = "business-matching";

const bizUrl = "/business-matching/preview";
const seedingUrl = "cpseeding.com";

const bizObj = {
  dev: `localhost:3000${bizUrl}`,
  prod: `https://portal.${seedingUrl}${bizUrl}`,
  test: `https://portal-test.${seedingUrl}${bizUrl}`,
};

const reactEnv = process.env.REACT_APP_ENV;

const businessMatchingRoute = (isAuthenticated, modules) =>
  modules?.business_matching
    ? [
        {
          exact: true,
          path: basePath,
          element: modules?.business_matching_redirect && reactEnv !== "dev" ? <RediretToSeeding /> : <BusinessMatchingLayout />,
          children: [
            { exact: true, index: true, element: <BusinessMatchingPreview /> },
            ...(isAuthenticated
              ? [
                  { exact: true, path: "form", element: <BusinessMatchingForm /> },
                  { exact: true, path: "status", element: <BusinessMatchingStatus /> },
                  { exact: true, path: `status/:registrationId`, element: <RegisteredBusinessMatchingDetail /> },
                ]
              : []),
            { exact: true, path: `preview`, element: <BusinessMatchingPreview /> },
          ],
        },
      ]
    : [];

function RediretToSeeding() {
  useEffect(() => {
    window.location.href = bizObj[reactEnv];
  }, []);
}

export default businessMatchingRoute;
