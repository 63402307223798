import create from "zustand";
import _ from "lodash";
import produce from "immer";

const useStore = create((set) => ({
  isPaid: false,
  isDisabled: false,
  booking: {},
  setBooking: (booking) =>
    set({
      booking: booking,
      isDisabled: booking.booking_status === "cancelled" || booking.booking_status === "disapproved" || booking.booking_status === "failed" || booking.space_payment_service === true,
      isPaid: booking.transaction_status === "paid",
    }),
  period: [],
  setPeriod: (e) => set({ period: e }),
  addPeriod: (e) =>
    set(
      produce((draft) => {
        draft.period.push(e);
      })
    ),
  periodSpaceId: null,
  setPeriodSpaceId: (e) => set({ periodSpaceId: e }),
  clearPeriod: (e) => set({ period: [] }),
  removePeriod: (index) =>
    set(
      produce((draft) => {
        draft.period.splice(index, 1);
      })
    ),
}));

export default useStore;
