import { axios, HeaderDefault, GetEnvironment } from "services/base.service";

export const getShopByUser = (payload) => {
  return axios({
    url: `/v1/shop/user`,
    method: "get",
  }).then((res) => res?.data?.data);
};

export const getShopInfo = (shop_id) => {
  return axios({
    url: `/v1/shop/info`,
    method: "get",
    params: { shop_id },
  }).then((res) => {
    let data = res?.data?.data;
    if (data) {
      if ("images" in data && data.images?.[0]) {
        data.images = data.images.map((e) => {
          return {
            ...e,
            name: e.image_name,
          };
        });
      }
      if ("documents" in data && data.documents?.[0]) {
        data.documents = data.documents.map((e) => {
          return {
            ...e,
            name: e.document_name ?? "untitled_file",
          };
        });
      }
      return data;
    }
  });
};

export const getPrimaryShop = () => {
  return axios({
    url: `/v1/user/primary/shop`,
    method: "get",
  }).then((res) => res?.data?.data);
};

export const getMissingDocument = (shop_id) => {
  return axios({
    url: `/v1/shop/missing/document`,
    method: "get",
    params: { shop_id },
  }).then((res) => res?.data?.data);
};

export const verifyShop = (payload) => {
  return axios({
    url: `/v1/data_verifier/verify`,
    method: "post",
    data: payload,
  }).then((res) => res?.data?.data);
};

export const verifyShopBusinessMatching = (payload) => {
  return axios({
    url: `/v1/data_verifier/verify`,
    method: "post",
    data: { ...payload, service_type: "business_matching" },
  }).then((res) => res?.data?.data);
};

export const verifyShopShortTermEvent = (payload) => {
  return axios({
    url: `/v1/data_verifier/verify`,
    method: "post",
    data: { ...payload, service_type: "shortterm_event" },
  }).then((res) => res?.data?.data);
};

export const verifyShopShortTerm = (payload) => {
  return axios({
    url: `/v1/data_verifier/verify`,
    method: "post",
    data: { ...payload, service_type: "shortterm" },
  }).then((res) => res?.data?.data);
};

export const getBusinessType = () => {
  return axios({
    url: `/v1/shop/business/type`,
    method: "get",
  }).then((res) => res?.data?.data);
};

export const deleteDocument = (document_id) => {
  return axios({
    url: `/v1/shop/document/delete`,
    method: "delete",
    params: { document_id },
  }).then((res) => res?.data?.data);
};

export const addDocument = ({ document_type, shop_id, data }) => {
  return axios({
    url: `/v1/shop/documents/new`,
    method: "post",
    params: { document_type, shop_id },
    data: data,
  }).then((res) => res?.data?.data);
};

export const setShopToPrimary = (payload) => {
  return axios({
    url: `/v1/user/primary/shop`,
    method: "post",
    params: { shop_id: payload },
  }).then((res) => res?.data?.data);
};

export const addShop = (payload) => {
  return axios({
    url: `/v1/shop/new`,
    method: "post",
    data: payload,
  }).then((res) => res?.data?.data);
};

export const addImagesBase = ({ img_type, shop_id, data }) => {
  return axios({
    url: `/v1/shop/images/new`,
    method: "post",
    params: { img_type, shop_id },
    data: data,
  });
};

export const addImages = ({ img_type, shop_id, data }) => {
  return addImagesBase({ img_type, shop_id, data }).then(
    (res) => res?.data?.data
  );
};

export const deleteImage = (image_id) => {
  return axios({
    url: `/v1/shop/image/delete`,
    method: "delete",
    params: { image_id },
  }).then((res) => res?.data?.data);
};

export const updateDocument = ({ document_type, shop_id, data }) => {
  return axios({
    url: `/v1/shop/documents/update`,
    method: "post",
    params: { document_type, shop_id },
    data: data,
  }).then((res) => res?.data?.data);
};

export const updateShop = (
  payload,
  handleSuccess = null,
  handleError = null
) => {
  return axios({
    url: `/v1/shop/update`,
    method: "post",
    data: payload,
  })
    .then((res) => {
      handleSuccess && handleSuccess(res);
      return res?.data?.data;
    })
    .catch((error) => {
      console.log("Error caught in updateShop():", error.message);
      if (error.response) {
        console.log(error.response.data);
      }
      if (handleError) {
        handleError(error);
      } else {
        throw error;
      }
    });
};

export const updateImages = ({ img_type, shop_id, data }) => {
  return axios({
    url: `/v1/shop/images/update`,
    method: "post",
    params: { img_type, shop_id },
    data: data,
  }).then((res) => res?.data?.data);
};

export const updateCPOppPlatform = (shop_id, payload) => {
  return axios({
    url: `/v1/shop/opportunity/update`,
    method: "post",
    params: { shop_id },
    data: payload,
  });
};

export const registerCampaign = (payload) => {
  return axios({
    url: `/v1/campaign/partner/register`,
    method: "post",
    data: payload,
  }).then((res) => res?.data?.data);
};

export const updatePartnerCampaign = (payload) => {
  return axios({
    url: `/v1/campaign/partner/status/update`,
    method: "post",
    params: payload,
    data: payload,
  });
};
