import React, { useState, useEffect } from "react";
import { Grid, Container } from "@mui/material";
import { AppBar, Footer } from "common/components/nav";
import Filter from "modules/space/mall/home/filter";
import Header from "modules/space/mall/home/header";
import MallList from "modules/space/mall/home/mall-list";
import BottomNav from "modules/space/bottom-nav";
import { AppBarMenu } from "modules/recruit/header";

export default function MallExplorer(props) {
  return (
    <Grid bgcolor="#FAFAFC">
      <AppBar menu>เช่าพื้นที่ขายของ</AppBar>
      <Container>
        <Header />
        <Grid container mt={4} columnSpacing={2} pb={{ xs: 15, md: 2 }}>
          <Grid item xs={3} sx={{ display: { xs: "none", md: "block" } }}>
            <Filter />
          </Grid>
          <Grid item xs={12} md={9}>
            <MallList />
          </Grid>
        </Grid>
      </Container>
      <BottomNav selected="หน้าแรก" />
    </Grid>
  );
}
