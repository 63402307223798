import create from "zustand";

const useStore = create((set) => ({
  open: false,
  setOpen: (open) => set(() => ({ open: open })),
  openModal: () => set(() => ({ open: true })),
  closeModal: () => set(() => ({ open: false })),
  error: {},
  setError: (error) => set(() => ({ open: true, error: error })),
  protectModal: false,
  setProtectModal: (protectModal) => set(() => ({ protectModal: protectModal })),
}));

export default useStore;
