import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Item } from "common/components/form";
import { BOOKING_STATUS } from "common/constants/booking";

export default function Status(props) {
  let { label, color } = { ...BOOKING_STATUS[props.status] };

  return (
    <Grid
      sx={{
        boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.1)",
        borderRadius: "12px",
        px: 2.5,
        py: 1.2,
        mb: 2.5,
      }}
      container
    >
      <Item xs={6} color="grey.500">
        สถานะ
      </Item>
      <Item xs={6} color={color} bold fontSize={16} right>
        {label}
      </Item>
    </Grid>
  );
}
