import axios from "axios";
import { publicKey } from "common/constants/omise";

export const createSource = (payload) =>
  axios({
    auth: { username: publicKey },
    baseURL: "https://api.omise.co/",
    url: `/sources`,
    method: "post",
    params: payload,
  }).then((res) => res.data);
