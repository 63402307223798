import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { FooterButton } from "common/components/button";
import { Footer } from "common/components/nav";
import { styled } from "@mui/material/styles";
import { ChevronRight } from "@mui/icons-material";
import _ from "lodash";
import paymentStore from "common/stores/payment.store";
import { Item } from "common/components/form";
import userStore from "common/stores/user.store";
import shopStore from "common/stores/shop.store";
import Price from "common/components/price";
import * as userService from "services/user.service";
import bookingStore from "common/stores/booking.store";
import moment from "moment";
import "moment/locale/th";
moment.locale("th");

export default function BookingDetail(props) {
  // let { from_date = "", to_date = "" } = { ...props.booking_date_range_info?.[0] };

  const formatDate = (e) => {
    try {
      return moment(e).format("DD MMM YY");
    } catch (err) {
      return "ไม่พบข้อมูล";
    }
  };

  return (
    <Grid container rowSpacing={1}>
      {/* prettier-ignore */}
      <Item xs={12} bold> ข้อมูลการจอง </Item>
      <Grid item xs={12}>
        <Box sx={{ bgcolor: "grey.100", borderRadius: 2, p: 2 }}>
          <Grid container rowSpacing={1}>
            {/* prettier-ignore */}
            <Item xs={6} bold> โซน: </Item>
            <Item xs={6}>{props.name ?? "ไม่มีข้อมูล"}</Item>

            {/* prettier-ignore */}
            <Item xs={6} bold> ชั้น: </Item>
            <Item xs={6}>{props.floor}</Item>

            {/* prettier-ignore */}
            <Item xs={6} bold>พื้นที่: </Item>
            <Item xs={6}>
              {props?.booth_area
                ? `${props?.booth_area} ตรม. (${props?.booth_width}x${props?.booth_length} ม.)`
                : `${props?.booth_width}x${props?.booth_length} ม.`}
            </Item>

            {/* prettier-ignore */}
            {Object.keys(props.business_type).map((e, i) => (
              <>
                <Item xs={6} bold>
                  {i === 0 ? "สินค้าและบริการ:" : ""}
                </Item>
                <Item xs={6}>{e} </Item>
              </>
            ))}

            {/* prettier-ignore */}
            <Item xs={6} bold> ราคา: </Item>
            <Item xs={6}>
              <Price value={props.booth_price} /> บาท/วัน
            </Item>
          </Grid>
          <Divider variant="middle" sx={{ my: 1.5, mx: 0 }} />
          <Grid container rowSpacing={1}>
            {/* prettier-ignore */}
            <Item xs={12} bold> ช่วงเวลา </Item>
            {!_.isEmpty(props.fromToDate) &&
              props.fromToDate.map((e) => (
                <Item xs={12}>
                  {e.total_days === 1
                    ? `วันที่ ${formatDate(e.from_date)} (${e.total_days} วัน)}`
                    : `วันที่ ${formatDate(e.from_date)} - ${formatDate(
                        e.to_date
                      )} (${e.total_days} วัน)`}
                </Item>
              ))}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
