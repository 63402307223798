import { axios, HeaderDefault, GetEnvironment } from "services/base.service";

export const getUrl = () => {
  return axios({
    url: `/v1/line/login/link`,
    method: "get",
  }).then((res) => res?.data?.data);
};

export const getFriendShip = () => {
  return axios({
    baseURL: HeaderDefault().baseURL,
    url: `/v1/line/oa/friendship`,
    method: "get",
    headers: {
      "Line-Authorization": JSON.parse(localStorage.getItem("access_token")),
      Authorization: JSON.parse(localStorage.getItem("access_token")),
    },
  }).then((res) => res?.data?.data);
};

export const getLineLogin = () => {
  return axios({
    baseURL: HeaderDefault().baseURL,
    url: `/v1/line/login`,
    method: "get",
    headers: {
      "Line-Authorization": JSON.parse(localStorage.getItem("access_token")),
      Authorization: JSON.parse(localStorage.getItem("access_token")),
    },
  }).then((res) => res?.data?.data);
};

export const getToken = (code) => {
  return axios({
    url: `/v1/line/token`,
    params: { code },
    method: "get",
  }).then((res) => res?.data?.data);
};

export const getLineProfile = () => {
  return axios({
    baseURL: HeaderDefault().baseURL,
    url: `/v1/line/profile`,
    method: "get",
    headers: {
      "Line-Authorization": JSON.parse(localStorage.getItem("access_token")),
      Authorization: JSON.parse(localStorage.getItem("access_token")),
    },
  }).then((res) => res?.data?.data);
};

export const integrate = (payload) => {
  return axios({
    baseURL: HeaderDefault().baseURL,
    url: `/v1/line/integration`,
    method: "get",
    headers: {
      "Line-Authorization":
        payload.access_token ??
        JSON.parse(localStorage.getItem("access_token")),
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    },
  })
    .then((res) => res?.data?.data)
    .catch((err) => {
      throw new Error(err?.response);
    });
};
