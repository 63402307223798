import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import SuccessLoading from "common/pages/success-loading";
import { useBookingDetailQuery } from "common/hooks/use-query/booking.hook";
import bookingStore from "common/stores/booking.store";

export default function SuccessShop() {
  const navigate = useNavigate();
  const { booking } = bookingStore();

  const { refetch } = useBookingDetailQuery({
    bookingId: booking.booking_id,
  });

  useEffect(() => {
    setTimeout(function () {
      refetch();
      localStorage.removeItem("from_service");
      navigate(`/space/booking/${booking.booking_id}`);
    }, 5000);
  }, []);

  return (
    <SuccessLoading
      title="การส่งข้อมูลสำเร็จ"
      description="ระบบกำลังพาคุณกลับไปหน้าบริการก่อนหน้า"
    />
  );
}
