import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import ProgressWithTime from "common/components/progress/progress-time";
import Page from "common/components/page";
import shopSuccessImage from "assets/img/shopSuccess.png";
import { LazyLoadImage as Image } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

export default function Success(props) {
  return (
    <Page>
      <Grid
        container
        justifyContent="center"
        textAlign="center"
        alignItems="center"
        sx={{
          height: "100vh",
        }}
      >
        <Grid item maxWidth={390}>
          <Typography fontSize={18} fontWeight="bold" mb={4}>
            อัพเดทข้อมูลสำเร็จ
          </Typography>
          <Box component={Image} src={shopSuccessImage} />
          <ProgressWithTime sx={{ mt: 10 }} />
          <Typography fontSize={20} fontWeight="bold" mt={4}>
            โปรดรอสักครู่
          </Typography>
          <Typography fontSize={20} fontWeight="bold" mt={4}>
            ระบบกำลังพาคุณไปหน้า
          </Typography>
          <Typography fontSize={20} fontWeight="bold">
            ”{props.title}”
          </Typography>
          <Typography fontSize={16} mt={4}>
            ภายใน 5 วินาที หรือคุณสามารถกดเลือก
          </Typography>
          <Typography>
            <Link to={props.to}>{props.title} </Link>
            ได้หากไม่ต้องการรอ
          </Typography>
        </Grid>
      </Grid>
    </Page>
  );
}
