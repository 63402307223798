import React, { useState } from "react";
import {
  Container,
  Checkbox,
  FormControlLabel,
  Grid,
  RadioGroup,
  Radio,
} from "@mui/material";
import { FooterButton } from "common/components/button";
import { Footer } from "common/components/nav";
import { styled } from "@mui/material/styles";

const RadioIcon = styled("span")(({ theme }) => ({
  borderRadius: "100%",
  display: "flex",
  width: 20,
  height: 20,
  border: `3px solid ${theme.palette.primary.main}`,
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
}));

const RadioCheckedIcon = styled(RadioIcon)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    width: 20,
    height: 20,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
}));

export default function FooterComponent(props) {
  return (
    <Footer disabledPaper sx={{ bgcolor: props.bgcolor ?? "grey.100" }}>
      {props.choice ? (
        <ChoiceCheckBox {...props} />
      ) : (
        <NormalCheckBox {...props} />
      )}
    </Footer>
  );
}

export function NormalCheckBox(props) {
  const [checked, setChecked] = useState(props.checked ?? false);
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        display="flex"
        sx={{
          justifyContent: {
            xs: "start",
            sm: "center",
          },
          ml: {
            xs: 4,
            sm: 0,
          },
        }}
      >
        <FormControlLabel
          control={
            <Checkbox color="primary" onClick={() => setChecked(!checked)} />
          }
          label="ยินยอมและรับทราบเงื่อนไขทั้งหมด"
        />
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center">
        <FooterButton
          {...props}
          disabled={!checked}
          marginTop={1}
          onClick={() => {
            props.onClick();
          }}
        >
          รับทราบข้อกำหนด
        </FooterButton>
      </Grid>
    </Grid>
  );
}

export function ChoiceCheckBox(props) {
  const [accept, setAccept] = useState(null);

  return (
    <Grid container>
      <Grid item xs={12} display="flex" justifyContent="center">
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          value={accept}
          name="radio-buttons-group"
          onChange={(event) => setAccept(event.target.value)}
          sx={{ display: "inline" }}
        >
          <FormControlLabel
            value="true"
            control={
              <Radio
                icon={<RadioIcon />}
                sx={{
                  "&:before": {
                    border: "3px solid",
                    borderColor: "primary.main",
                  },
                }}
                checkedIcon={<RadioCheckedIcon />}
              />
            }
            label="ยินยอม"
          />
          <FormControlLabel
            sx={{ ml: 2 }}
            value="false"
            control={
              <Radio
                icon={<RadioIcon />}
                sx={{
                  "&:before": {
                    border: "3px solid",
                    borderColor: "primary.main",
                  },
                }}
                checkedIcon={<RadioCheckedIcon />}
              />
            }
            label="ไม่ยินยอม"
          />
        </RadioGroup>
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center">
        <FooterButton
          {...props}
          semiWidth
          disabled={!accept}
          marginTop={1}
          onClick={() => {
            props.onClick(accept);
          }}
        >
          รับทราบข้อกำหนด
        </FooterButton>
      </Grid>
    </Grid>
  );
}
