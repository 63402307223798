import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Paper,
  Container,
  Grid,
  Typography,
  Divider,
  Drawer,
} from "@mui/material";
import zonepic from "assets/img/fullZone.png";
import { LazyLoadImage as Image } from "react-lazy-load-image-component";
import { AppBar } from "common/components/nav";
import Breadcrumbs from "common/components/breadcrumbs";
import TransactionStatus from "modules/space/booking/transaction-status";
import Status from "modules/space/booking/id/preview/status";
import Detail from "modules/space/booking/id/preview/detail";
import PaymentMethod from "modules/space/booking/payment/select-method";
import { Summary } from "modules/space/booking/payment/footer";
import PaidSummary from "modules/space/booking/id/preview/paid-summary";
import paymentStore from "common/stores/payment.store";
import bookingStore from "common/stores/booking.store";
import PaymentDetail from "modules/space/booking/id/payment-detail";
import MobileDrawer from "modules/space/booking/id/drawer.mobile";

export default function Booking(props) {
  const [booking, isDisabled, isPaid] = bookingStore((e) => [
    e.booking,
    e.isDisabled,
    e.isPaid,
  ]);

  const gridMarginBottom =
    isDisabled || isPaid
      ? 3
      : booking.booking_status === "pending_approval"
      ? 25
      : 34;

  return (
    <>
      <AppBar link="/space/booking" menu>
        {booking.mall_name}
      </AppBar>
      <Grid>
        <Container>
          <Breadcrumbs
            menu={[
              { text: "การจองของฉัน", to: "/space/booking" },
              { text: booking?.booking_number },
            ]}
            sx={{ pb: 2 }}
          />
          <Typography fontSize={24} fontWeight="bold">
            {booking.mall_name}
          </Typography>
          <TransactionStatus {...booking} sx={{ mt: "30px", right: "11px" }} />
          <Grid container>
            <Box
              component={Image}
              src={booking.space_images?.[0]?.image_url ?? zonepic}
              preview={false}
              height={{ xs: 190, md: 300 }}
              width="100%"
              sx={{ mt: "20px", objectFit: "cover", borderRadius: 3 }}
            />
          </Grid>
        </Container>
        <Grid bgcolor="#fafafc" mt={2} pb={{ xs: 55, md: 2 }}>
          <Container>
            <Grid
              container
              mt={3}
              pt={3}
              pb={{ xs: gridMarginBottom, md: 6 }}
              spacing={2}
            >
              <Grid item xs={12} md={8}>
                <Grid
                  component={Paper}
                  variant="outlined"
                  p={3}
                  borderRadius={3}
                  container
                  direction="column"
                  sx={{ display: { xs: "none", md: "block" } }}
                >
                  <BookingDetail {...props} />
                </Grid>

                <Grid sx={{ display: { xs: "block", md: "none" } }}>
                  <BookingDetail {...props} />
                </Grid>
              </Grid>

              {/* desktop */}
              <Grid item md={4} sx={{ display: { xs: "none", md: "block" } }}>
                <Grid
                  container
                  component={Paper}
                  p={1}
                  variant="outlined"
                  sx={{ borderRadius: 3 }}
                >
                  {isPaid && <PaidSummary />}
                  <PaymentDetail {...props} />
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Grid>

        {/* mobile */}

        <MobileDrawer {...props} />
      </Grid>
    </>
  );
}

function BookingDetail(props) {
  const [booking, isDisabled, isPaid] = bookingStore((e) => [
    e.booking,
    e.isDisabled,
    e.isPaid,
  ]);
  const pendingPayment = paymentStore((e) => e.pendingPayment);

  return (
    <>
      <Status status={booking.booking_status} />
      {isDisabled && (
        <>
          {booking.booking_status !== "cancelled" && (
            <Summary
              totalDays={booking?.booking_date_range_info?.[0]?.total_days}
              boothPrice={booking.space_booth_price}
              totalPrice={booking.booking_total_price}
              disabled
            />
          )}
          <Divider sx={{ my: 3 }} />
        </>
      )}
      {booking.booking_status === "approved" &&
        booking.space_payment_service &&
        !pendingPayment && (
          <>
            {!isPaid && <PaymentMethod />}
            <Divider sx={{ my: 3 }} />
          </>
        )}
      <Detail {...booking} />
    </>
  );
}
