import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Paper } from "@mui/material";
import { PlaceOutlined } from "@mui/icons-material";
import Link from "common/components/link";
import { LazyLoadImage as Image } from "react-lazy-load-image-component";
import OriginSme from "assets/logo/origin_sme.svg";

export function MallComponent(props) {
  return (
    <MallWrapper>
      <MallContent {...props} />
    </MallWrapper>
  );
}

export function MallWrapper(props) {
  return (
    <Box
      sx={{ flexGrow: 1, textAlign: "-webkit-center" }}
      pb={{ xs: 5, md: 0 }}
    >
      <Grid
        container
        spacing={2}
        width={{ xs: "auto", md: props.width ?? 1120 }}
        p={{ xs: 0, md: 2 }}
        columns={{ xs: 12, sm: 12, md: 5 }}
      >
        {props.children}
      </Grid>
    </Box>
  );
}
export function MallContent(props) {
  return (
    props.malls?.[0] &&
    props.malls?.map((mall) => (
      <Grid item xs={6} sm={3} md={1} {...props.column}>
        <MallCard {...mall} />
      </Grid>
    ))
  );
}

export function MallCard(props) {
  return (
    <Paper
      variant="outlined"
      container
      sx={{
        borderRadius: 3,
      }}
    >
      <Grid
        container
        direction="column"
        component={Link}
        to={`/space/mall/${props.id}`}
      >
        <Box
          component={Image}
          src={props.image_url ?? OriginSme}
          sx={{
            ...(!props.image_url & { p: 4 }),
            width: "100%",
            borderRadius: 3,
            objectFit: "cover",
            aspectRatio: "1",
          }}
        />
        <Grid item container direction="column" p={1.5} textAlign="left">
          <Typography fontSize={16} width="inherit" noWrap>
            {props.mall_name}
          </Typography>
          <Typography fontSize={16} color="grey.600" width="inherit" noWrap>
            <PlaceIcon />
            {props.province}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

export const PlaceIcon = (props) => (
  <PlaceOutlined
    color="primary"
    sx={{ fontSize: 16, mb: -0.4, mr: 0.5, ...props.sx }}
    {...props}
  />
);
