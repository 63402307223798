import { axios, HeaderDefault, GetEnvironment } from "services/base.service";

export const getMall = (payload) => {
  return axios({
    url: `/v2/client/mall/search`,
    method: "post",
    params: payload,
    data: payload,
  }).then((res) => res?.data?.data);
};

export const getMallAll = (payload) => {
  return axios({
    url: `/v2/client/mall/search_all`,
    method: "get",
    params: payload,
  }).then((res) => res?.data?.data);
};

export const getMallInfo = ({ mall_id }) => {
  return axios({
    url: `/v1/mall/info`,
    params: { mall_id },
    method: "get",
  }).then((res) => res?.data?.data);
};

export const getMallsDistance = (payload) => {
  return axios({
    url: `/v1/mall/distance`,
    params: payload,
    method: "post",
  }).then((res) => res?.data?.data);
};

export const getMallsGroup = (payload) => {
  return axios({
    url: `/v2/client/mall/grouping`,
    params: payload,
    method: "get",
  }).then((res) => res?.data?.data);
};
