import React, { useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Box } from "@mui/material/";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import themeStore from "common/stores/theme.store";
import { Helmet } from "react-helmet";

export default function Components(props) {
  const location = useLocation();
  const theme = themeStore((e) => e.theme);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <ThemeProvider theme={createTheme(theme?.muiTheme)}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{theme?.title}</title>
        <link id="favicon" rel="icon" href={theme.icon} type="image/x-icon" />
      </Helmet>

      <CssBaseline />
      <Box mx="auto">
        <Box sx={{ minHeight: "100vh" }}>{props.children}</Box>
      </Box>
    </ThemeProvider>
  );
}
