import React, { useMemo, memo } from "react";
import { TextField, Autocomplete, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useMall } from "common/hooks/use-query/mall.hook";
import { useCampaign } from "common/hooks/use-query/recruit.hook";
import { useNavigate } from "react-router-dom";

function SearchBar(props) {
  const navigate = useNavigate();
  const { data: campaigns } = useCampaign();
  const { data } = useMall();
  const { result_data: malls } = { ...data };

  const optionsCampaign = campaigns?.[0]
    ? campaigns?.map((e) => ({
        type: "สมัครโครงการ",
        label: e.name,
        to: `/recruit/campaign/${e.id}`,
      }))
    : [];

  const optionsMall = malls?.[0]
    ? malls?.map((e) => ({
        type: "เช่าพื้นที่ขายของ",
        label: e.mall_name,
        to: `/space/mall/${e.id}`,
      }))
    : [];

  const options = [...optionsMall, ...optionsCampaign];

  function handleSelect(event, value) {
    navigate(value?.to);
  }

  return (
    <Autocomplete
      disablePortal
      label={false}
      id="global-search"
      groupBy={(option) => option.type}
      options={options?.[0] ? options : [{ label: "1" }]}
      onChange={handleSelect}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={props?.placeholder ? props?.placeholder : "หาพื้นที่เช่า" }
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{
            borderRadius: 3,
            boxShadow: "0px 6px 10px -1px rgba(6, 25, 56, 0.07)",
            "& legend": { width: "auto" },
            "& fieldset": {
              border: "1.5px solid",
              borderColor: "#EBEBEB",
            },
          }}
        />
      )}
      sx={{
        bgcolor: "white",
        borderRadius: 3,
        position: "relative",
        top: 20,
        borderColor: "#EBEBEB",
        "& .MuiOutlinedInput-root": {
          borderRadius: 3,
        },
      }}
    />
  );
}

export default memo(SearchBar);
