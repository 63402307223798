import { axios, HeaderDefault, GetEnvironment } from "services/base.service";
import dayjs from "dayjs";

export const getSpace = (payload) => {
  return axios({
    url: `/v1/space/search`,
    method: "post",
    data: payload,
  }).then((res) => res?.data?.data);
};

export const getSpaceInfo = ({ space_id }) => {
  return axios({
    url: `/v1/space/info`,
    params: { space_id },
    method: "get",
  }).then((res) => res?.data?.data);
};

export const getAvailableSpace = ({ space_id, fully_booked }) => {
  return axios({
    url: `/v1/space/available`,
    params: { space_id, fully_booked },
    method: "get",
  }).then((res) => res?.data?.data);
};

export const validateAvailable = ({ space_id, from_date, to_date }) => {
  return axios({
    url: `/v2/client/space/available/validation`,
    method: "get",
    params: {
      space_id,
      from_date: dayjs(from_date).format("YYYY-MM-DD"),
      to_date: dayjs(to_date).format("YYYY-MM-DD"),
    },
  }).then((res) => res?.data?.data);
};

export const getSpaceGroupingInfo = ({ group_id }) => {
  return axios({
    url: `/v2/client/space/grouping/info`,
    params: { group_id },
    method: "get",
  }).then((res) => res?.data?.data);
};

export const getSpaceGrouping = (payload) => {
  return axios({
    url: `/v2/client/space/grouping`,
    params: payload,
    method: "get",
  }).then((res) => res?.data?.data);
};
