import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import Success from "assets/img/success.png";
import { Link, useNavigate } from "react-router-dom";
import Page from "common/components/page";
import { MuiButton } from "common/components/button";
import bookingStore from "common/stores/booking.store";
import { LazyLoadImage as Image } from "react-lazy-load-image-component";
import _ from "lodash";

export default function SuccessShop() {
  const bookings = bookingStore((e) => e.booking);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(function () {
      // navigate(path || "/recruit");
    }, 5000);
  }, []);

  function renderBookingNumber() {
    return bookings.map((booking) => (
      <Typography
        variant="h5"
        fontWeight="bold"
        sx={{
          mt: 1,
          color: "#E34745",
        }}
      >
        {booking?.booking_number}
      </Typography>
    ));
  }

  return (
    <Page>
      <Box sx={{ mt: "10%", textAlign: "center" }}>
        <Typography variant="h5" mb={8}>
          <b>การจองพื้นที่สำเร็จ!</b>
        </Typography>
        <Image src={Success} width="155px" height="235px" />
        <Box
          sx={{
            bgcolor: "grey.100",
            margin: "0 auto",
            marginTop: "40px",
            marginBottom: "40px",
            p: "12px 30px",
            borderRadius: 3,
            maxWidth: "265px",
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            หมายเลขการจองของท่านคือ
          </Typography>
          {!_.isEmpty(bookings) && renderBookingNumber()}
        </Box>
        <Typography variant="h6">
          ท่านสามารถดูข้อมูลการจองได้ที่ หน้าการจอง
        </Typography>
        <MuiButton
          variant="contained"
          color="red"
          fullWidth={false}
          onClick={() => {
            navigate("/add-line-oa");
            localStorage.setItem("line_status", "/add-line-oa");
            localStorage.setItem("from_service", "/space/booking");
          }}
          sx={{
            mt: 6,
            mb: 4,
            py: 1.5,
            width: "-webkit-fill-available",
            mx: "15%",
            maxWidth: "265px",
          }}
          // onClick={}
        >
          ไปที่หน้าการจองของฉัน
        </MuiButton>
        <Typography
          variant="h6"
          sx={{ textDecoration: "underline" }}
          onClick={() => {
            navigate("/add-line-oa");
            localStorage.setItem("line_status", "/add-line-oa");
            localStorage.setItem("from_service", "/space");
          }}
        >
          <Link to="">กลับสู่หน้าหลัก</Link>
        </Typography>
      </Box>
    </Page>
  );
}
