import React, { useState, useEffect, useCallback } from "react";
import { Grid, Container, Paper, Typography, Link, Box } from "@mui/material";
import { FilterButton } from "modules/space/mall/home/filter";
import { AppBar, Footer } from "common/components/nav";
import { LoaderBody } from "common/components/spinner";
import Header from "modules/space/mall/explorer/header";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router";
import BottomNav from "modules/space/bottom-nav";
import mallStore from "common/stores/mall.store";
import * as mallService from "services/mall.service";
import GoogleMapReact from "google-map-react";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { useMallsDistance } from "common/hooks/use-query/mall.hook";

export default function MallExplorer(props) {
  const [
    mallsDistance,
    setMallsDistance,
    curLat,
    setCurLat,
    curLng,
    setCurLng,
    center,
    setCenter,
  ] = mallStore((e) => [
    e.mallsDistance,
    e.setMallsDistance,
    e.curLat,
    e.setCurLat,
    e.curLng,
    e.setCurLng,
    e.center,
    e.setCenter,
  ]);
  const [zoom, setZoom] = useState(5);

  const navigate = useNavigate();
  const { isLoading, refetch } = useMallsDistance({
    lat: curLat,
    lng: curLng,
  });

  const lat = localStorage.getItem("curLat");
  const lng = localStorage.getItem("curLng");

  async function resetLocation() {
    setCurLat(lat);
    setCurLng(lng);
    setCenter({ lat, lng });
  }

  // Initialize
  useEffect(() => {
    function getGeolocation() {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const newLat = position.coords.latitude;
        const newLng = position.coords.longitude;

        if (lat !== newLat && lng !== newLng) {
          localStorage.setItem("curLat", newLat);
          localStorage.setItem("curLng", newLng);
          setCurLat(newLat);
          setCurLng(newLng);
        }
      });
      setZoom(12);
    }

    getGeolocation();
  }, []);

  return (
    <Grid bgcolor="#FAFAFC">
      <AppBar menu>เช่าพื้นที่ขายของ</AppBar>
      <Container>
        <Header resetLocation={resetLocation} />
        <Grid container mt={4} columnSpacing={2} pb={{ xs: 15, md: 2 }}>
          <Grid item xs={12} md={12}>
            <Grid sx={{ display: { xs: "block", md: "block" } }}>
              <Paper
                variant="outlined"
                sx={{
                  borderRadius: "13px",
                  marginTop: "10px",
                }}
              >
                <Grid p="4px">
                  <Box
                    height={{
                      xs: 410,
                      md: 630,
                    }}
                    width="100%"
                    sx={{
                      borderRadius: "13px",
                      overflow: "hidden",
                    }}
                  >
                    {(_.isEmpty(mallsDistance) || isLoading) && (
                      <LoaderBody
                        sx={{
                          display: isLoading ? "block" : "none",
                          mt: { xs: "180px", md: "35vh" },
                        }}
                      />
                    )}
                    <Box
                      sx={{
                        display: isLoading ? "none" : "contents",
                      }}
                    >
                      <GoogleMapReact
                        bootstrapURLKeys={{
                          key: "AIzaSyCiQOnoorqvV4lPUBOxK_c5RRO91ZPU0mo",
                        }}
                        center={center}
                        zoom={zoom}
                      >
                        {!_.isEmpty(mallsDistance) &&
                          mallsDistance.map((item) => {
                            return (
                              <Box
                                sx={{
                                  display: "flex",
                                  minWidth: "250px",
                                  width: "100%",
                                }}
                                key={item?.id}
                                lat={item?.lat_lng?.lat}
                                lng={item?.lat_lng?.lng}
                                onClick={() =>
                                  navigate("/space/mall/" + item?.id)
                                }
                              >
                                <Box>
                                  <FmdGoodIcon
                                    sx={{ color: "#E34745", fontSize: "30px" }}
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    backgroundColor: "#fff",
                                    padding: "2px 8px",
                                    borderRadius: "5px",
                                    marginLeft: "-14px",
                                    zIndex: "-1",
                                    maxHeight: "30px",
                                    alignSelf: "center",
                                  }}
                                >
                                  <Typography fontSize="16px" fontWeight="700">
                                    {item?.name}
                                  </Typography>
                                </Box>
                              </Box>
                            );
                          })}
                      </GoogleMapReact>
                    </Box>
                  </Box>
                </Grid>
              </Paper>
              {/* <Paginate count={total_result_count} /> */}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <BottomNav selected="หน้าแรก" />
    </Grid>
  );
}
