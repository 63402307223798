import React, { useState, useEffect, createRef } from "react";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import { Item } from "common/components/form";

export default function ShopInfo(props) {
  return (
    <Grid container rowSpacing={0.6}>
      <Item xs={12} bold fontSize={16}>
        ข้อมูลส่วนตัว
      </Item>
      <Item xs={6} bold>
        ชื่อ:
      </Item>
      <Item xs={6}>
        {props?.user_prefix ?? props.prefix ?? ""}
        {props?.user_first_name ?? props.first_name ?? ""}{" "}
        {props?.user_last_name ?? props?.last_name ?? ""}
      </Item>
      <Item xs={6} bold>
        ชื่อร้าน:
      </Item>
      <Item xs={6}>{props?.shop_name ?? props?.name}</Item>
      <Item xs={6} bold>
        สินค้าและบริการ:
      </Item>
      <Item xs={6}>{props?.shop_business_type ?? props?.business_type}</Item>
      <Item xs={6} bold>
        ราคาสินค้าและบริการ:
      </Item>
      <Item xs={6}>
        {props?.shop_min_price ?? props?.min_price}-
        {props?.shop_max_price ?? props?.max_price} บาท
      </Item>
    </Grid>
  );
}
