import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginLine from "assets/img/lineIconLogin.jpg";
import { LazyLoadImage as Image } from "react-lazy-load-image-component";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  textAlign: "center",
};

export default function BasicModal(props) {
  const navigate = useNavigate();
  const handleOpen = () => props.setOpen(true);
  const handleClose = () => props.setOpen(false);

  useEffect(() => {
    props.setOpen(props.open);
  }, [props.open]);

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          กรุณาเข้าสู่ระบบด้วยไลน์
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          กรุณาเข้าสู่ระบบและ ทำการเพิ่ม Line Official เป็นเพื่อน
          เพื่อรับการแจ้งเตือนจากบริการของเรา
        </Typography>
        <Image
          // style={{ color: "rgb(90, 187, 173)" }}
          src={LoginLine}
          style={{ marginTop: "20px", cursor: "pointer" }}
          width="100%"
          onClick={() => {
            handleClose();
            "onClick" in props ? props?.onClick() : navigate("/auth/login");
          }}
        />
      </Box>
    </Modal>
  );
}
