import React, { useState, useEffect } from "react";
import { Chip, Grid, Divider, Paper, Typography } from "@mui/material";
import { CheckCircle, ChevronRight } from "@mui/icons-material";
import spaceStore from "common/stores/space.store";
import _ from "lodash";
import Item from "./booth.component";
import Link from "common/components/link";

export default function Detail() {
  const space = spaceStore((e) => e.space);

  return (
    <Paper variant="outlined" sx={{ borderRadius: 3 }}>
      <Grid container p={3} rowGap={2}>
        <Item xs={12} fontSize={20} fontWeight="bold">
          รายละเอียดพื้นที่
        </Item>
        {space.description && (
          <Item xs={12} py={2}>
            {space.description}
          </Item>
        )}
        <Item xs={6}>บริเวณพื้นที่</Item>
        <Item xs={6}>
          {space.floor ? `ชั้น ${Number(space.floor).toFixed(0)}` : "-"}
        </Item>

        <Item xs={6}>ประเภทสัญญา</Item>
        <Grid item xs={6}>
          <Chip
            label={space.contract_type}
            color="error"
            size="small"
            variant="outlined"
          />
        </Grid>

        <Item xs={6}>ขนาดพื้นที่</Item>
        <Item xs={6}>
          {space.booth_width} x {space.booth_length}
        </Item>
        <Divider component={Grid} item xs={12} />
        <CheckedList data={space.business_type} title="เหมาะสำหรับ" />
        <CheckedList data={space.amenity} title="สิ่งอำนวยความสะดวก" />
        <Grid
          component={Link}
          to="terms-and-conditions"
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ cursor: "pointer" }}
        >
          <Typography fontSize={20} fontWeight="bold">
            ข้อกำหนดและเงื่อนไข
          </Typography>
          <ChevronRight />
        </Grid>
      </Grid>
    </Paper>
  );
}

function CheckedList({ data, title }) {
  if (_.isEmpty(data)) return <></>;

  return (
    <>
      <Item xs={12} fontSize={20} fontWeight="bold">
        {title}
      </Item>
      {_.keys(data).map((e) => (
        <Item xs={12} md={6}>
          <CheckCircle
            color="primary"
            sx={{
              fontSize: 18,
              mb: -0.3,
              mr: 1,
            }}
          />
          {e}
        </Item>
      ))}
      <Divider component={Grid} item xs={12} />
    </>
  );
}
