import navigateHook from "common/hooks/router/navigate.hook";
import useQueryHook from "common/hooks/use-query";
import { useModal } from "mui-modal-provider";
import { useNavigate } from "react-router-dom";
import * as lineService from "services/line.service";
// import ErrorModal from "modules/settings/error/modal";
import ErrorModal from "common/modals/error";
import userStore from "common/stores/user.store";

export default function LineIntegrate() {
  const navigate = useNavigate();
  const { refetchStore } = useQueryHook();
  const { navigateFromService, navigateFromLineStatus } = navigateHook();
  const { showModal } = useModal();
  const isAuthenticated = userStore((e) => e.isAuthenticated);

  async function getToken(code) {
    try {
      const linePayload = await lineService.getToken(code);
      localStorage.setItem("access_token", `"${linePayload?.access_token}"`);
      localStorage.setItem("refresh_token", `"${linePayload?.refresh_token}"`);
    } catch (err) {
      navigateFromService();
    }
  }

  async function recieveCodeParam(code) {
    await getToken(code);
    isAuthenticated
      ? await integrateAuthenticated()
      : await integrateUnAuthenticated();
  }

  function handleDuplicated(err) {
    const modal = showModal(ErrorModal, {
      title: (
        <>
          พบ LINE Account นี้ <br />
          ได้มีการลงทะเบียนบนระบบแล้ว
        </>
      ),
      body: "ขออภัย LINE Account นี้ ได้มีการลงทะเบียนบนระบบแล้ว โปรดใช้ LINE Account ใหม่เพื่อดำเนินการต่อ",
      button: "ปิด",
      onClick: () => {
        navigate("/settings/profile");
        modal.hide();
      },
      close: false,
    });
  }

  async function integrateAuthenticated() {
    try {
      await lineService.integrate();
      navigate("/settings/profile");
    } catch (err) {
      console.log("line.integrate", err);
      handleDuplicated(err);
    }
  }

  async function integrateUnAuthenticated() {
    try {
      const { is_new_user, token, refresh_token } =
        await lineService.getLineLogin();
      if (is_new_user) {
        navigate("/register/line");
      } else {
        localStorage.setItem("token", `"${token}"`);
        localStorage.setItem("refresh_token", refresh_token);
        await refetchStore();
        navigateFromLineStatus();
      }
    } catch (err) {
      console.log("integrate hook -> getLineLogin", err);
      navigateFromLineStatus();
    }
  }

  return { recieveCodeParam };
}
