import React, { useState, useEffect } from "react";
import { Grid, Divider } from "@mui/material";
import PaymentMethod from "modules/space/booking/payment/select-method";
import {
  ApproveInfo,
  LandlordInfo,
} from "modules/space/booking/payment/info-box";
import PaymentSubmitLogic from "modules/space/booking/payment/payment.hook";

export default function PaymentMethodInfo(props) {
  const { paymentCondition } = PaymentSubmitLogic(props);

  return (
    <>
      {paymentCondition.isInstantPayment && (
        <PaymentMethod totalPrice={props?.total_price} {...props} />
      )}
      {paymentCondition.isRequireApprove && <ApproveInfo />}
      {paymentCondition.isDirectLandLord && <LandlordInfo />}
    </>
  );
}
