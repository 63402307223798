import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Drawer from "@mui/material/Drawer";
import Booking from "modules/space/booth/booking";

const drawerBleeding = 56;

function SwipeableEdgeDrawer(props) {
  const { window } = props;
  const [open, setOpen] = useState(true);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <Drawer
      anchor="bottom"
      open={open}
      variant="permanent"
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      swipeAreaWidth={drawerBleeding}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        ".MuiPaper-root": {
          zIndex: 1,
          borderRadius: 3,
          pb: { xs: 5, md: 0 },
          boxShadow: "0px -14px 21px -1px rgba(6, 25, 56, 0.08)",
        },
      }}
    >
      <Booking />
    </Drawer>
  );
}

SwipeableEdgeDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default SwipeableEdgeDrawer;
