import { Box, Typography } from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import { useQuery } from "react-query";
import axios from "axios";
import { includes } from "lodash";

export default function Body(props) {
	const isUrl = includes(props.text, "html");

	const { data, isLoading } = useQuery({
		queryKey: ["getT&cContent", props.text],
		queryFn: () => axios.get(props.text),
		enabled: isUrl,
		select: (res) => res.data,
	});

	return (
		<Box
			mx="auto"
			height={{
				xs: `calc(100vh - ${props.height})`,
				md: `calc(80vh - ${props.height})`,
			}}
		>
			<Box
				sx={{
					height: "inherit",
					bgcolor: props.bgcolor ?? "white",
					borderRadius: 3,
					p: 3,
					overflowY: "scroll",
					overflowX: "hidden",
				}}
			>
				<Typography
					fontWeight="bold"
					textAlign="center"
					sx={{
						fontSize: {
							xs: 20,
							sm: 30,
						},
						display: {
							xs: props?.disabledTitle ? "none" : "inherit",
							md: "inherit",
						},
					}}
				>
					{props.title ?? "ข้อกำหนดและเงื่อนไข"}
				</Typography>
				<br />
				<Typography fontSize={{ xs: 14, sm: 16 }}>
					{ReactHtmlParser(isUrl ? data : props.text)}
				</Typography>
			</Box>
		</Box>
	);
}
