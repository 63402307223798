import React, { useState, useEffect } from "react";
import { Button, TextField, InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";
import useStore from "common/stores/mall.store";

export default function SearchBar(props) {
  const { setPage, tempFilter, setTempFilter, setFilter } = useStore();

  function handleSearch(e) {
    setTempFilter({ mall_name: e.target.value });
  }

  return (
    <TextField
      placeholder="หาพื้นที่เช่า"
      fullWidth
      value={tempFilter?.mall_name}
      onChange={handleSearch}
      onKeyPress={(e) => {
        if (e.key === "Enter") setFilter();
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <Button
              variant="contained"
              onClick={setFilter}
              sx={{
                height: 42,
                mr: -1,
                px: 4,
                boxShadow: 0,
              }}
            >
              ค้นหา
            </Button>
          </InputAdornment>
        ),
      }}
      sx={{
        bgcolor: "white",
      }}
    />
  );
}
