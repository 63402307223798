import React, { useState, useEffect } from "react";
import Information from "modules/space/booking/id/preview/information";
import Footer from "modules/space/booking/id/preview/footer";
import bookingStore from "common/stores/booking.store";

export default function PaymentDetail(props) {
  const [booking, isDisabled, isPaid] = bookingStore((e) => [
    e.booking,
    e.isDisabled,
    e.isPaid,
  ]);

  return (
    <>
      {!isDisabled && !isPaid && (
        <Footer
          variant="contained"
          boothPrice={booking?.space_booth_price}
          totalPrice={booking.booking_total_price}
          totalDays={booking?.booking_total}
          bookingStatus={booking.booking_status}
          {...props}
          informationComponent={<Information booking={booking} />}
          disabledButton={
            booking.booking_status === "pending_approval" ||
            !booking.space_payment_service
          }
        />
      )}
    </>
  );
}
