import React, { useState, useEffect, createRef } from "react";
import Page from "common/components/page";
import { AppBar } from "common/components/nav";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import { useSpaceInfo } from "common/hooks/use-query/space.hook";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { LazyLoadImage as Image } from "react-lazy-load-image-component";
import { CheckCircle, Phone } from "@mui/icons-material";
import zonepic from "assets/img/fullZone.png";
import { AREA_TYPE } from "common/constants/booking";
import Item from "./item.component";
import { useMallInfo } from "common/hooks/use-query/mall.hook";
import bookingStore from "common/stores/booking.store";

export default function SpaceDetail(props) {
  const booking = bookingStore((e) => e.booking);

  const { data: space, isLoading: spaceIsLoading } = useSpaceInfo(
    booking.space_id
  );

  return (
    <>
      <Typography fontWeight="bold" fontSize={16}>
        ข้อมูลพื้นที่
      </Typography>
      <Box sx={{ bgcolor: "grey.200", borderRadius: 2, p: 1.5, mt: 1 }}>
        <Grid container rowSpacing={0.6} sx={{ fontSize: "16px" }}>
          <Item xs={12} bold>
            {booking.mall_name}
          </Item>
          <Item xs={12} fontSize={14} sx={{ pb: 1.5 }}>
            <LocationOnIcon color="red" />&nbsp;{booking.space_name}
          </Item>
          <Image
            src={booking.space_images?.[0]?.image_url ?? zonepic}
            preview={false}
            height="190px"
            width="100%"
            style={{
              marginTop: "5px",
              objectFit: "cover",
              borderRadius: "12px",
            }}
          />
          <Item xs={12} bold sx={{ pt: 2 }}>
            รายละเอียดพื้นที่
          </Item>
          <Item>ชื่อพื้นที่</Item>
          <Item>{space?.name}</Item>
          <Item>ชั้น</Item>
          <Item>
            {space?.floor} ({AREA_TYPE[space?.area_type ?? ""]})
          </Item>
          <Item>ประเภทสัญญา</Item>
          <Item>{space?.contract_type}</Item>
          <Item>ขนาดพื้นที่:</Item>
          <Item>
            {space?.booth_area
              ? `${space?.booth_area} ตรม. (${space?.booth_width}x${space?.booth_length} ม.)`
              : `${space?.booth_width}x${space?.booth_length} ม.`}
          </Item>
        </Grid>
        <Divider sx={{ my: 3 }} />
        <Grid container rowSpacing={0.6}>
          <ItemList title="เหมาะสำหรับ" values={space?.business_type} /> 
          <ItemList title="สิ่งอำนวยความสะดวก" values={space?.amenity} />
        </Grid>
        <Divider sx={{ my: 3 }} />
        <Contact mallId={booking.mall_id} />
      </Box>
    </>
  );
}

function ItemList(props) {
  return (
    <>
      <Item xs={12} bold sx={{ pt: 2 }}>
        {props.title}
      </Item>
      {props.values &&
        Object.keys(props.values).map(
          (value) =>
            value && (
              <Item xs={12}>
                <CheckCircle color="red" />
                &nbsp; {value}
              </Item>
            )
        )}
    </>
  );
}

function Contact(props) {
  const { data: mall, mallIsLoading } = useMallInfo(props.mallId);

  return (
    <>
      <Item xs={12} bold>
        ติดต่อสอบถาม
      </Item>
      <Item xs={12}>
        <Phone color="red" />
        &nbsp; เบอร์: {mall?.contact_number}
      </Item>
    </>
  );
}
