import { BrowserRouter, Routes, Route, Navigate, Fragment } from "react-router-dom";
import React, { useState, useEffect } from "react";
import * as lineService from "services/line.service";
import { useLocation } from "react-router-dom";
import AddLineOa from "pages/other/add-line-oa";
import CheckFriendShip from "pages/other/check-friendship";
import LineAuth from "pages/other/line-authorizing";

const lineRoute = () => [
  { exact: true, path: "/add-line-oa", element: <AddLineOa /> },
  { exact: true, path: "/check-friendship", element: <CheckFriendShip /> },
  { exact: true, desktop: true, path: "/line-auth", element: <LineAuth /> },
];

export default lineRoute;
