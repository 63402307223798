import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  Modal,
  Grid,
  Paper,
  SwipeableDrawer,
  // Select,
  IconButton,
  Typography,
  TextField,
} from "@mui/material";
import { FilterAlt, Close } from "@mui/icons-material";
import { useModal } from "mui-modal-provider";
import spaceStore from "common/stores/space.store";
import { isLaptop } from "common/utils/mediaQuery";
import FilterContent from "./content";
import FilterHeader from "./header";
import FilterFooter from "./footer";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: { md: 800, lg: 1109 },
  bgcolor: "background.paper",
  borderRadius: 3,
};

export default function Responsive(props) {
  return isLaptop() ? <FilterModal {...props} /> : <FilterDrawer {...props} />;
}

function FilterModal(props) {
  return (
    <Modal {...props}>
      <Box sx={style}>
        <FilterWrapper onClose={props.onClose} />
      </Box>
    </Modal>
  );
}

function FilterDrawer(props) {
  return (
    <Drawer {...props} anchor="right">
      <Box width="100vw">
        <FilterWrapper onClose={props.onClose} />
      </Box>
    </Drawer>
  );
}

function FilterWrapper(props) {
  return (
    <>
      {/* mobile */}
      <Grid
        container
        sx={{ display: { xs: "block", md: "none" } }}
        direction="column"
      >
        <Grid item xs={12}>
          <Grid
            container
            sx={{
              bgcolor: "white",
              position: "fixed",
              top: 0,
              zIndex: 1011,
              pt: 3,
            }}
          >
            <FilterHeader onClose={props.onClose} />
          </Grid>
        </Grid>

        <Grid item xs={12} pt="77px" pb="200px" sx={{ position: "absolute" }}>
          <FilterContent onClose={props.onClose} />
        </Grid>
        <Grid item xs={12} sx={{ position: "absolute", bgcolor: "white" }}>
          <FilterFooter onClose={props.onClose} />
        </Grid>
      </Grid>

      {/* desktop */}
      <Grid sx={{ display: { xs: "none", md: "block" } }}>
        <Paper variant="outlined" sx={{ borderRadius: 3, p: 2, pt: 4 }}>
          <FilterHeader onClose={props.onClose} />
          <FilterContent />
          <FilterFooter onClose={props.onClose} />
        </Paper>
      </Grid>
    </>
  );
}

export function FilterButton(props) {
  const { showModal } = useModal();

  function handleClick() {
    showModal(Responsive);
  }

  return (
    <>
      <Grid sx={{ display: { xs: "none", sm: "block" } }}>
        <Button
          size="large"
          startIcon={<FilterAlt />}
          sx={{
            color: "grey.800",
            fontSize: 16,
            ":hover": {
              bgcolor: "grey.200",
            },
          }}
          onClick={handleClick}
        >
          ตัวกรอง
        </Button>
      </Grid>

      <Grid sx={{ display: { xs: "block", sm: "none" } }}>
        <IconButton sx={{ color: "grey.800" }} onClick={handleClick}>
          <FilterAlt />
        </IconButton>
      </Grid>
    </>
  );
}
