import React from "react";
import { Container, Grid, Paper, Typography } from "@mui/material";
import Page from "common/components/page";
import { AppBar } from "common/components/nav";
import bookingStore from "common/stores/booking.store";
import { LazyLoadImage as Image } from "react-lazy-load-image-component";
import { Item } from "common/components/form";
import moment from "moment";
import smeLogo from "assets/img/sme_logo.png";
import useInterval from "use-interval";
import { LoaderBody } from "common/components/spinner";
import { FadeLoaderBody } from "common/components/spinner/fade-loader";
import themeStore from "common/stores/theme.store";

export default function PaymentMethod(props) {
  const booking = bookingStore((state) => state.booking);
  const theme = themeStore((e) => e.theme);

  useInterval(() => {
    props.goToNamedStep("preview");
  }, 3000);

  return (
    <Container sx={{ pt: "35px" }}>
      <AppBar onClick={() => props.goToNamedStep("preview")}>
        แสกนจ่ายเงิน
      </AppBar>
      <Grid
        container
        spacing={2}
        sx={props.disabled ? { color: "grey.500" } : {}}
      >
        <Grid item xs={12} md={6}>
          <Grid
            component={Paper}
            variant={{ xs: "none", md: "outlined" }}
            container
            direction="column"
            textAlign="center"
            borderRadius={3}
            rowSpacing={1}
            pb={20}
          >
            <Item bold fontSize={24} color="grey.600" mt={8}>
              ตรวจสอบการชำระเงิน
            </Item>
            <Item bold fontSize={16} mt={4}>
              ...ระบบกำลังพากลับหน้าบริการ...
            </Item>
            <Item bold fontSize={12}>
              โปรดอย่าปิดหน้าการทำงานของระบบระหว่างดำเนินการ
            </Item>
            <Grid item xs={12}>
              <FadeLoaderBody sx={{ mt: "70px" }} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6} sx={{ display: { xs: "none", md: "block" } }}>
          <Grid
            component={Paper}
            variant="outlined"
            container
            borderRadius={3}
            p={3}
            rowSpacing={1}
          >
            <Grid item xs={6}>
              <Image
                src={smeLogo}
                width="41px"
                style={{ height: "fit-content" }}
              />
            </Grid>
            <Grid item xs={6} textAlign="right" mb={1}>
              <Typography fontSize={19} fontWeight="bold">
                {theme.title}
              </Typography>
              <Typography fontSize={12}>
                Devloped by CP Origin Co., Ltd
              </Typography>
            </Grid>
            <Item xs={6}>Marchant:</Item>
            <Item xs={6} right>
              {booking?.company_name}
            </Item>
            <Item xs={6}>Amount:</Item>
            <Item xs={6} right>
              THB {booking?.booking_total_price}
            </Item>
            <Item xs={6}>Date:</Item>
            <Item xs={6} right>
              {moment(booking?.transaction_payment_datetime).format(
                "DD-MM-YYYY"
              )}
            </Item>
            <Item xs={6}>หมายเลขการสั่งซื้อ:</Item>
            <Item xs={6} right mb={7}>
              {booking.transaction_number}
            </Item>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
