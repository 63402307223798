import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import _ from "lodash";
import { TRANSACTION_STATUS } from "common/constants/booking";
import bookingStore from "common/stores/booking.store";

export default function TransactionStatus(props) {
  let status = props.transaction_status;

  if (!props.space_payment_service && props.transaction_status !== "paid") {
    status = "landlord_pending";
  }

  if (
    props.condition === "ยกเลิก" ||
    (_.isNil(status) && props.booking_status !== "approved")
  )
    return <></>;

  if (
    props.booking_status === "approved" &&
    ["expired", "failed"].includes(status)
  )
    status = "default";

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          mt: "13px",
          right: "14px",
          borderRadius: "5px",
          padding: "4px",
          color: "white",
          fontWeight: "bold",
          bgcolor: TRANSACTION_STATUS?.[status ?? "default"]?.color,
          ...props.sx,
        }}
      >
        {TRANSACTION_STATUS?.[status ?? "default"]?.label}
      </Box>
    </Box>
  );
}
