import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Button,
  Grid,
  Typography,
  TextField,
  IconButton,
} from "@mui/material";
import { FilterAlt, Close } from "@mui/icons-material";

export default function FilterHeader(props) {
  return (
    <Grid
      container
      justifyContent="space-between"
      direction="row"
      alignItems="center"
      pl={2}
      pb={2}
    >
      <Grid item>
        <Typography fontSize={20} fontWeight="bold">
          ตัวกรอง
        </Typography>
      </Grid>
      <IconButton
        size="large"
        onClick={props.onClose}
        sx={{
          bgcolor: "white",
        }}
      >
        <Close />
      </IconButton>
    </Grid>
  );
}
