import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import Breadcrumbs from "common/components/breadcrumbs";
import userStore from "common/stores/user.store";
import { useState } from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PhoneIcon from "@mui/icons-material/Phone";
import mapPinImage from "assets/img/main-page/map-pin.png";
import { LazyLoadImage as Image } from "react-lazy-load-image-component";
import { Footer } from "common/components/nav";
import { FooterButton } from "common/components/button";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useMallInfo } from "common/hooks/use-query/mall.hook";
import { useBookingDetailQuery } from "common/hooks/use-query/booking.hook";
import { AppBar } from "common/components/nav";

export default function ContactUs(props) {
  const { bookingId } = useParams();
  const { data: booking } = useBookingDetailQuery({ bookingId });
  const { isLoading, data: mall } = useMallInfo(booking?.mall_id);
  const navigate = useNavigate();

  return (
    <>
      <AppBar menu link="/space/booking">
        {booking.mall_name}
      </AppBar>
      <Grid
        sx={{
          pt: {
            xs: 0,
            sm: 10,
          },
        }}
      >
        <Container>
          <Breadcrumbs
            menu={[
              { text: "การจองของฉัน", to: "/space/booking" },
              {
                text: booking?.booking_number,
                to: `/space/booking/${bookingId}`,
              },
              { text: "ติดต่อ Landlord" },
            ]}
          />
          <Typography
            fontWeight="bold"
            sx={{
              fontSize: {
                xs: 20,
                sm: 30,
              },
            }}
            textAlign="center"
            mt={2}
          >
            ติดต่อ Landlord
          </Typography>
          <Paper
            variant="outlined"
            sx={{
              p: {
                xs: 2,
                sm: 4,
              },
              mt: 4,
              borderRadius: "15px",
            }}
          >
            <Grid container>
              <Grid item>
                <Image src={mapPinImage} height={21} width={21} />
              </Grid>
              <Grid
                item
                ml={1}
                sx={{
                  width: {
                    xs: 270,
                    sm: 620,
                    lg: "auto",
                  },
                }}
              >
                <Typography>{mall?.name}</Typography>
                <Typography>{mall?.address}</Typography>
              </Grid>
            </Grid>
            <Grid container mt={4}>
              <PhoneIcon sx={{ color: "#DC1A17" }} />
              <Typography ml={1}>{mall?.contact_number}</Typography>
            </Grid>
            <Footer>
              <FooterButton
                semiWidth
                onClick={() => navigate(`/space/booking/${bookingId}`)}
                sx={{ width: { sm: 280 } }}
              >
                กลับ
              </FooterButton>
            </Footer>
          </Paper>
        </Container>
      </Grid>
    </>
  );
}
