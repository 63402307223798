import React, { useState, useEffect } from "react";
import StepWizard from "react-step-wizard";
import { useParams } from "react-router-dom";
import { useBookingDetailQuery } from "common/hooks/use-query/booking.hook";
import TermsAndConditions from "modules/space/booking/payment/terms-and-conditions";
import Preview from "./preview";
import Loader from "common/components/spinner";
import PaymentMethod from "modules/space/booking/payment/method";
import SuccessBooking from "pages/space/booking/success";
import PromptpayQrcode from "modules/space/booking/payment/promptpay-qrcode";
import PromptpaySuccess from "modules/space/booking/payment/promptpay-success";
import HandleRedirect from "modules/space/booking/payment/handle-redirect.hook";
import ErrorModal from "modules/space/booking/payment/error/index";
import bookingStore from "common/stores/booking.store";
import _ from "lodash";
import stepWizardStore from "common/stores/step-wizard.store";

export default function BookingReview(props) {
  const { bookingId } = useParams();
  const booking = bookingStore((e) => e.booking);
  const { setStepWizard } = stepWizardStore();
  const { isLoading } = useBookingDetailQuery({ bookingId });

  if (_.isEmpty(booking) || isLoading) return <Loader />;

  return (
    <>
      <StepWizard
        isLazyMount
        isHashEnabled
        instance={setStepWizard}
        transitions={{}}
      >
        <Preview hashKey="preview" stepName="preview" {...props} />
        <PaymentMethod hashKey="method" stepName="method" {...props} />
        <PromptpayQrcode
          hashKey="promptpay-qrcode"
          stepName="promptpay-qrcode"
          {...props}
        />
        <PromptpaySuccess
          hashKey="promptpay-success"
          stepName="promptpay-success"
          {...props}
        />
        <HandleRedirect hashKey="handle" stepName="handle" {...props} />
      </StepWizard>
      <ErrorModal onClose={false} />
    </>
  );
}
