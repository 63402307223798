import React, { useState, useEffect } from "react";
import { Box, Container, Grid, Tab, Tabs } from "@mui/material";
import { LazyLoadImage as Image } from "react-lazy-load-image-component";
import { useParams } from "react-router-dom";
import { useMallInfo } from "common/hooks/use-query/mall.hook";
import { AppBar, Footer } from "common/components/nav";
import Breadcrumbs from "common/components/breadcrumbs";
import BottomNav from "modules/space/bottom-nav";
import locationGray from "assets/icon/mallDetail/location_gray.png";
import locationColor from "assets/icon/mallDetail/location_color.png";
import vectorGray from "assets/icon/mallDetail/vector_gray.png";
import vectorColor from "assets/icon/mallDetail/vector_color.png";
import MallDetail from "modules/space/mall/id/detail";
import SpaceList from "modules/space/mall/id/space";

export default function MallDetailComponent(props) {
  const { mallId } = useParams();
  const { data: mall } = useMallInfo(mallId);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <AppBar to="/space">{mall?.name}</AppBar>
      <Grid>
        <Container>
          <Breadcrumbs
            menu={[{ text: "พื้นที่เช่า", to: "/space" }, { text: mall?.name }]}
            sx={{ pb: 2 }}
          />
          <TabsDetail handleChange={handleChange} value={value} />
          <MallDetail mall={mall} value={value} />
        </Container>
      </Grid>
      <Grid bgcolor="#FAFAFC" py={3}>
        <Container>
          <SpaceList />
        </Container>
      </Grid>
      <BottomNav selected="หน้าแรก" />
    </>
  );
}

function TabsDetail({ handleChange, value }) {
  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        borderBottom: "1px solid",
        borderColor: "rgba(0, 0, 0, 0.12)",
      }}
    >
      <Tabs
        sx={{ height: "60px" }}
        value={value}
        onChange={handleChange}
        centered
      >
        <Tab
          sx={{ justifyContent: "center" }}
          icon={
            <Box
              component={Image}
              src={value === 0 ? vectorColor : vectorGray}
              width="18px"
              height="18px"
            />
          }
          iconPosition="start"
          label="ภาพสถานที่"
          value={0}
        />
        <Tab
          sx={{ justifyContent: "center" }}
          icon={
            <Box
              component={Image}
              src={value === 1 ? locationColor : locationGray}
              width="18px"
              height="18px"
            />
          }
          iconPosition="start"
          label="แผนที่   "
          value={1}
        />
      </Tabs>
    </Box>
  );
}
