import React, { useState, useEffect, createRef } from "react";
import { AppBar } from "common/components/nav";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import _ from "lodash";
import { Title, Description } from "common/components/form";
import { useParams } from "react-router-dom";
import { useBookingDetailQuery } from "common/hooks/use-query/booking.hook";
import Loader from "common/components/spinner";
import bookingStore from "common/stores/booking.store";
import BillingAddress from "modules/space/booking/billing-address";
import Item from "modules/space/booking/id/detail/item.component";
import SpaceDetail from "modules/space/booking/id/detail/space-detail";
import MobileDrawer from "modules/space/booking/id/drawer.mobile";

export default function PaymentMethod(props) {
  const { bookingId } = useParams();
  const booking = bookingStore((state) => state.booking);
  const { isLoading, refetch } = useBookingDetailQuery({
    bookingId,
  });
  const totalDays = _.sumBy(booking.booking_date_range_info, "total_days");

  if (_.isEmpty(booking)) return <Loader />;

  return (
    <Container>
      <AppBar link={`/space/booking/${bookingId}`}>รายละเอียดพื้นที่</AppBar>
      <DetailContent />
      <MobileDrawer
        isRequireApprove
        boothPrice={booking?.space_booth_price}
        totalDays={totalDays}
        totalPrice={Number(totalDays * booking.space_booth_price).toFixed(2)}
      />
    </Container>
  );
}

export function DetailContent(props) {
  return (
    <Grid container pb={{ xs: 55, md: 0 }}>
      <ShopInfo />
      <BillingAddress />
      <Divider component={Grid} xs={12} sx={{ my: 3 }} />
      <SpaceDetail />
    </Grid>
  );
}

function ShopInfo(props) {
  const booking = bookingStore((e) => e.booking);

  return (
    <Grid container>
      <Item xs={12} fontSize={18} mb={2}>
        ข้อมูลส่วนตัว
      </Item>
      <Title children="ชื่อ" />
      <Description
        children={`${booking?.user_prefix}
        ${booking?.user_first_name} ${booking?.user_last_name}`}
      />
      <Title children="ชื่อร้าน" />
      <Description children={booking?.shop_name} />
      <Title children="สินค้าและบริการ" />
      <Description children={booking?.shop_business_type} />
      <Title children="ราคาสินค้าและบริการ" />
      <Description
        children={`${booking?.shop_min_price}-${booking?.shop_max_price} บาท`}
      />
    </Grid>
  );
}
