import { Box, Container, Grid } from "@mui/material";

const boxStyle = { borderRadius: 2, p: 1, border: 1, borderColor: "#F5B469" };

export function ApproveInfo(props) {
  return (
    <Box sx={{ ...boxStyle, pb: 3 }}>
      <b>การชำระเงิน สามารถทำได้เมื่อได้รับการอนุมัติ</b>
      <p style={{ color: "#9EA5A5" }}>
        พื้นที่นี้ ต้องได้รับการอนุมัติจากเจ้าของพื้นที่ก่อนชำระเงิน
        เมื่อได้รับการอนุมัติ ท่านจะได้รับการแจ้งเตือนการชำระเงิน
        และสามารถเข้ามาชำระเงินที่หน้า "การจอง" ได้
      </p>
    </Box>
  );
}

export function LandlordInfo(props) {
  return (
    <Box sx={{ ...boxStyle, pb: 2 }}>
      <b>ชำระเงินผ่านเจ้าของพื้นที่โดยตรง</b>
      <p style={{ color: "#9EA5A5" }}>
        พื้นที่นี้ ต้องติดต่อกับเจ้าของพื้นที่เพื่อทำการชำระเงินโดยตรง
        (ระยะเวลาในการติดต่อกลับ 3-7 วัน)
      </p>
    </Box>
  );
}
