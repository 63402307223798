import React, { useState, useEffect } from "react";
import { BOOKING_INFORMATION } from "common/constants/booking";
import { Grid, Box, Button, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import moment from "moment";
import { Item } from "common/components/form";

export default function Information(props) {
  const bookingInformation = {
    ...BOOKING_INFORMATION(props.booking),
  };

  if (bookingInformation.variant === "empty") return <></>;

  return props.booking.booking_status === "cancelled" ? (
    <CancelledInformation
      expiredDate={props.booking.latest_status_log?.created_at}
      note={props.booking.latest_status_log?.note}
    />
  ) : (
    <InformationBox {...bookingInformation} />
  );
}

function CancelledInformation(props) {
  return (
    <>
      <Grid container rowSpacing={0.4}>
        <Item fontSize={16} xs={12} bold mb={0.7} color="#F01C31">
          ยกเลิกการจอง
        </Item>
        <Item fontSize={16} xs={6} color="#F01C31">
          วัน-เวลาที่ยกเลิก
        </Item>
        <Item fontSize={16} xs={6} color="#F01C31" right>
          {moment(props.expiredDate).format("DD MMM YY hh:mm")}
        </Item>
        <Item fontSize={16} xs={6}>
          สถานะ
        </Item>
        <Item fontSize={16} xs={6} color="#A1A6AA" right>
          ยกเลิกสำเร็จ - ไม่คืนเงิน
        </Item>
        <Item fontSize={16} xs={6}>
          เหตุผล
        </Item>
        <Item fontSize={16} xs={6} color="#A1A6AA" right>
          {props.note}
        </Item>
      </Grid>
    </>
  );
}

export function InformationBox(props) {
  const template = {
    warning: {
      color: "#F5B469",
      icon: <InfoOutlined sx={{ color: "#F5B469" }} />,
    },
    danger: {
      color: "#F01C31",
      titleColor: "#F01C31",
      icon: <InfoOutlined sx={{ color: "#F01C31" }} />,
    },
  };

  return (
    <Box
      sx={{
        p: 1,
        mb: 2,
        border: 1,
        borderRadius: 2,
        borderColor: template[props.variant]?.color,
        ...props.sx,
      }}
    >
      <Grid container>
        {template[props.variant].icon}
        <Typography
          fontSize={16}
          sx={{
            ml: 1,
            pb: 1,
            color: template[props.variant].titleColor ?? "auto",
          }}
        >
          {props.title}
        </Typography>
      </Grid>
      <Typography fontSize={14} color="grey.500" children={props.description} />
    </Box>
  );
}
