import React, { useState, useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import SuccessIcon from "assets/img/shopSuccess.png";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import {
  useShopQuery,
  usePrimaryShopQuery,
  useUserQuery,
} from "common/hooks/use-query";
import Page from "common/components/page";
import { BorderLinearProgress } from "common/components/progress";
import { isTablet } from "common/utils/mediaQuery";

export default function SuccessLoading(props) {
  const [loadingValue, setLoadingValue] = useState(0);
  const path = localStorage.getItem("from_service");

  useEffect(() => {
    const setInterval = () => {
      setTimeout(() => setLoadingValue(loadingValue + 2), 100);
    };

    if (loadingValue < 100) setInterval();
  }, [loadingValue]);

  return (
    <Page>
      <Box sx={{ mt: "95px", textAlign: "center" }}>
        <Typography variant="h6">
          <b>{props.title}</b>
        </Typography>
        <img
          src={SuccessIcon}
          style={{ marginTop: "30px" }}
          alt="success-icon"
        />
        <BorderLinearProgress
          variant="determinate"
          value={loadingValue}
          sx={{ my: 8, mx: isTablet() ? "30%" : "10%" }}
        />
        <Typography variant="h6" mb={4.2}>
          <b>โปรดรอซักครู่</b>
        </Typography>
        <Typography variant="h6" mb={2}>
          <b>{props.description}</b>
        </Typography>
        <Typography variant="h6">
          ภายใน 5 วินาที หรือคุณสามารถกดเลือก <br />
          {props.linkText ?? (
            <Link to={path || "/recruit"}>กลับสู่หน้าบริการ</Link>
          )}
          ได้หากไม่ต้องการรอ
        </Typography>
      </Box>
    </Page>
  );
}
