import { Grid, Box, Button, Typography, Skeleton } from "@mui/material";
import { LazyLoadImage as Image } from "react-lazy-load-image-component";
import SuccessBubble from "assets/img/success-bubble-01.png";
import location from "common/hooks/router/location.hook";
import { Link, Navigate } from "react-router-dom";

export default function Success(props) {
  const { defaultRoute } = location();

  return (
    <Box sx={{ textAlign: "-webkit-center" }}>
      <Skeleton
        variant="rectangular"
        height={81}
        width={81}
        sx={{ marginTop: "8%" }}
      />
      <Typography
        variant="h6"
        mt={4}
        mb={5}
        sx={{ fontSize: "15px", color: "#5AC98D" }}
      >
        <Skeleton width={280} />
      </Typography>
      <Grid mx={1}>
        <Link to={defaultRoute}>
          <Button
            size="large"
            variant="info"
            sx={{
              fontSize: "15px",
              color: "#18A0FB",
              py: "12px",
            }}
          >
            กลับสู่หน้าบริการ
          </Button>
        </Link>
      </Grid>
    </Box>
  );
}
