import * as React from "react";
import { AppBar, Box, Toolbar, Typography, IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import themeStore from "common/stores/theme.store";
import { ChevronLeft } from "@mui/icons-material";
import _ from "lodash";
import StepWizardStore from "common/stores/step-wizard.store";
import { useModal } from "mui-modal-provider";
import { Menu } from "@mui/icons-material";
import MobileMenu from "modules/recruit/header/menu/mobile.js";
import useVariant from "common/styles/variants";

export default function MobileAppBar(props) {
  const stepWizard = StepWizardStore((e) => e.stepWizard);
  const maxWidth = themeStore((e) => e.maxWidth);
  const navigate = useNavigate();
  const location = useLocation();
  const variants = useVariant();

  function handleBack() {
    try {
      if (props.stepper) {
        const currentStep = stepWizard?.state?.activeStep;
        currentStep > 0 ? handleStep() : handleNavigate();
        return;
      }
      handleNavigate();
    } catch (err) {
      handleNavigate();
    }
  }

  function handleStep() {
    stepWizard.previousStep();
  }

  function handleNavigate() {
    try {
      if (location.pathname.includes("/how-to")) {
        return navigate("/recruit");
      }
      navigate(props.link ?? props.to ?? -1);
    } catch (err) {
      const path = location.pathname.split("/")[1];
      navigate(`/${path}`);
    }
  }

  function checkStepperNav() {
    const isNav = !_.isEmpty(stepWizard?.props?.nav);
    const steps = stepWizard?.props?.nav?.props?.steps;
    const activeStep = stepWizard?.state?.activeStep;
    const isSkip = steps?.[activeStep]?.skip;

    if (isNav) {
      return isSkip ? "75px" : "0px";
    }
    return "75px";
  }

  return (
    <Box sx={{ pb: props.pb ?? checkStepperNav() }}>
      <AppBar
        id="MobileAppBar"
        sx={{
          ...variants[props.variant ?? "default"],
          maxWidth: maxWidth,
          mx: "auto",
          textAlign: "center",
          fontSize: "18px",
          fontWeight: "bold",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.08)",
          zIndex: 1100,
          ...props.style,
          ...props.sx,
        }}
      >
        <Toolbar sx={{ px: 0 }}>
          {!props.leftButton && !props.disabledBack ? (
            <IconButton
              color="inherit"
              sx={{
                position: "absolute",
                // left: "10px",
                fontSize: "14px",
                zIndex: 100,
              }}
              onClick={props.onClick ?? handleBack}
            >
              {props.icon ?? <ChevronLeft />}
            </IconButton>
          ) : (
            props.leftButton
          )}
          <Typography
            variant="h6"
            component="div"
            sx={{
              minWidth: 300,
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              flexGrow: 1,
              py: "5px",
              mx: "auto",
            }}
          >
            <b>{props.children}</b>
          </Typography>
          {props.menu ? (
            <MenuButton {...props.menuProps} />
          ) : (
            !!props.rightButton && props.rightButton
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

function MenuButton(props) {
  const { showModal } = useModal();
  return (
    <IconButton
      onClick={() => showModal(MobileMenu)}
      sx={{
        position: "absolute",
        right: "10px",
        color: "inherit",
        ...props.menuProps,
      }}
    >
      <Menu sx={{ color: "inherit" }} />
    </IconButton>
  );
}
