import React, { useState, useEffect } from "react";
import { Icon } from "semantic-ui-react";
import { Box, Grid, Typography, Divider, Tabs, Tab } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import { Title, Description } from "common/components/form";
import Support from "./support";
import { DetailContent } from "pages/space/booking/id/detail";
import Item from "modules/space/booking/id/detail/item.component";
import CustomLink from "common/components/link";

export default function ResponsiveDetail(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid mt={3}>
      <Grid container justifyContent="space-between">
        <Typography
          fontSize={24}
          children="ข้อมูลพื้นที่"
          fontWeight="bold"
          color="grey.700"
        />
        <Link to={`/space/booking/${props.booking_id}/detail`}>
          <Grid container sx={{ display: { xs: "flex", md: "none" } }}>
            <Typography fontSize={16} children="รายละเอียดพื้นที่" />
            <ChevronRight />
          </Grid>
        </Link>
      </Grid>

      <Grid container sx={{ display: { xs: "none", md: "block" } }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="รายละเอียดการจอง" />
            <Tab label="รายละเอียดพื้นที่" />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Detail {...props} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DetailContent />
        </TabPanel>
      </Grid>

      <Grid container sx={{ display: { xs: "block", md: "none" } }}>
        <Divider sx={{ my: 2 }} />
        <Detail {...props} />
      </Grid>
    </Grid>
  );
}

function Detail(props) {
  const navigate = useNavigate();

  return (
    <Grid container direction="column">
      {!_.isEmpty(props.booking_date_range_info) && (
        <Grid container item xs={12}>
          <Grid item xs={6} sx={{ marginTop: "5px" }}>
            <Title children="วันที่เริ่มต้น" />
            {props.booking_date_range_info.map((bd) => (
              <Description
                children={moment(bd.from_date).format("ll")}
                mb={0}
              />
            ))}
          </Grid>
          <Grid item xs={6} sx={{ marginTop: "5px" }}>
            <Title children="วันที่สิ้นสุด" />
            {props.booking_date_range_info.map((bd) => (
              <Description children={moment(bd.to_date).format("ll")} mb={0} />
            ))}
          </Grid>
        </Grid>
      )}
      <Divider sx={{ my: 2 }} />
      <Grid container direction="row">
        <Title children="ให้เช่าพื้นที่โดย" />
        <Description children={props.mall_name} mb={0} />

        <Divider component={Grid} xs={12} sx={{ my: 2 }} />
      </Grid>

      <Grid container direction="row">
        <Item xs={12} fontSize={18} children="รายละเอียดการจอง" mb={2} />

        <Title children="หมายเลขการจอง" />
        <Description children={props.booking_number} />

        <Title children="ชื่อร้านค้า" />
        <Description children={props.shop_name} />

        <Title children="ชื่อสกุลผู้จอง" />
        <Description
          mb={2}
          children={`${props.user_first_name} ${props.user_last_name}`}
        />

        <Title children="เบอร์โทร" />
        <Description children={props.user_phone} />
      </Grid>

      <Divider sx={{ mb: 3, mt: 2 }} />

      <Grid item xs={12}>
        <Grid
          container
          component={CustomLink}
          to={`/space/mall/booth/${props.space_id}/terms-and-conditions`}
          alignItems="center"
          spacing={1}
          // direction="row"
          // direction="row"
        >
          <Grid item><Icon name="book" style={{ color: "grey" }} /></Grid>
          <Grid item>
            <Typography color="grey.700" fontSize={16} fontWeight="bold">
              ข้อกำหนดและเงื่อนไข
            </Typography>
          </Grid>
          <Grid item ml="auto">
            <ChevronRight sx={{ color: "grey.500" }} />
          </Grid>
        </Grid>
      </Grid>

      <Divider sx={{ mb: 3, mt: 2 }} />
      <Support />
    </Grid>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Grid pt={3}>{children}</Grid>}
    </div>
  );
}
