import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";
import Loader from "common/components/spinner";
import { useBookingDetailQuery } from "common/hooks/use-query/booking.hook";
import errorStore from "common/stores/error.store";
import bookingStore from "common/stores/booking.store";
import ERROR_MESSAGE from "common/constants/error/payment";

export default function HandleRedirect(props) {
  const { bookingId } = useParams();
  const booking = bookingStore((state) => state.booking);
  const setErrorModal = errorStore((state) => state.setOpen);
  const setError = errorStore((state) => state.setError);

  const { isLoading, isFetching } = useBookingDetailQuery({
    bookingId,
  });

  useEffect(() => {
    if (!_.isEmpty(booking)) {
      if (booking?.transaction_status === "paid") {
        props.goToNamedStep("preview");
      } else if (booking?.transaction_status === "failed") {
        setError(ERROR_MESSAGE.TRUEMONEY.FAILED_PROCESSING);
        setErrorModal(true);
        props.goToNamedStep("preview");
      }
    }
  }, [booking]);

  return <Loader />;
}
