import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Chip,
  Grid,
  Divider,
  IconButton,
  Paper,
  Typography,
  Tab,
  Tabs,
} from "@mui/material";
import { FilterAlt, LocalOfferOutlined } from "@mui/icons-material";
import { TbStack2 } from "react-icons/tb";
import { LazyLoadImage as Image } from "react-lazy-load-image-component";
import { useParams } from "react-router-dom";
import { AppBar, Footer } from "common/components/nav";
import Breadcrumbs from "common/components/breadcrumbs";
import mallStore from "common/stores/mall.store";
import { PlaceIcon } from "modules/space/mall.component";
import { useSpace } from "common/hooks/use-query/space.hook";
import Link from "common/components/link";
import OriginSme from "assets/logo/origin_sme.svg";
import { formatCurrency } from "common/utils/intl";
import BottomNav from "modules/space/bottom-nav";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import GoogleMapReact from "google-map-react";
import locationGray from "assets/icon/mallDetail/location_gray.png";
import locationColor from "assets/icon/mallDetail/location_color.png";
import vectorGray from "assets/icon/mallDetail/vector_gray.png";
import vectorColor from "assets/icon/mallDetail/vector_color.png";
import MallDetail from "modules/space/mall/id/detail";
import SpaceList from "modules/space/mall/campaign/space";
import useStore from "common/stores/mall.store";
import spaceStore from "common/stores/space.store";
import Loader from "common/components/spinner";
import { useSpaceGrouping } from "common/hooks/use-query/space.hook";
import { useMallInfo } from "common/hooks/use-query/mall.hook";

export default function MallDetailComponent(props) {
  const { groupId, mallId } = useParams();
  const [value, setValue] = useState(0);

  const mallsGroup = useStore((e) => e.mallsGroup);
  const { data: mall } = useMallInfo(mallId);
  // if (isLoadingSpaceGroupingInfo) return <Loader />;

  console.log(groupId, mallId)
  console.log('mall', mall)
  // console.log('spaceGrouping', spaceGrouping)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <AppBar>{mall?.name}</AppBar>
      <Grid>
        <Container>
          <Breadcrumbs
            menu={[{ text: "พื้นที่เช่า", to: "/space" }, { text: mall?.name }]}
            sx={{ pb: 2 }}
          />
          <TabsDetail handleChange={handleChange} value={value} />
          <MallDetail mall={mall} value={value} />
        </Container>
      </Grid>
      <Grid bgcolor="#FAFAFC" py={3}>
        <Container>
          <SpaceList />
        </Container>
      </Grid>
      <BottomNav selected="หน้าแรก" />
    </>
  );
}

function TabsDetail({ handleChange, value }) {
  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        borderBottom: "1px solid",
        borderColor: "rgba(0, 0, 0, 0.12)",
      }}
    >
      <Tabs
        sx={{ height: "60px" }}
        value={value}
        onChange={handleChange}
        centered
      >
        <Tab
          sx={{ justifyContent: "center" }}
          icon={
            <Box
              component={Image}
              src={value === 0 ? vectorColor : vectorGray}
              width="18px"
              height="18px"
            />
          }
          iconPosition="start"
          label="ภาพสถานที่"
          value={0}
        />
        <Tab
          sx={{ justifyContent: "center" }}
          icon={
            <Box
              component={Image}
              src={value === 1 ? locationColor : locationGray}
              width="18px"
              height="18px"
            />
          }
          iconPosition="start"
          label="แผนที่   "
          value={1}
        />
      </Tabs>
    </Box>
  );
}
