import React, { useState, useEffect } from "react";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import formStore from "common/stores/form.store";

export function ValidatorHook(props) {
  const [ref, setRef] = useState({});
  const [formInvalid, setFormInvalid] = useState(true);
  const [form, setForm] = formStore((e) => [e.form, e.setForm]);

  ValidatorForm.addValidationRule("isThai", (value) => {
    const isNotThai = /[^\u0E00-\u0E7F]/.test(value);
    const isOther = /[a-zA-Z0-9'^$&+,:;=?@#|'<>.^*()%!-]/.test(value);
    if (isOther || isNotThai) {
      return false;
    }
    return true;
  });

  async function isFormValid() {
    const fn = ref.isFormValid();
    const result = await fn;
    setFormInvalid(!result);
  }

  function setFormRef(e) {
    setRef(e);
  }

  useEffect(() => {
    if ("isFormValid" in ref) isFormValid();
  }, [form, ref]);

  return {
    ValidatorForm,
    TextValidator,
    SelectValidator,
    setFormRef,
    formInvalid,
  };
}
