import { useNavigate } from "react-router-dom";
import LocationHook from "./location.hook";
import _ from "lodash";

export default function Location() {
  const navigate = useNavigate();
  const { defaultRoute } = LocationHook();
  const fromService = localStorage.getItem("from_service");

  function navigateFromService() {
    navigate(fromService ?? defaultRoute);
  }

  function navigateFromLineStatus() {
    const lineStatus = localStorage.getItem("line_status");
    if (_.isNil(lineStatus)) {
      return navigateFromService();
    } else {
      return navigate(lineStatus);
    }
  }

  return { navigateFromService, navigateFromLineStatus };
}
