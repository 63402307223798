import { useTheme } from "@mui/material/styles";

export default function useVariant() {
  const theme = useTheme();

  const variants = {
    linearGradient: {
      color: "white",
      background: `linear-gradient(149.14deg, ${theme.palette.primary.main} 14.89%, ${theme.palette.primary.dark} 76.84%);`,
    },
    primary: {
      color: "white",
      background: theme.palette.primary.main,
    },
    default: {
      color: "#123735",
      background: "white",
    },
  };

  return variants;
}
