import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import { FooterButton } from "common/components/button";
import { Footer } from "common/components/nav";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import paymentStore from "common/stores/payment.store";
import { Item } from "common/components/form";
import userStore from "common/stores/user.store";
import shopStore from "common/stores/shop.store";
import Price from "common/components/price";
import * as userService from "services/user.service";
import bookingStore from "common/stores/booking.store";
import { PAYMENT } from "common/constants/booking";
import { Method } from "modules/space/booking/payment/method";

import stepWizardStore from "common/stores/step-wizard.store";

export default function PaymentMethod() {
  const booking = bookingStore((e) => e.booking);
  const [payment, setPayment] = paymentStore((e) => [e.payment, e.setPayment]);
  const stepWizard = stepWizardStore((e) => e.stepWizard);

  let [searchParams] = useSearchParams();
  function handleOnClick() {
    booking.booking_total_price > 0 && stepWizard.goToNamedStep("method");
  }

  useEffect(() => {
    const type = searchParams.get("type");
    if (!_.isEmpty(type)) setPayment({ ...PAYMENT[type] });
  }, [searchParams]);

  return (
    <>
      <Grid container rowSpacing={1}>
        <Item xs={12} bold children="ช่องทางการชำระเงิน" />
      </Grid>

      <Grid
        style={{
          textDecoration: "none",
          color: booking.booking_total_price > 0 ? "grey.100" : "inherit",
        }}
      >
        {_.isEmpty(payment) ? (
          <Method
            name="เลือกการชำระเงิน"
            onClick={handleOnClick}
            disabledOnClick
            rightArrow
          />
        ) : (
          <Method
            {...payment}
            onClick={handleOnClick}
            disabledOnClick
            rightArrow
          />
        )}
      </Grid>
    </>
  );
}
