import React, { Component } from "react";
import { Box, Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import userStore from "common/stores/user.store";
import { ModalLogin } from "common/components/modal";
import _ from "lodash";

export const AnchorLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: inherit;
  }
`;

export default function CustomLink(props) {
  const { user } = userStore();
  const navigate = useNavigate();
  const { showLoginModal } = ModalLogin();

  function handleClick() {
    if (props.checkAuth && _.isEmpty(user)) {
      showLoginModal();
      return;
    }
    navigate(props.to);
  }

  return <Box {...props} onClick={handleClick} sx={{ cursor: "pointer" }} />;
}
