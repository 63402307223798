import axios from "axios";
import userStore from "common/stores/user.store";

const API_URL = process.env.REACT_APP_API_URL;

export const HeaderDefault = () => ({
  baseURL: API_URL,
  timeout: 100000,

  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Content-Type": "application/json",
  },
});

// errorComposer will compose a handleGlobally function
const checkUnauthorized = (error) => {
  const statusCode = error.response ? error.response.status : null;
  const Url = error.response.config.url;
  const whitelist = ["/v1/line/oa/friendship"];

  if (whitelist.includes(Url)) {
    return;
  }

  if (statusCode === 401) {
    userStore.getState().clearUser();
    // localStorage.removeItem("token");
  }
};

let api = axios.create(HeaderDefault());

api.interceptors.request.use((request) => {
  request.headers["Authorization"] = `Bearer ${JSON.parse(
    localStorage.getItem("token")
  )}`;
  return request;
});

api.interceptors.response.use(
  (res) => res,
  (error) => {
    checkUnauthorized(error);
    const err = error.response?.data;
    console.error(err);
    throw err;
  }
);

export { api as axios };
