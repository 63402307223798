import userStore from "common/stores/user.store";
import shopStore from "common/stores/shop.store";
import * as userService from "services/user.service";
import { useMutation } from "react-query";
import * as authenService from "services/authen.service";
import useLocalStorage from "use-local-storage";
import {
  useMutateUser,
  useMutateShop,
  useMutatePrimaryShop,
} from "common/hooks/use-query";

export function useMutateLogin() {
  const { mutateAsync: mutateUser } = useMutateUser();
  const { mutateAsync: mutateShop } = useMutateShop();
  const { mutateAsync: mutatePrimaryShop } = useMutatePrimaryShop();

  return useMutation({
    mutationKey: ["mutateLogin"],
    mutationFn: (payload) => authenService.login(payload),
    onSuccess: async (data) => {
      mutateShop();
      await mutateUser();
      await mutatePrimaryShop();
    },
    onError: (err) => {
      console.error("get login: ", err);
      throw err;
    },
  });
}

export function useMutateLogout() {
  const clearUser = userStore((e) => e.clearUser);
  const clearShops = shopStore((e) => e.clearShops);
  return useMutation({
    mutationKey: "logout",
    mutationFn: authenService.logout,
    onSuccess: () => {
      clearUser();
      clearShops();
      localStorage.clear();
    },
  });
}
