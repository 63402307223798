import React, { useState, useEffect } from "react";
import {
  Grid,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select as MuiSelect,
  Checkbox,
  Box,
  Chip,
  styled,
} from "@mui/material";
import _ from "lodash";
import CancelIcon from "@mui/icons-material/Cancel";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function Select(props) {
  return (
    <Grid item xs={props.xs ?? 12} mb={props.sm ?? 4}>
      <FormControl required={props.required} fullWidth>
        <InputLabel>{props.label}</InputLabel>
        <MuiSelect
          label={props.label}
          value={props.value}
          onChange={props.onChange}
        >
          {!_.isEmpty(props.options) &&
            _.map(props.options, (item, key) => (
              <MenuItem
                key={props.hasKey ? key : item}
                value={props.hasKey ? key : item}
              >
                {item}
              </MenuItem>
            ))}
        </MuiSelect>
      </FormControl>
    </Grid>
  );
}

export function MultiSelect(props) {
  const [selected, setSelected] = useState([]);
  console.log("props.items", props.items);
  console.log("selected", selected);
  console.log("props.value", props.value);

  function handleChange(event) {
    const {
      target: { value },
    } = event;

    setSelected(value);
    props.onChange(value);
  }

  function handleValue(value) {
    if (_.isArray(value)) return value;
    if (typeof value === "string") return [...value];
    if (typeof value === "object") return Object.keys(_.pickBy(value, Boolean));
  }

  useEffect(() => {
    setSelected(props.value);
  }, []);

  return (
    <Grid item xs={props.xs ?? 12} sm={props.sm ?? 4}>
      <FormControl fullWidth>
        <InputLabel id="rjsf-multiple-chip-label" required={props.required}>
          {props.label}
        </InputLabel>
        <MuiSelect
          labelId="rjsf-multiple-chip-label"
          id="demo-multiple-chip"
          fullWidth
          multiple
          value={handleValue(props.value) ?? []}
          onChange={handleChange}
          label={props.label}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {!_.isEmpty(selected) &&
                _.map(selected, (value, key) => {
                  const text = _.isString(value)
                    ? value
                    : _.find(props.items, { id: value })?.name;

                  return (
                    <Chip
                      key={key}
                      label={text}
                      {...(props.chipDeletable && {
                        onMouseDown: (event) => event.stopPropagation(),
                        deleteIcon: <CancelIcon fontSize="small" />,
                        onDelete: () => {
                          const result = selected.filter(
                            (filterSelect) => value !== filterSelect
                          );
                          setSelected(result);
                          props.setValue(result);
                        },
                      })}
                    />
                  );
                })}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          <MenuItem disabled value="">
            <em>โปรดระบุ</em>
          </MenuItem>
          {!_.isEmpty(props.items) &&
            _.map(props.items, (item) => {
              const text = _.isString(item) ? item : item?.name ?? item?.label;
              const value = _.isString(item) ? item : item?.id;
              const checked = _.isString(item)
                ? selected.includes(item)
                : selected.includes(item.id);
              return (
                <MenuItem key={text} value={value}>
                  <Checkbox checked={checked} />
                  <ListItemText primary={text} />
                </MenuItem>
              );
            })}
        </MuiSelect>
      </FormControl>
    </Grid>
  );
}
