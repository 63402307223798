import * as React from "react";
import {
  Box,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { Close } from "@mui/icons-material";
import Carousel from "common/components/swiper";

export default function SwiperDialog(props) {
  const FakeTransitionComponent = ({ children }) => children;
  //
  return (
    <Dialog
      {...props}
      // TransitionComponent={FakeTransitionComponent}
      // TransitionProps={{ timeout: 0 }}
      sx={{
        ".MuiPaper-root": {
          borderRadius: 3,
          bgcolor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <DialogTitle sx={{ p: 0 }}>
        <Header {...props} />
      </DialogTitle>
      <DialogContent sx={{ p: 0, borderRadius: 3 }}>
        {/* <Header {...props} /> */}
        <Box
          height={{ xs: 203, md: 337 }}
          width={{ xs: 345, md: 600 }}
          sx={{
            aspectRatio: "16/9",
          }}
        >
          <Carousel
            data={props.images}
            py={2}
            autoplay={false}
            height="inherit"
            width="inherit"
            // height={337}
            // width={600}
            mx="auto"
            swiperProps={{
              objectFit: "cover",
            }}
            sx={{
              p: 0,
              aspectRatio: "16/9",
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}

function Header(props) {
  return (
    <Grid
      container
      justifyContent="end"
      sx={{
        color: "white",
        // position: "absolute",
        // right: 8,
        // top: 8,
        // zIndex: 2,
        // color: (theme) => theme.palette.grey[500],
      }}
    >
      <Grid />
      <IconButton
        aria-label="close"
        onClick={props.onClose}
        sx={{ color: "inherit" }}
      >
        <Close sx={{ color: "inherit" }} />
      </IconButton>
    </Grid>
  );
}
