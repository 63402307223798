import { useQuery, useMutation } from "react-query";
import * as userService from "services/user.service";
import userStore from "common/stores/user.store";
import _ from "lodash";

const getToken = () => localStorage.getItem("token");

export const useUserQuery = (token) => {
	const { setUser, setIsAuthenticated } = userStore();

	return useQuery({
		queryKey: ["userInfo", token ?? getToken()],
		queryFn: () => userService.getUserInfo(),
		onSuccess: (user) => {
			setUser(user);
		},
		onError: (error) => {
			setIsAuthenticated(false);
		},
	});
};

export const useMutateUser = (token) => {
	const { setUser, setIsAuthenticated } = userStore();

	return useMutation({
		mutationKey: ["userInfo", token ?? getToken()],
		mutationFn: () => userService.getUserInfo(),
		onSuccess: (user) => {
			setUser(user);
		},
		onError: (error) => {
			setIsAuthenticated(false);
		},
	});
};

export const useForgotPasswordQuery = () => {
	const mutationFunction = (phone) => userService.forgotPassword(phone);

	return useMutation(mutationFunction, {
		mutationKey: "forgotPassword",
		onSuccess: (data) => {
			if (!_.isEmpty(data)) {
				localStorage.setItem("encrypted_code", data.encrypted_code);
				localStorage.setItem("ref_code", data.ref_code);
				localStorage.setItem("resetpassword", data.phone);
				localStorage.setItem("has_password", data.has_password);
			}
		},
	});
};
