import React, { useState, useEffect } from "react";
import { Button, Grid } from "@mui/material";
import spaceStore from "common/stores/space.store";
import { Footer } from "common/components/nav";

export default function FilterFooter(props) {
  const { clearFilter, setFilter } = spaceStore();

  return (
    <Footer>
      <Grid container direction="column" rowSpacing={2} textAlign="center">
        <Grid item px={2}>
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              setFilter();
              props.onClose();
            }}
            sx={{ width: { xs: "100%", md: 327 } }}
          >
            ยืนยัน
          </Button>
        </Grid>

        <Grid item px={2}>
          <Button
            variant="text"
            color="blue"
            size="large"
            onClick={() => {
              clearFilter();
            }}
            sx={{ width: { xs: "100%", md: 327 } }}
          >
            ล้างตัวกรอง
          </Button>
        </Grid>
      </Grid>
    </Footer>
  );
}
