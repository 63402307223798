import { Box, Paper } from "@mui/material";
import themeStore from "common/stores/theme.store";
import useVariant from "common/styles/variants";

export default function Header(props) {
  const maxWidth = themeStore((state) => state.maxWidth);
  const variants = useVariant();
  const backgroundStyle = props.style ?? variants[props.variant ?? "default"];

  return (
    <Box
      maxWidth={maxWidth}
      mx="auto"
      sx={{
        ...backgroundStyle,
        textAlign: "center",
        fontSize: "18px",
        fontWeight: "bold",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        padding: "20px 20px",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        zIndex: 800,
      }}
    >
      {props.children}
    </Box>
  );
}
