import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Grid, Typography } from "@mui/material";
import { MuiButton } from "common/components/button";
import formStore from "common/stores/form.store";
import { TextValidator } from "react-material-ui-form-validator";

export const passwordRulesSchema = (form) => ({
  minLength: form.password?.length >= 8,
  containNumber: /[0-9]/.test(form?.password),
  containSpecial: /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/.test(form?.password),
  containUpper: /[A-Z]/.test(form?.password),
  containLower: form.password && /[a-z]/.test(form?.password),
  matchPass: form.password && form?.password === form?.repassword,
});

export function PasswordForm(props) {
  const [form, setForm] = formStore((e) => [e.form, e.setForm]);
  const passwordRules = passwordRulesSchema(form);

  const inputStyle = {
    borderRadius: 2,
  };
  return (
    <>
      <Typography fontWeight="bold" fontSize={20} sx={{ mt: 5 }}>
        กำหนดรหัสผ่าน
      </Typography>
      <TextValidator
        {...props.requiredError}
        label="สร้างรหัสผ่าน"
        type="password"
        placeholder="ระบุรหัสผ่าน"
        color="red"
        value={form.password}
        name="password"
        onChange={(e) => setForm({ password: e.target.value })}
        fullWidth
        sx={{ mt: 3 }}
        InputProps={{ sx: inputStyle }}
        required
      />
      <Grid mt={3} ml={1}>
        <PasswordBullet condition={passwordRules.minLength}>
          รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร
        </PasswordBullet>
        <PasswordBullet condition={passwordRules.containNumber}>
          มีตัวเลขอยู่ในรหัสผ่าน (0-9)
        </PasswordBullet>
        <PasswordBullet condition={passwordRules.containSpecial}>
          มีอักขระพิเศษอย่างน้อย 1 ตัว
        </PasswordBullet>
        <PasswordBullet condition={passwordRules.containUpper}>
          มีตัวพิมพ์ใหญ่
        </PasswordBullet>
        <PasswordBullet condition={passwordRules.containLower}>
          มีตัวพิมพ์เล็ก
        </PasswordBullet>
        <PasswordBullet condition={passwordRules.matchPass}>
          รหัสผ่านทั้ง 2 ต้องตรงกัน
        </PasswordBullet>
      </Grid>

      <TextValidator
        {...props.requiredError}
        label="ยืนยันรหัสผ่าน"
        type="password"
        placeholder="ระบุรหัสผ่าน"
        color="red"
        value={form.repassword}
        name="repassword"
        onChange={(e) => setForm({ repassword: e.target.value })}
        fullWidth
        sx={{ mt: 3 }}
        InputProps={{ sx: inputStyle }}
        required
      />
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={4}>
          <MuiButton
            fullWidth
            variant="contained"
            loading={props.loading}
            type="submit"
            sx={{ mt: 6, mb: 3 }}
            disabled={props.formInvalid || props.passwordInvalid}
          >
            {props?.buttonText ?? "ลงทะเบียน"}
          </MuiButton>
        </Grid>
      </Grid>
    </>
  );
}

export function PasswordBullet(props) {
  return (
    <Grid mt={1} display="flex" alignItems="center">
      {props.condition ? (
        <CheckCircleIcon sx={{ color: "#29CDAD", mr: 1 }} />
      ) : (
        <CheckCircleIcon sx={{ color: "#D5D5D5", mr: 1 }} />
      )}
      {props.children}
    </Grid>
  );
}
