import { useQuery, useInfiniteQuery } from "react-query";
import * as mallService from "services/mall.service";
import mallStore from "common/stores/mall.store";
import * as consentService from "services/consent.service";
import _ from "lodash";

export const useMall = (filter) => {
  return useQuery({
    queryKey: ["getMallFiltered", filter],
    queryFn: () => mallService.getMall(filter),
  });
};

export const useMallInfinite = (filter) => {
  const [size] = mallStore((e) => [e.size]);

  return useInfiniteQuery({
    queryKey: ["getMallInfiniteFiltered", JSON.stringify(filter)],
    queryFn: async ({ pageParam = 1 }) =>
      await mallService.getMall({ size, page: pageParam, ...filter }),
    getNextPageParam: (lastPage) => {
      console.log("lastPage", lastPage);
      return lastPage?.next_page || undefined;
    },
  });
};

export const useMallAll = (filter) => {
  return useQuery({
    queryKey: ["getMallFiltered", filter],
    queryFn: () => mallService.getMallAll(filter),
  });
};

export const useMallInfo = (mallId) => {
  const setMall = mallStore((e) => e.setMall);

  return useQuery({
    queryKey: ["mallDetail", mallId],
    queryFn: () => mallService.getMallInfo({ mall_id: mallId }),
    enabled: !!mallId,
    onSuccess: (mall) => {
      if (!_.isEmpty(mall)) setMall(mall);
    },
  });
};

export const useConsentByCompany = (props) => {
  return useQuery({
    queryKey: ["getConsentFormByCompany", props.company_id],
    queryFn: () =>
      consentService.getConsentFormByCompany({
        company_id: props.company_id,
      }),
    ...props,
  });
};

export const useMallsDistance = ({ lat, lng }) => {
  const { setMallsDistance, setCenter } = mallStore();

  return useQuery({
    queryKey: ["getMallsDistance", { lat, lng }],
    queryFn: () => mallService.getMallsDistance({ lat, lng }),
    onSuccess: (mall) => {
      if (!_.isEmpty(mall)) {
        setMallsDistance(mall);
        setCenter({ lat, lng });
      }
    },
  });
};

export const useMallsGroup = (group_id) => {
  const setMallsGroup = mallStore((e) => e.setMallsGroup);
  return useQuery({
    queryKey: ["getMallsGroup", group_id],
    queryFn: () => mallService.getMallsGroup(group_id),
    enabled: !!group_id,
    onSuccess: (mall) => {
      if (!_.isEmpty(mall)) setMallsGroup(mall);
    },
  });
};
