import { LicenseInfo } from "@mui/x-license-pro";
import "antd/dist/antd.css";
import Layout from "common/components/layout";
import ModalProvider from "mui-modal-provider";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import Router from "routes/Router";
import "semantic-ui-css/semantic.min.css";
import "./App.css";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO);

const checkUnauthorized = (error) => {
	const statusCode = error.response ? error.response.status : null;
	if (statusCode === 401) {
		localStorage.removeItem("token");
	}
};

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: false,
		},
		// queryCache: new QueryCache({
		//   onError: (error) => checkUnauthorized(error),
		// }),
	},
});

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<BrowserRouter>
				<Layout>
					<ModalProvider>
						<Router />
					</ModalProvider>
				</Layout>
			</BrowserRouter>
		</QueryClientProvider>
	);
}

export default App;
