import create from "zustand";

const useStore = create((set) => ({
  payment: {},
  setPayment: (payment) => set((state) => ({ payment: payment })),
  qrcodeUrl: "",
  setQrcodeUrl: (qrcodeUrl) => set(() => ({ qrcodeUrl: qrcodeUrl })),
  remainDuration: 0,
  setRemainDuration: (remainDuration) => set(() => ({ remainDuration: remainDuration })),
  decreaseRemainDuration: () => set((e) => ({ remainDuration: e.remainDuration - 1000 })),
  pendingPayment: false,
  setPendingPayment: (pendingPayment) => set(() => ({ pendingPayment: pendingPayment })),
}));

export default useStore;
