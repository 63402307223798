import { Grid, Box, Button, Typography } from "@mui/material";
import { LazyLoadImage as Image } from "react-lazy-load-image-component";
import SuccessBubble from "assets/img/success-bubble-01.png";
import location from "common/hooks/router/location.hook";
import { Link, Navigate } from "react-router-dom";
import themeStore from "common/stores/theme.store";

export default function Success(props) {
  const { defaultRoute } = location();
  const theme = themeStore((e) => e.theme);

  return (
    <>
      <Image
        alt="success-bubble-01"
        height={81}
        src={SuccessBubble}
        style={{ marginTop: "8%" }}
      />
      <Typography
        variant="h6"
        mt={4}
        mb={5}
        sx={{ fontSize: "15px", color: "#5AC98D" }}
      >
        ท่านได้ทำการเชื่อมต่อบัญชี LINE กับ {theme.title} <br />
        เรียบร้อยแล้ว
      </Typography>
      <Grid mx={1}>
        <Link to={defaultRoute}>
          <Button
            size="large"
            variant="info"
            sx={{
              fontSize: "15px",
              color: "#18A0FB",
              py: "12px",
            }}
          >
            กลับสู่หน้าบริการ
          </Button>
        </Link>
      </Grid>
    </>
  );
}
