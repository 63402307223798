import { axios, HeaderDefault, GetEnvironment } from "services/base.service";

export const getBookingAll = () => {
  return axios({
    url: `/v1/booking/user`,
    method: "get",
  }).then((res) => res?.data?.data);
};

export const getBookingDetail = ({ booking_id }) => {
  return axios({
    url: `/v1/booking/detail`,
    method: "get",
    params: { booking_id },
  }).then((res) => res?.data?.data);
};

export const createBooking = (payload) => {
  return axios({
    url: `/v1/booking/range/new`,
    method: "post",
    data: payload,
  }).then((res) => res?.data?.data);
};

export const createPayment = (payload) => {
  return axios({
    url: `/v1/payment/new`,
    method: "post",
    data: payload,
  }).then((res) => res?.data?.data);
};

export const createFreePayment = (payload) => {
  return axios({
    url: `/v1/payment/free`,
    method: "post",
    data: payload,
  }).then((res) => res?.data?.data);
};

export const cancelBooking = (payload) => {
  return axios({
    url: `/v1/booking/update/status`,
    method: "post",
    data: { status: "ยกเลิก", ...payload },
  }).then((res) => res?.data?.data);
};
