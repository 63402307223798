import React, { useState, useEffect } from "react";
import { Box, Paper, Grid, Container, Typography } from "@mui/material";
import Header from "modules/recruit/header";
import Carousel from "common/components/swiper";
import { MallComponent } from "modules/space/mall.component";
import { useMall } from "common/hooks/use-query/mall.hook";
import { Link } from "react-router-dom";
import BottomNav from "modules/space/bottom-nav";
import Loader from "common/components/spinner";

import SmeThai from "assets/img/campaign/sme-thai.png";

const bannerList = [{ img: SmeThai, to: "/space/campaign/1" }];

export default function Space() {
  return (
    <>
      <Header title="เช่าเพื่อนที่ขายของ" disabledBack={false} />
      <Body />
      <BottomNav selected="หน้าแรก" />
    </>
  );
}

function Body() {
  const { isLoading, data } = useMall({ size: 15, page: 1 });
  const { result_data: malls } = { ...data };

  return (
    <Grid
      component={Container}
      container
      display="flex"
      sx={{
        mt: { xs: 6 },
        pb: { xs: "150px", md: 0 },
      }}
    >
      <Grid item xs={12} display="flex" justifyContent="center">
        <Carousel data={bannerList} py={2} mx="auto" />
      </Grid>

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        pt={4}
        pb={1}
        px={{ xs: 0, md: 5 }}
      >
        <Typography fontSize={22} fontWeight="bold">
          พื้นที่ขาย
        </Typography>
        <Link to="/space/mall">ดูทั้งหมด</Link>
      </Grid>
      {isLoading ? <Loader /> : <MallComponent malls={malls} />}
    </Grid>
  );
}
