import create from "zustand";

export const storeSchema = (set, customHook = () => {}) => ({
  stepWizard: {},
  setStepWizard: (stepWizard) => set({ stepWizard: stepWizard }),
  initStep: 1,
  setInitStep: (e) => set({ initStep: e }),
  resetInitStep: (e) => set({ initStep: 1 }),
  title: null,
  setTitle: (e) => set({ title: e }),
  ...customHook(set),
});

export const createStore = (customHook) => create((set) => storeSchema(set, customHook));
export const stepWizardStore = create((set) => storeSchema(set));
export default stepWizardStore;
