import { LocalOfferOutlined } from "@mui/icons-material";
import {
  Box, Chip, Divider, Grid, Paper,
  Typography
} from "@mui/material";
import OriginSme from "assets/logo/origin_sme.svg";
import Link from "common/components/link";
import { useSpaceGrouping } from "common/hooks/use-query/space.hook";
import spaceStore from "common/stores/space.store";
import { formatCurrency } from "common/utils/intl";
import { LazyLoadImage as Image } from "react-lazy-load-image-component";
import { useParams } from "react-router-dom";

export default function SpaceList(props) {
  const { groupId, mallId } = useParams();
  const spaceGrouping = spaceStore((e) => e.spaceGrouping);
  const { isLoadingSpaceGroupingInfo } = useSpaceGrouping({ group_id:  groupId, mall_id: mallId});

  // const searchFilter = useMemo(
  //   () =>
  //     _.pickBy(
  //       {
  //         ...{
  //           ...filter,
  //           booth_price:
  //             !!filter.booth_price && _.pickBy(filter.booth_price, Boolean),
  //           booth_width:
  //             !!filter.booth_width && _.pickBy(filter.booth_width, Boolean),
  //           booth_length:
  //             !!filter.booth_length && _.pickBy(filter.booth_length, Boolean),
  //         },
  //         mall_id: mallId,
  //       },
  //       _.identity
  //     ),
  //   [filter]
  // );

  // const { data: spaces, isLoading } = useSpace(searchFilter);

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        pt={2}
        mb={3}
      >
        <Typography fontSize={{ xs: 16, md: 24 }} fontWeight="bold">
          พื้นที่
        </Typography>
        {/* <FilterButton /> */}
      </Grid>
      <Grid container spacing={2}>
        {spaceGrouping?.[0] &&
          spaceGrouping?.map((space) => (
            <Grid item xs={12} md={4}>
              <SpaceComponent {...space} />
            </Grid>
        ))}
      </Grid>
    </>
  );
}

function SpaceComponent(props) {
  const image = props.images?.[0]?.image_url;
  return (
    <Paper
      variant="outlined"
      sx={{
        borderRadius: 3,
      }}
    >
      <Grid
        component={Link}
        to={`/space/mall/booth/${props.id}`}
        container
        borderRadius={3}
      >
        <Box
          component={Image}
          src={image ?? OriginSme}
          width="100%"
          sx={{
            ...(!image && { p: 5 }),
            objectFit: image ? "cover" : "contain",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
          height={{
            xs: 195,
            md: 193,
          }}
        />
        <Grid
          item
          container
          p={2}
          justifyContent="space-between"
          sx={{
            borderTop: "1px solid #D5D5D5",
            borderBottom: "1px solid #D5D5D5",
          }}
        >
          <Typography fontWeight="bold">{props.name}</Typography>
          <Chip
            label={props.contract_type}
            color="error"
            size="small"
            variant="outlined"
          />
        </Grid>
        <Divider />
        <Grid
          item
          container
          justifyContent="flex-end"
          px={2}
          py={1}
          color="grey.600"
        >
          <Typography>
            <LocalOfferOutlined
              color="primary"
              sx={{ fontSize: 16, mb: -0.4, mr: 2 }}
            />
            {formatCurrency(props.booth_price)} / วัน
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
