import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Container,
  Chip,
  Grid,
  Divider,
  IconButton,
  Paper,
  Typography,
  Tab,
  Tabs,
} from "@mui/material";
import { FilterAlt, LocalOfferOutlined } from "@mui/icons-material";
import { LazyLoadImage as Image } from "react-lazy-load-image-component";
import { useParams } from "react-router-dom";
import { useSpace } from "common/hooks/use-query/space.hook";
import Link from "common/components/link";
import EmptySpace from "assets/img/empty_space.svg";
import { formatCurrency } from "common/utils/intl";
import { FilterButton } from "modules/space/mall/id/filter";
import spaceStore from "common/stores/space.store";
import _ from "lodash";
import { LoaderBody } from "common/components/spinner";

export default function SpaceList(props) {
  const { mallId } = useParams();
  const filter = spaceStore((e) => e.filter);

  const searchFilter = useMemo(
    () =>
      _.pickBy(
        {
          ...{
            ...filter,
            booth_price:
              !!filter.booth_price && _.pickBy(filter.booth_price, Boolean),
            booth_width:
              !!filter.booth_width && _.pickBy(filter.booth_width, Boolean),
            booth_length:
              !!filter.booth_length && _.pickBy(filter.booth_length, Boolean),
          },
          mall_id: mallId,
        },
        _.identity
      ),
    [filter]
  );

  const { data: spaces, isLoading } = useSpace(searchFilter);

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        pt={2}
        mb={3}
      >
        <Typography fontSize={{ xs: 16, md: 24 }} fontWeight="bold">
          พื้นที่
        </Typography>
        <FilterButton />
      </Grid>
      <Grid container spacing={2}>
        {isLoading ? (
          <LoaderBody sx={{ mt: { xs: "50px", md: "180px" } }} />
        ) : (
          spaces?.[0] &&
          spaces?.map((space) => (
            <Grid item xs={12} md={4}>
              <SpaceComponent {...space} />
            </Grid>
          ))
        )}
      </Grid>
    </>
  );
}

function SpaceComponent(props) {
  const image = props.images?.[0]?.image_url;
  return (
    <Paper
      variant="outlined"
      sx={{
        borderRadius: 3,
      }}
    >
      <Grid
        component={Link}
        to={`/space/mall/booth/${props.id}`}
        container
        borderRadius={3}
      >
        <Box
          component={Image}
          src={image ?? EmptySpace}
          width="100%"
          sx={{
            objectFit: "cover",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
          height={{
            xs: 195,
            md: 193,
          }}
        />
        <Grid
          item
          container
          p={2}
          justifyContent="space-between"
          sx={{
            borderTop: "1px solid #D5D5D5",
            borderBottom: "1px solid #D5D5D5",
          }}
        >
          <Typography fontWeight="bold">{props.name}</Typography>
          <Chip
            label={props.contract_type}
            color="error"
            size="small"
            variant="outlined"
          />
        </Grid>
        <Divider />
        <Grid
          item
          container
          justifyContent="flex-end"
          px={2}
          py={1}
          color="grey.600"
        >
          <Typography>
            <LocalOfferOutlined
              color="primary"
              sx={{ fontSize: 16, mb: -0.4, mr: 2 }}
            />
            {formatCurrency(props.booth_price)} / วัน
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
