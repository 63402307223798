import loadable from "@loadable/component";

const LogOut = loadable(() => import("pages/auth/logout"));
const Login = loadable(() => import("pages/auth/login"));
const basePath = "auth";

const authRoute = [
  {
    exact: true,
    path: `/${basePath}`,
    desktop: true,
    children: [
      { exact: true, path: `logout`, element: <LogOut /> },
      { exact: true, path: `login`, element: <Login /> },
    ],
  },
];

export default authRoute;
