import React, { useMemo, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Popover,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { ChevronLeft, Clear, KeyboardArrowDown } from "@mui/icons-material";
import { useModal } from "mui-modal-provider";
import { Link } from "react-router-dom";
import { LazyLoadImage as Image } from "react-lazy-load-image-component";
import userStore from "common/stores/user.store";
import { useLocation, useNavigate } from "react-router-dom";
import stepWizardStore from "common/stores/step-wizard.store";
import themeStore from "common/stores/theme.store";
import {
  UserAvatar,
  ProfileList,
  OtherList,
} from "modules/recruit/header/menu/profile";
import Navigate, {
  useMenuList,
} from "modules/recruit/header/menu/navigate-list";
import formStore from "common/stores/form.store";

export default function DesktopHeader(props) {
  const { showModal } = useModal();
  const { logo } = themeStore((e) => e.theme);

  const handlePopover = (PopoverComponent, otherProps) => (e) => {
    showModal(PopoverComponent, {
      anchorEl: e.currentTarget,
      anchorOrigin: {
        vertical: "bottom",
      },
      ...(otherProps && otherProps),
    });
  };

  return (
    <Paper
      position="fixed"
      variant="outlined"
      elevation={0}
      sx={{
        width: { xs: "100%" },
        px: { xs: 0, sm: 10 },
        fontWeight: "bold",
        color: "#17524E",
        bgcolor: "#FFFFFF",
        position: "fixed",
        zIndex: 999,
        top: 0,
        ...props?.sx,
      }}
    >
      <Container>
        <Toolbar
          disableGutters
          sx={{
            minHeight: 78,
            left: 0,
          }}
        >
          <Link to="/recruit">
            <Image src={logo} height="45px" width="179px" />
          </Link>
          <MenuList
            onClick={handlePopover(PopoverNavigate, {
              transformOrigin: {
                horizontal: "center",
              },
            })}
          />
          <Box sx={{ flexGrow: 1 }} />
          {/* <Tooltip title="Notifications">
            <IconButton
              component={Link}
              sx={{ ml: 1 }}
              to="/business-matching/status"
            >
              <NotificationsNoneOutlined />
            </IconButton>
          </Tooltip>
          <Divider
            variant="middle"
            orientation="vertical"
            flexItem
            sx={{ pl: 2, my: 2 }}
          /> */}
          <ProfileAvatar />
          <IconButton
            onClick={handlePopover(PopverProfile, {
              transformOrigin: {
                horizontal: "right",
              },
            })}
          >
            <KeyboardArrowDown />
          </IconButton>
        </Toolbar>
        {props.children}
      </Container>
    </Paper>
  );
}

function ProfileAvatar() {
  const navigate = useNavigate();
  const { first_name = "", email = "" } = userStore((e) => e.user);

  return first_name ? (
    <Avatar
      sx={{
        height: 40,
        width: 40,
        ml: 3,
        bgcolor: "primary.main",
        textTransform: "uppercase",
      }}
    >
      {email?.[0] ?? first_name?.[0]}
    </Avatar>
  ) : (
    <Box justifyContent="flex-end">
      <Button
        size="large"
        onClick={() => navigate("/auth/login")}
        sx={{ color: "grey.800" }}
      >
        เข้าสู่ระบบ
      </Button>
      <Button
        size="large"
        variant="contained"
        color="primary"
        onClick={() => navigate("/register")}
        sx={{ mx: 2, borderRadius: 2.5 }}
      >
        สมัครสมาชิก
      </Button>
    </Box>
  );
}

export function TempDesktopHeader(props) {
  const [stepWizard, title] = stepWizardStore((e) => [e.stepWizard, e.title]);
  const navigate = useNavigate();
  const location = useLocation();
  const currentStep = stepWizard?.state?.activeStep;
  const { clearForm } = formStore();
  const theme = themeStore((e) => e.theme);

  const showBackButton = !props.leftButton && !props.disabledBack;

  function handleBack() {
    try {
      if (props.stepper) {
        const currentStep = stepWizard?.state?.activeStep;
        currentStep > 0 ? handleStep() : handleNavigate();
        return;
      }
      handleNavigate();
    } catch (err) {
      handleNavigate();
    }
  }

  function handleStep() {
    stepWizard.previousStep();
  }

  function handleNavigate() {
    try {
      navigate(props.link ?? props.to ?? -1);
    } catch (err) {
      const path = location.pathname.split("/")[1];
      navigate(`/${path}`);
    }
  }

  return (
    <Grid component={Container}>
      {props?.disabledHeader ? (
        <></>
      ) : (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <Grid item>
            <Box
              component={Image}
              src={theme.logo}
              height={40}
              preview={false}
            />
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                localStorage.clear("allow_disclosure_agreement");
                clearForm();
                navigate("/recruit");
              }}
            >
              <Clear fontSize="large" />
            </IconButton>
          </Grid>
        </Grid>
      )}
      <Grid container justifyContent="center" sx={{ position: "relative" }}>
        {showBackButton ? (
          <Grid position="absolute" left={2} top={5}>
            <Button
              onClick={props.onClick ?? props.handleBack ?? handleBack}
              startIcon={props.icon ?? <ChevronLeft />}
              sx={{
                color: "grey.800",
                mt: 3,
              }}
            >
              กลับ
            </Button>
          </Grid>
        ) : (
          props.leftButton
        )}
        {props.children && (
          <Typography fontSize={props?.fontSize ?? 35} fontWeight="bold" mt={2}>
            {props.children}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

function MenuList(props) {
  const location = useLocation();
  const menu = useMenuList();

  const currentMenu = useMemo(
    () =>
      menu.find(
        (mapping) =>
          (location?.pathname).includes(mapping?.link) && mapping?.text
      ),
    [location.pathname]
  );

  if (menu.length < 2) return <></>;

  return (
    <>
      <Divider
        variant="middle"
        orientation="vertical"
        flexItem
        sx={{ pl: 2, my: 2 }}
      />
      <Typography
        component={Link}
        to={currentMenu?.to ?? "/*"}
        color="black"
        sx={{ pl: 2 }}
      >
        {currentMenu?.label ?? "หน้าแรก"}
      </Typography>
      <IconButton onClick={props.onClick}>
        <KeyboardArrowDown />
      </IconButton>
    </>
  );
}

const popOverStyle = {
  ".MuiPaper-root": {
    borderRadius: 3,
  },
};

function PopoverNavigate(props) {
  return (
    <Popover {...props} sx={popOverStyle}>
      <Box width="289px">
        <Navigate />
      </Box>
    </Popover>
  );
}

function PopverProfile(props) {
  const user = userStore((e) => e.user);
  const theme = themeStore((e) => e.theme);

  return (
    <Popover {...props} sx={popOverStyle}>
      <Box
        width="289px"
        sx={(theme) => ({
          "&svg": {
            fill: theme.palette.primary.main,
          },
        })}
      >
        {!!user?.first_name && (
          <>
            <UserAvatar />
            {theme.modules.profile && <ProfileList onClose={props.onClose} />}
          </>
        )}
        <OtherList onClose={props.onClose} />
      </Box>
    </Popover>
  );
}
