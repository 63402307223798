import React, { useState, useEffect, createRef, memo } from "react";
import { Container, Grid, Paper, Skeleton, Typography } from "@mui/material";
import { AppBar } from "common/components/nav";
import paymentStore from "common/stores/payment.store";
import { Link, useNavigate } from "react-router-dom";
import { LazyLoadImage as Image } from "react-lazy-load-image-component";
import _ from "lodash";
import { Item } from "common/components/form";
import moment from "moment";
import smeLogo from "assets/img/sme_logo.png";
import { FooterButton } from "common/components/button";
import PaymentHook from "modules/space/booking/payment/payment.hook";
import useInterval from "use-interval";
import errorStore from "common/stores/error.store";
import ERROR_PAYMENT from "common/constants/error/payment";
import bookingStore from "common/stores/booking.store";
import themeStore from "common/stores/theme.store";

export default function PaymentMethod(props) {
  const booking = bookingStore((e) => e.booking);
  const { setRemainDuration } = paymentStore();
  const { setOpen, setError } = errorStore();

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { handlePromptpayRemaining, checkPaymenStatus, refetch } =
    PaymentHook(props);

  function paymentExpired() {
    setOpen(true);
    setRemainDuration(0);
    setError({
      ...ERROR_PAYMENT.EXPIRED,
      onClick: () => {
        refetch();
        setOpen(false);
        props.goToNamedStep("preview");
      },
    });
  }

  async function handleClickCheckPaymentStatus() {
    try {
      setOpen(false);
      setLoading(true);
      await checkPaymenStatus();
      setTimeout(() => {
        setOpen(true);
        setError({
          ...ERROR_PAYMENT.PROMPTPAY.TRANSACTION_NOT_EXIST,
          onClick: () => {
            try {
              handleClickCheckPaymentStatus();
            } catch (err) {
              console.log(err);
            }
          },
        });
        setLoading(false);
      }, 3000);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    handlePromptpayRemaining();
  }, [booking]);

  useInterval(async () => {
    try {
      await checkPaymenStatus();
    } catch (err) {
      console.log(err);
    }
  }, 10000);

  if (
    _.isEmpty(booking) ||
    _.isEmpty(booking.transaction_payment_data) ||
    booking.transaction_payment_data === "expired"
  )
    props.goToNamedStep("preview");

  return (
    <Container sx={{ pt: "35px" }}>
      <AppBar onClick={() => props.goToNamedStep("preview")}>
        แสกนจ่ายเงิน
      </AppBar>
      <Grid
        container
        sx={props.disabled ? { color: "grey.500" } : {}}
        spacing={2}
      >
        <Grid item xs={12} md={6}>
          <Grid
            component={Paper}
            sx={{ borderRadius: 3 }}
            justifyContent="center"
            p={2}
          >
            <Grid item container sx={{ display: { xs: "flex", md: "none" } }}>
              <Detail />
            </Grid>
            <Item bold center fontSize={16} mb={2}>
              โปรดแสกน QR จ่ายเงินให้เสร็จภายใน 10 นาที
            </Item>
            <TimeRemaining onExpire={paymentExpired} />
            <Item center fontSize={16} mb={2}>
              กรุณาอย่าปิดหน้าจอ จนกว่าจะชำระเงินสำเร็จ
            </Item>
            <Grid item sx={{ m: "0 auto", mb: 2, textAlign: "center" }}>
              <Image
                name="promptpay-qrcode"
                src={booking.transaction_payment_data}
                id="promptpay-qrcode"
                alt="promptpay-qrcode"
                height="270px"
                placeholder={
                  <Skeleton variant="rectangular" width={190} height={120} />
                }
                style={{ border: "1px solid black", minWidth: "190px" }}
              />
            </Grid>
            <Grid item width="100%">
              <FooterButton
                {...props}
                marginTop="0"
                loading={loading}
                onClick={handleClickCheckPaymentStatus}
              >
                แจ้งการชำระเงิน
              </FooterButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={6} sx={{ display: { xs: "none", md: "block" } }}>
          <Grid component={Paper} container sx={{ borderRadius: 3 }} p={2}>
            <Detail />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

function Detail() {
  const booking = bookingStore((e) => e.booking);
  const theme = themeStore((e) => e.theme);

  return (
    <>
      <Grid item xs={6}>
        <Image src={smeLogo} width="41px" style={{ height: "fit-content" }} />
      </Grid>
      <Grid xs={6} right sx={{ textAlign: "right", mb: 1 }}>
        <Typography fontSize={19} fontWeight="bold">
          {theme.title}
        </Typography>
        <Typography fontSize={12}>by {theme.title} Co., Ltd</Typography>
      </Grid>
      <Item xs={6}>Marchant:</Item>
      <Item xs={6} right>
        {booking?.company_name}
      </Item>
      <Item xs={6}>Amount:</Item>
      <Item xs={6} right>
        THB {booking?.booking_total_price}
      </Item>
      <Item xs={6}>Date:</Item>
      <Item xs={6} right mb={3}>
        {moment(booking?.booking_created_at).format("DD-MM-YYYY")}
      </Item>
    </>
  );
}

const TimeRemaining = memo((props) => {
  const [remainDuration, decreaseRemainDuration] = paymentStore((e) => [
    e.remainDuration,
    e.decreaseRemainDuration,
  ]);

  useInterval(() => {
    remainDuration > 1000 ? decreaseRemainDuration() : props.onExpire();
  }, 1000);

  return (
    <Item bold center color="#F01C31" fontSize={20} mb={2}>
      เหลือเวลา {moment(remainDuration).format("mm:ss")}
    </Item>
  );
});
