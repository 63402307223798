import React, { useState, useEffect } from "react";
import { Box, Drawer } from "@mui/material";
import PaymentDetail from "./payment-detail";
import bookingStore from "common/stores/booking.store";
import PaidSummary from "modules/space/booking/id/preview/paid-summary";

export default function MobileDrawer(props) {
  const [booking, isDisabled, isPaid] = bookingStore((e) => [
    e.booking,
    e.isDisabled,
    e.isPaid,
  ]);

  return (
    <Drawer
      anchor="bottom"
      open
      variant="permanent"
      sx={{ display: { xs: "block", md: "none" } }}
    >
      <Box p={2}>
        {isPaid && <PaidSummary />}
        <PaymentDetail {...props} />
      </Box>
    </Drawer>
  );
}
