import React, { useState, useEffect } from "react";
import { Icon, Loader, Container, Image, Button } from "semantic-ui-react";
import axiosAPI from "services/axiosApi";
import { Radio, Space, Checkbox } from "antd";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { useSpaceInfo } from "common/hooks/use-query/space.hook";
import { useParams } from "react-router-dom";
import Page from "common/components/page";
import * as mallService from "services/mall.service";
import * as consentService from "services/consent.service";
import TermsAndConditions from "common/pages/terms-and-conditions";

export default function VeryShotTC() {
  const navigate = useNavigate();
  const [termAndCondition, setTermAndCondition] = useState(null);

  const { spaceId } = useParams();
  const { data: selectedZone, isLoading } = useSpaceInfo(spaceId);

  const nextStep = () => {
    selectedZone.required_approval
      ? navigate(`/space/preview-payment/${spaceId}`)
      : navigate(`/space/preview-payment-required/${spaceId}`);
  };

  async function getConsentForm() {
    if (selectedZone?.mall_id) {
      try {
        const mall = await mallService.getMallInfo({
          mall_id: selectedZone?.mall_id,
        });
        const termAndCondition = await consentService.getConsentFormByCompany({
          company_id: mall?.company_id,
        });
        setTermAndCondition(termAndCondition);
      } catch (err) {
        console.log(err.response);
      }
    }
  }

  useEffect(() => {
    if (!isLoading) getConsentForm();
  }, [selectedZone]);

  return (
    <TermsAndConditions
      disabledHeader
      text={termAndCondition}
      handleBack={() => navigate(-1)}
      disabledLater={true}
      onClick={nextStep}
      title="ข้อกำหนดและเงื่อนไข"
      color="red"
      appBar={{ fontSize: 30 }}
      bodyProps={{ title: "" }}
    />
  );
}
