import React, { useState, useEffect } from "react";
import { Icon } from "semantic-ui-react";
import { Checkbox } from "antd";
import {
  Grid,
  Button,
  Typography,
  Dialog,
  SwipeableDrawer,
  Box,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { MultiList } from "./booking-list";
import { Footer } from "common/components/nav";

export default function Responsive(props) {
  return (
    <SwipeableDrawer {...props} anchor="right">
      <Box width="100vw" pt={4}>
        <Body {...props} />
      </Box>
    </SwipeableDrawer>
  );
}

export function Body(props) {
  return (
    <>
      <Grid container justifyContent="end" pr={1} pt={1}>
        <IconButton size="large" onClick={props.onClose}>
          <Close />
        </IconButton>
      </Grid>
      <Grid
        container
        direction="column"
        p={{ xs: 2, md: 4 }}
        pt={{ xs: 1, md: 1 }}
      >
        <Grid item pb={3}>
          <Typography textAlign="left" fontSize={20} fontWeight="bold">
            จัดการวันจอง
          </Typography>
        </Grid>
        <MultiList spacing={3} fontSize={16} />
      </Grid>
      <Footer>{props.submitButton}</Footer>
    </>
  );
}
