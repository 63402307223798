import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useShopQuery,
  usePrimaryShopQuery,
  useUserQuery,
} from "common/hooks/use-query";
import Success from "common/components/success";

import { AppBar } from "common/components/nav";

export default function SuccessShop(props) {
  const navigate = useNavigate();
  const { refetch: shopsRefetch } = useShopQuery();
  const { refetch: primaryShopRefetch } = usePrimaryShopQuery();
  const { refetch: userRefetch } = useUserQuery();

  const lineStatus = localStorage.getItem("line_status");
  const path = localStorage.getItem("from_service");

  useEffect(() => {
    setTimeout(() => {
      shopsRefetch();
      userRefetch();
      primaryShopRefetch();

      if (!!path) {
        localStorage.removeItem("from_service");
        navigate(path);
        return;
      }

      if (lineStatus) {
        localStorage.removeItem("line_status");
        navigate(lineStatus);
        return;
      }
    }, 5000);
  }, []);

  return (
    <>
      <Success title={props?.title ?? "ร้านค้า"} to={path} />
    </>
  );
}
