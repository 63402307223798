import * as React from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  SwipeableDrawer,
  List,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import {
  UserAvatar,
  ProfileList,
  OtherList,
} from "modules/recruit/header/menu/profile";
import Navigate from "./navigate-list";
import userStore from "common/stores/user.store";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

export default function SwipeableTemporaryDrawer(props) {
  const user = userStore((e) => e.user);

  return (
    <SwipeableDrawer anchor="right" {...props}>
      <Box role="presentation" width="100vw">
        <Header {...props} />
        {user?.first_name ? <UserAvatar /> : <UnauthButton />}
        <Navigate />
        {!_.isEmpty(user) && <ProfileList />}
        <OtherList onClose={props.onClose} />
      </Box>
    </SwipeableDrawer>
  );
}

function Header(props) {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      px={4}
      py={2}
      height={56}
    >
      <Grid>
        <Typography fontSize={20} fontWeight="bold">
          เมนู
        </Typography>
      </Grid>
      <IconButton
        sx={{ position: "absolute", right: "10px" }}
        onClick={props.onClose}
      >
        <Close />
      </IconButton>
    </Grid>
  );
}

function UnauthButton(props) {
  const navigate = useNavigate();

  return (
    <Grid container direction="column" spacing={2} px={4} pb={2} pt={3}>
      <Grid item>
        <Button
          variant="contained"
          size="large"
          fullWidth
          sx={{ borderRadius: 2.5 }}
          onClick={() => navigate("/register")}
        >
          ลงทะเบียน
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          size="large"
          fullWidth
          sx={{ borderRadius: 2.5 }}
          onClick={() => navigate("/auth/login")}
        >
          เข้าสู่ระบบ
        </Button>
      </Grid>
    </Grid>
  );
}
