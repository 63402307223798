import { axios, HeaderDefault, GetEnvironment } from "services/base.service";

export const getConsentFormByCompany = ({ company_id }) => {
  return axios({
    url: `/v1/consent_form`,
    method: "get",
    params: { company_id, type: "shortterm" },
  }).then((res) => res?.data?.data?.data);
};

export const getCompany = () => {
  return axios({
    url: `/v1/company/all`,
    method: "get",
  }).then((res) => res?.data?.data);
};
