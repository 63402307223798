import React, { useState, useEffect } from "react";
import { Container, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { AppBar, Footer } from "common/components/nav";
import Breadcrumbs from "common/components/breadcrumbs";
import { useSpaceInfo } from "common/hooks/use-query/space.hook";
import Header from "modules/space/booth/header";
import Detail from "modules/space/booth/detail";
import Booking from "modules/space/booth/booking";
import BookingDrawer from "modules/space/booth/booking.drawer";
import BottomNav from "modules/space/bottom-nav";
import bookingStore from "common/stores/booking.store";

export default function MallDetailComponent(props) {
  const { spaceId } = useParams();
  const { data: space } = useSpaceInfo(spaceId);
  const { periodSpaceId, setPeriodSpaceId, clearPeriod } = bookingStore();

  useEffect(() => {
    if (periodSpaceId !== spaceId) {
      setPeriodSpaceId(spaceId);
      clearPeriod();
    }
  }, [spaceId]);

  return (
    <>
      <AppBar to={`/space/mall/${space?.mall_id}`}>{space?.name}</AppBar>
      <Grid>
        <Container>
          <Breadcrumbs
            menu={[
              { text: "พื้นที่เช่า", to: "/space" },
              { text: space?.mall?.name, to: `/space/mall/${space?.mall_id}` },
              { text: space?.name },
            ]}
            sx={{ pb: 2 }}
          />
        </Container>
        <Header space={space} />
      </Grid>
      <Grid bgcolor="#FAFAFC" pt={4} pb={{ xs: "400px", sm: "350px", md: 4 }}>
        <Container>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} md={8}>
              <Detail />
            </Grid>
            <Grid item md={4} sx={{ display: { xs: "none", md: "block" } }}>
              <Booking />
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid sx={{ display: { xs: "block", md: "none" } }}>
        <BookingDrawer />
      </Grid>
      <BottomNav selected="หน้าแรก" />
    </>
  );
}
