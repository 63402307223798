import empty from "assets/icon/empty.svg";
import "common/bg.css";
import { Link } from "react-router-dom";
import { Typography, Button, Grid, Box } from "@mui/material";
import { LazyLoadImage as Image } from "react-lazy-load-image-component";

export default function EmptyReserved() {
  return (
    <Grid sx={{ marginTop: "150px" }}>
      <Box
        sx={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
        component={Image}
        src={empty}
        width="60px"
        height="60px"
      />
      <Grid>
      </Grid>
      <Typography sx={{ marginTop: "30px", textAlign: "center", fontSize: "16px" }}>
        คุณยังไม่มีการจองที่กำลังจะเกิดขึ้น
      </Typography>
      <Grid
        style={{ marginTop: "43px", marginLeft: "15px", marginRight: "15px" }}
      >
        <Link to="/space/mall">
          <Button
            sx={{
              fontSize: "18px",
              fontWeight: "700",
              backgroundColor: "#F01C31",
              color: "white",
              borderRadius: "10px",
              padding: "20px",
              width: "300px",
              height: "52px",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Typography sx={{lineHeight: 0}}>เช่าพื้นที่</Typography>
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
}
