import React, { useState, useEffect, memo, useMemo } from "react";
import { Paper, Grid, Typography } from "@mui/material";
import { MallWrapper, MallContent } from "modules/space/mall.component";
import { FilterButton } from "modules/space/mall/home/filter";
import { LoaderBody } from "common/components/spinner";
import useStore from "common/stores/mall.store";
import _ from "lodash";
import { useMallInfinite } from "common/hooks/use-query/mall.hook";
import { InView } from "react-intersection-observer";
import { FadeLoaderBody } from "common/components/spinner/fade-loader";

export default function Responsive(props) {
  const filter = useStore((e) => e.filter);

  const searchFilter = useMemo(() => _.pickBy(filter, Boolean), [filter]);

  const { data, refetch, isLoading, fetchNextPage, isFetchingNextPage } =
    useMallInfinite(searchFilter);

  function handleScroll(inView, entry) {
    inView && fetchNextPage();
  }

  useEffect(() => {
    console.log(data)
    refetch();
  }, [searchFilter]);

  return (
    <>
      <Grid
        sx={{
          borderRadius: 3,
          display: { xs: "block", md: "none" },
        }}
      >
        <Content
          isLoading={isLoading}
          pages={data?.pages}
          isFetchingNextPage={isFetchingNextPage}
        />
      </Grid>

      <Grid sx={{ display: { xs: "none", md: "block" } }}>
        <Paper
          variant="outlined"
          sx={{
            borderRadius: 3,
          }}
        >
          <Content
            isLoading={isLoading}
            pages={data?.pages}
            isFetchingNextPage={isFetchingNextPage}
          />
        </Paper>
      </Grid>
      <InView as="div" onChange={handleScroll} />
    </>
  );
}

const Content = memo((props) => (
  <>
    {props.isLoading ? (
      <LoaderBody sx={{ mt: { xs: "150px", md: "180px" } }} />
    ) : (
      <MallQueried pages={props.pages} />
    )}
    {props.isFetchingNextPage && (
      <FadeLoaderBody
        sx={{ ml: "2%", pt: { xs: "10px", md: "30px" }, pb: { md: "30px" } }}
      />
    )}
  </>
));

const MallQueried = memo(({ pages }) => {
  const { total_result_count } = { ...pages?.[0] };

  return (
    <>
      <Grid p={{ xs: 0, md: 2 }} pb={{ xs: 3, md: 1 }}>
        <Typography fontSize={14} color="grey.600">
          ผลการค้นหา
        </Typography>
        <Typography fontSize={24} fontWeight="bold">
          สถานที่ให้เช่าทั้งหมด ({total_result_count ?? 0})
        </Typography>
        <FilterButton />
      </Grid>

      <MallWrapper width={960}>
        {pages?.[0] &&
          pages.map(({ result_data }) => (
            <MallContent
              malls={result_data}
              column={{
                xs: 6,
                sm: 3,
                md: 1.5,
              }}
            />
          ))}
      </MallWrapper>
    </>
  );
});
