import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Drawer } from "@mui/material";
import { Title, Description } from "common/components/form";
import {
  ChevronRight,
  Help,
  ChatBubbleOutline,
  CancelOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CancelList from "./cancel";
import bookingStore from "common/stores/booking.store";
import Item from "modules/space/booking/id/detail/item.component";

export default function Support(props) {
  const booking = bookingStore((state) => state.booking);
  const navigate = useNavigate();

  return (
    <Grid container>
      <Item fontSize={18} children="การสนับสนุน" mb={2} />
      <Group onClick={() => navigate("/how-to/general")}>
        <Help sx={{ color: "grey.500" }} />
        <Description ml={1} mb={1} children="ช่วยเหลือ" />
      </Group>
      <Group onClick={() => navigate("contact")}>
        <ChatBubbleOutline sx={{ color: "grey.500" }} />
        <Description ml={1} mb={1} children="ติดต่อ Landlord" />
      </Group>
      {!["cancelled", "disapproved"].includes(booking.booking_status) && (
        <CancelList />
      )}
    </Grid>
  );
}

export function Group(props) {
  return (
    <Grid
      container
      onClick={props.onClick}
      alignItems="center"
      mb={2}
      sx={{
        cursor: "pointer",
      }}
    >
      <Grid containter sx={{ display: "flex", flexGrow: 1 }}>
        {props.children}
      </Grid>
      {!props.disabledRight && (
        <Grid item ml="auto">
          <ChevronRight sx={{ color: "grey.500" }} />
        </Grid>
      )}
    </Grid>
  );
}
