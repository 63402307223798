import { Box, Container, Grid } from "@mui/material";
import Header from "./header";
import Body from "./body";
import Footer from "./footer";
import { AppBar } from "common/components/nav";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";

export default function TermsAndCondition(props) {
  const navigate = useNavigate();
  const [height, setHeight] = useState(0);

  const appBarHeight =
    document.getElementById("MobileAppBar")?.clientHeight ?? 0;
  const footerHeight = document.getElementById("Footer")?.clientHeight ?? 0;

  useEffect(() => {
    setHeight(`(${appBarHeight}px + ${footerHeight}px)`);
  });

  return (
    <Container sx={{ pt: `${appBarHeight}px`, pb: { xs: "80px", sm: "0px" } }}>
      {(!props.disabledAppBar || !props.disabledTopNav) && (
        <AppBar
          tempAppBar={props?.tempAppBar ?? true}
          stepper
          disabledHeader={props.disabledHeader ?? false}
          {...props?.appBar}
        >
          {props.appBar?.title ?? props?.title ?? "ข้อกำหนดและเงื่อนไข"}
        </AppBar>
      )}
      <Body
        height={height}
        text={props?.text}
        title={props?.title}
        disabledFooter={props.disabledFooter}
        {...props.bodyProps}
      />
      {!props.disabledFooter && (
        <Footer
          semiWidth
          bgcolor="#fff"
          label={props?.disabledLater ? null : "ไว้คราวหลัง"}
          backOnClick={() => navigate("/recruit")}
          backButton={props?.disabledLater ? false : true}
          {...props}
          {...props.footerProps}
        />
      )}
    </Container>
  );
}

export { Header, Body, Footer };
