import React, { useEffect } from "react";
import { Box, Button, Grid, Typography, Modal } from "@mui/material";
import { MuiButton } from "common/components/button";
import { BorderLinearProgress } from "common/components/progress";
import { LazyLoadImage as Image } from "react-lazy-load-image-component";
import failedCreateShop from "assets/img/failedCreateShop.png";
import PaymentHook from "modules/space/booking/payment/payment.hook";
import errorStore from "common/stores/error.store";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 320,
  bgcolor: "background.paper",
  textAlign: "center",
  borderRadius: 3,
  py: 4,
  px: 2,
};

export default function PaymentErrorModal(props) {
  const open = errorStore((state) => state.open);
  const setOpen = errorStore((state) => state.setOpen);
  const error = errorStore((state) => state.error);
  const { handleSubmit } = PaymentHook(props);

  return (
    <Modal
      open={open}
      onClose={() => props.onClose ?? setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography mb={4} fontSize={16} fontWeight="bold">
          {error.title}
        </Typography>
        <Image src={failedCreateShop} width="167px" height="142px" />
        {error.subtitle && (
          <Typography mt={4} fontSize={16} children="ขออภัยในความไม่สะดวก" />
        )}
        <Typography mt={2} mb={error.support ? 0 : 2} fontSize={14}>
          {error.body}
        </Typography>
        {error.supportText && (
          <Typography mt={2} fontSize={12}>
            ติดต่อแจ้งปัญหาการใช้งานได้ที่
          </Typography>
        )}
        {error.support && (
          <Grid container mb={2}>
            <Grid item container></Grid>
          </Grid>
        )}
        <MuiButton
          variant="contained"
          sx={{ fontSize: "16px" }}
          loading={props.loading}
          onClick={error.onClick ?? handleSubmit}
        >
          {error.button ?? "ลองใหม่อีกครั้ง"}
        </MuiButton>
        {error.close !== false && (
          <MuiButton
            variant="text"
            color="info"
            sx={{ fontSize: "16px", mt: 1 }}
            onClick={() => setOpen(false)}
          >
            {error.secondButton ?? "ปิด"}
          </MuiButton>
        )}
      </Box>
    </Modal>
  );
}
