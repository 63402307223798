import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import React, { useState, useEffect, memo, useMemo } from "react";
import useStore from "common/stores/mall.store";
import spaceStore from "common/stores/space.store";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import OriginSme from "assets/logo/origin_sme.svg";
import { shortTermCampaigns } from "assets/mockdata/short_term_campaign";
import Breadcrumbs from "common/components/breadcrumbs";
import Link from "common/components/link";
import { AppBar } from "common/components/nav";
import BottomNav from "modules/space/bottom-nav";
import { PlaceIcon } from "modules/space/mall.component";
import { LazyLoadImage as Image } from "react-lazy-load-image-component";
import { useNavigate, useParams } from "react-router-dom";
import { useMallsGroup } from "common/hooks/use-query/mall.hook";
import { useSpaceGroupingInfo } from "common/hooks/use-query/space.hook";
import _ from "lodash";
import Loader from "common/components/spinner";
import moment from "moment";

export default function DiscoverLocation() {
  const { groupId } = useParams();
  const mallsGroup = useStore((e) => e.mallsGroup);
  const spaceGroupingInfo = spaceStore((e) => e.spaceGroupingInfo);
  const { isLoadingMallsGroup } = useMallsGroup({ group_id:  groupId});
  const { isLoadingSpaceGroupingInfo } = useSpaceGroupingInfo({ group_id:  groupId});
  const navigate = useNavigate();
  
  if (_.isEmpty(mallsGroup) || _.isEmpty(spaceGroupingInfo) || isLoadingMallsGroup || isLoadingSpaceGroupingInfo ) return <Loader />;

  return (
    <>
      <AppBar>{spaceGroupingInfo?.name}</AppBar>
      <Box sx={{ pb: 4 }}>
        <Grid>
          <Container>
            <Breadcrumbs
              menu={[
                { text: "พื้นที่เช่า", to: "/space" },
                { text: spaceGroupingInfo?.name },
              ]}
              sx={{ pb: 2 }}
            />
            {/* <TabsDetail handleChange={handleChange} value={value} /> */}
          </Container>
          <CampaignBanner campaign={spaceGroupingInfo} />
          <Grid bgcolor="#FAFAFC">
            <CampaignDetail campaign={spaceGroupingInfo} />
          </Grid>
        </Grid>
        <Grid bgcolor="#FAFAFC" py={3}>
          <Container>
            <MallComponent malls={mallsGroup?.result_data} />
          </Container>
        </Grid>
        <BottomNav selected="หน้าแรก" />
      </Box>
    </>
  );
}

function CampaignBanner({ campaign }) {
  return (
    <Container
      sx={{
        maxWidth: "1440px !important",
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
      }}
    >
      <Grid container pt={2}>
        <Box
          component={Image}
          src={campaign?.image_url}
          height={{ xs: 172, md: 331 }}
          borderRadius={{ xs: 0, md: 2 }}
          width="100%"
          sx={{
            flexGrow: 1,
            objectFit: "cover",
          }}
        />
      </Grid>
    </Container>
  );
}

function stripHtml(html) {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  const test = tmp.textContent || tmp.innerText || "";
  return test;
}

function CampaignDetail({ campaign }) {
  
  return (
    <Container
      sx={{
        marginTop: { xs: "0", md: "-50px" },
        paddingLeft: { xs: "0", md: "16px" },
        paddingRight: { xs: "0", md: "16px" },
        position: "relative",
      }}
    >
      <Paper
        variant="outlined"
        sx={{
          background: "#FFFFFF",
          borderRadius: { xs: "0", md: "15px" },
          padding: "35px",
        }}
      >
        <Box
          minHeight="200px"
          width="100%"
        >
          <Typography fontSize={30} fontWeight={700} noWrap>
            {campaign?.name}
          </Typography>
          <Typography fontSize={20} fontWeight={700} noWrap mt={3}>
            รายละเอียดกิจกรรม
          </Typography>
          <Typography fontSize={16} mt={2}>
            {stripHtml(campaign?.description)}
          </Typography>
          <Box mt={2} display="flex">
            <CalendarTodayIcon sx={{ color: "#C90300" }} />
            <Typography fontSize={16} fontWeight={700} ml={1}>
              ช่วงเวลากิจกรรม
            </Typography>
            <Typography fontSize={16} ml={3}>
              {moment(campaign?.from_date).add(543, "year").format("DD MMM YY")}{" - "}
              {moment(campaign?.to_date).add(543, "year").format("DD MMM YY")}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}

export function MallComponent(props) {
  return (
    <MallWrapper>
      <MallContent {...props} />
    </MallWrapper>
  );
}

export function MallWrapper(props) {
  return (
    <Box
      sx={{ flexGrow: 1, textAlign: "-webkit-center" }}
      pb={{ xs: 5, md: 0 }}
    >
      <Grid
        container
        spacing={2}
        width={{ xs: "auto", md: props.width ?? 1120 }}
        p={{ xs: 0, md: 2 }}
        columns={{ xs: 12, sm: 3, md: 3 }}
      >
        {props.children}
      </Grid>
    </Box>
  );
}
export function MallContent(props) {
  return (
    props.malls?.[0] &&
    props.malls?.map((mall) => (
      <Grid item xs={6} sm={1} md={1} {...props.column}>
        <MallCard {...mall} />
      </Grid>
    ))
  );
}

export function MallCard(props) {
  const { groupId } = useParams();
  return (
    <Paper
      variant="outlined"
      container
      sx={{
        borderRadius: 3,
      }}
    >
      <Grid
        container
        direction="column"
        component={Link}
        to={`/space/campaign/${groupId}/mall/${props.id}`}
      >
        <Box
          component={Image}
          src={props.image_url ?? OriginSme}
          sx={{
            ...(!props.image_url & { p: 4 }),
            width: "100%",
            borderRadius: 3,
            objectFit: "cover",
            aspectRatio: "1",
          }}
        />
        <Grid item container direction="column" p={1.5} textAlign="left">
          <Typography fontSize={16} width="inherit" noWrap>
            {props.name}
          </Typography>
          <Typography fontSize={16} color="grey.600" width="inherit" noWrap>
            <PlaceIcon />
            {props.province}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
