import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { formatCurrency } from "common/utils/intl";

export default function Item(props) {
  return (
    <Grid item {...props}>
      <Typography
        fontSize={props.fontSize ?? 16}
        fontWeight={props.fontWeight ?? "normal"}
      >
        {props.children ?? "-"}
      </Typography>
    </Grid>
  );
}

export function Price({ price }) {
  return (
    <>
      <span style={{ color: "#E34745" }}>{`${formatCurrency(price)}`}</span>{" "}
      /วัน
    </>
  );
}
