import { axios, HeaderDefault, GetEnvironment } from "services/base.service";

export const getCompany = (payload) => {
  return axios({
    url: `/v1/campaign/company/all`,
    method: "get",
  }).then((res) => res?.data?.data);
};

export const getCampaign = (payload) => {
  return axios({
    url: `/v1/campaign/search`,
    method: "post",
    params: payload,
    data: payload,
  }).then((res) => res?.data?.data);
};

export const getRegisteredCampaign = ({ user_id, campaign_id }) => {
  return axios({
    url: `/v1/campaign/registered/search`,
    method: "post",
    data: { user_id, campaign_id },
  }).then((res) => res?.data?.data);
};

export const getCampaignShopInfo = ({ campaign_id }) => {
  return axios({
    url: `/v1/campaign/shop/info`,
    method: "get",
    params: { campaign_id },
  }).then((res) => res?.data?.data);
};

export const registerCPOppPlatform = (payload) => {
  return axios({
    url: `/v1/campaign/opportunity/register`,
    method: "post",
    data: payload,
  });
};
