import create from "zustand";

const useStore = create((set) => ({
  shop: {},
  shops: [],
  primaryShop: {},
  setShop: (shop) => set({ shop: shop }),
  setShops: (shops) => set({ shops: shops }),
  clearShops: () => set({ shop: {}, shops: [], primaryShop: {} }),
  setPrimaryShop: (primaryShop) => set({ primaryShop: primaryShop }),
  isPrimaryShop: false,
  setIsPrimaryShop: (isPrimaryShop) => set({ isPrimaryShop: isPrimaryShop }),
}));

export default useStore;
