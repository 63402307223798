import React, { useState, useEffect } from "react";
import { Autocomplete, Box, Grid, Typography, TextField } from "@mui/material";
import { Select } from "common/components/select";
import { useBusinessType } from "common/hooks/use-query/shop.hook";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import spaceStore from "common/stores/space.store";
import { AREA_TYPE, CONTRACT_TYPE } from "common/constants/booking";
import _ from "lodash";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

export default function FilterContent(props) {
  return (
    <ValidatorForm>
      <Grid container p={2} spacing={2} rowSpacing={4}>
        <Grid item xs={12}>
          <BasicField />
        </Grid>
        <Grid item xs={12} md={6}>
          <PriceRange />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateRange />
        </Grid>
        <Grid item xs={12}>
          <AreaSize />
        </Grid>
      </Grid>
    </ValidatorForm>
  );
}

const areaType = _.map(
  _.pickBy(AREA_TYPE, (e) => e, Boolean),
  (label, value) => ({ value, label })
);

function BasicField(props) {
  const [tempFilter, setTempFilter] = spaceStore((e) => [
    e.tempFilter,
    e.setTempFilter,
  ]);

  const { data: businessType } = useBusinessType();

  function handleChange(e) {
    setTempFilter({ [e.target.name]: e.target.value });
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TextValidator
          name="floor"
          onChange={handleChange}
          value={tempFilter?.floor}
          label="ชั้น"
          placeholder="B,G, 1 etc."
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          mb={0}
          value={tempFilter?.area_type}
          options={areaType}
          getOptionLabel={(option) => option?.label}
          onChange={(e, newValue) =>
            setTempFilter({
              area_type: newValue?.value,
            })
          }
          inputValue={AREA_TYPE?.[tempFilter?.area_type] ?? ""}
          renderInput={(params) => (
            <TextField
              {...params}
              label="รูปแบบพื้นที่"
              placeholder="เลือกรูปแบบพื้นที่"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          mb={0}
          options={_.map(CONTRACT_TYPE, (e) => e)}
          value={tempFilter?.contract_type}
          onChange={(e, newValue) =>
            setTempFilter({
              contract_type: newValue,
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="รูปแบบสัญญา"
              placeholder="เลือกรูปแบบสัญญา"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          value={tempFilter?.business_type}
          onChange={(e, newValue) => setTempFilter({ business_type: newValue })}
          options={businessType ?? []}
          renderInput={(params) => (
            <TextField
              {...params}
              label="ประเภทธุรกิจ"
              placeholder="เลือกรูปแบบธุรกิจ"
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

function PriceRange(props) {
  const [tempFilter, setTempFilter] = spaceStore((e) => [
    e.tempFilter,
    e.setTempFilter,
  ]);

  function handleChange(e) {
    setTempFilter({
      booth_price: {
        ...tempFilter.booth_price,
        [e.target.name]: e.target.value,
      },
    });
  }

  useEffect(() => {
    if (_.isEmpty(tempFilter?.booth_price?.max)) {
      return ValidatorForm.removeValidationRule("isGreaterThan");
    }
    ValidatorForm.addValidationRule("isGreaterThan", (value) => {
      if (value.length > 0) {
        return value > tempFilter?.booth_price?.min;
      }
    });
  }, [tempFilter?.booth_price?.min, tempFilter?.booth_price?.max]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography fontSize={16} fontWeight="bold">
          ช่วงราคา / วัน
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextValidator
          name="min"
          fullWidth
          label="ขั้นต่ำ"
          placeholder="ระบุราคา"
          value={tempFilter?.booth_price?.min ?? null}
          type="number"
          validators={["minNumber:1"]}
          errorMessages={["ระบุข้อมูลไม่ถูกต้อง"]}
          onChange={handleChange}
          min={1}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextValidator
          fullWidth
          name="max"
          label="สูงสุด"
          placeholder="ระบุราคา"
          value={tempFilter?.booth_price?.max ?? null}
          type="number"
          validators={["minNumber:1", "isGreaterThan"]}
          errorMessages={["ระบุข้อมูลไม่ถูกต้อง", "ระบุข้อมูลไม่ถูกต้อง"]}
          min={1}
          InputProps={{ inputProps: { min: 1 } }}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );
}

function DateRange() {
  const [tempFilter, setTempFilter] = spaceStore((e) => [
    e.tempFilter,
    e.setTempFilter,
  ]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography fontSize={16} fontWeight="bold">
          ช่วงวันที่
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          localeText={{ start: "วันเริ่มต้น", end: "วันสิ้นสุด" }}
        >
          <DateRangePicker
            disablePast
            value={
              tempFilter?.date ?? [
                tempFilter?.from_date ?? null,
                tempFilter?.to_date ?? null,
              ]
            }
            onChange={(newValue) => {
              setTempFilter({ date: newValue });
            }}
            renderInput={(startProps, endProps) => (
              <React.Fragment>
                <TextField fullWidth {...startProps} />
                <Box sx={{ mx: 1 }} />
                <TextField fullWidth {...endProps} />
              </React.Fragment>
            )}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}

function AreaSize(props) {
  const [tempFilter, setTempFilter] = spaceStore((e) => [
    e.tempFilter,
    e.setTempFilter,
  ]);

  function handleWidthChange(e) {
    setTempFilter({
      booth_width: {
        ...tempFilter.booth_width,
        [e.target.name]: e.target.value,
      },
    });
  }

  function handleLengthChange(e) {
    setTempFilter({
      booth_length: {
        ...tempFilter.booth_length,
        [e.target.name]: e.target.value,
      },
    });
  }

  useEffect(() => {
    if (
      _.isEmpty(tempFilter?.booth_length?.max) ||
      _.isEmpty(tempFilter?.booth_width?.max)
    ) {
      ValidatorForm.removeValidationRule("isLengthGreaterThan");
      ValidatorForm.removeValidationRule("isWidthGreaterThan");
    } else {
      ValidatorForm.addValidationRule("isLengthGreaterThan", (value) => {
        if (value) {
          return value > tempFilter?.booth_length?.min;
        }
      });
      ValidatorForm.addValidationRule("isWidthGreaterThan", (value) => {
        if (value) {
          return value > tempFilter?.booth_width?.min;
        }
      });
    }
  }, [tempFilter?.booth_length, tempFilter?.booth_width]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography fontSize={16} fontWeight="bold">
          ขนาดพื้นที่
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography fontSize={16}>ขั้นต่ำ</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextValidator
              name="min"
              fullWidth
              label="กว้าง"
              placeholder="โปรดระบุ"
              value={tempFilter?.booth_width?.min}
              type="number"
              min={1}
              onChange={handleWidthChange}
              validators={["minNumber:1"]}
              errorMessages={["ระบุข้อมูลไม่ถูกต้อง"]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextValidator
              name="min"
              fullWidth
              label="ยาว"
              placeholder="โปรดระบุ"
              value={tempFilter?.booth_length?.min}
              type="number"
              min={1}
              validators={["minNumber:1"]}
              errorMessages={["ระบุข้อมูลไม่ถูกต้อง"]}
              onChange={handleLengthChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography fontSize={16}>สูงสุด</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextValidator
              name="max"
              fullWidth
              label="กว้าง"
              placeholder="โปรดระบุ"
              value={tempFilter?.booth_width?.max}
              type="number"
              min={1}
              onChange={handleWidthChange}
              validators={["minNumber:1", "isWidthGreaterThan"]}
              errorMessages={["ระบุข้อมูลไม่ถูกต้อง", "ระบุข้อมูลไม่ถูกต้อง"]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextValidator
              name="max"
              fullWidth
              label="ยาว"
              placeholder="โปรดระบุ"
              value={tempFilter?.booth_length?.max}
              type="number"
              min={1}
              validators={["minNumber:1", "isLengthGreaterThan"]}
              errorMessages={["ระบุข้อมูลไม่ถูกต้อง", "ระบุข้อมูลไม่ถูกต้อง"]}
              onChange={handleLengthChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function compareMinValue(value, compareValue) {
  if (value === "") return { min: null, max: compareValue };

  value = Number(value);
  compareValue = Number(compareValue);

  return {
    min: value,
    max: value >= compareValue ? value + 1 : compareValue,
  };
}

function compareMaxValue(value, compareValue) {
  if (value === "") return { min: compareValue, max: null };

  value = Number(value);
  compareValue = Number(compareValue);

  return {
    min: value <= compareValue ? (value - 1 > 0 ? value - 1 : 0) : compareValue,
    max: value,
  };
}
