import { axios, HeaderDefault, GetEnvironment } from "services/base.service";
import _ from "lodash";
import * as USER_SCHEMA from "common/constants/schema/user";

export const register = (payload) => {
  return axios({
    url: `/v1/user/register`,
    method: "post",
    data: payload,
  }).then((res) => res?.data?.data);
};

export const getUserInfo = (payload) => {
  return axios({
    url: `/v1/user/info`,
    method: "get",
  }).then((res) => res?.data?.data);
};

export const getUserAddress = () => {
  return axios({
    url: `/v1/user/address`,
    method: "get",
  }).then((res) => res?.data?.data);
};

export const updateUserInfo = (payload) => {
  return axios({
    url: `/v1/user/update/info`,
    method: "post",
    data: payload,
  }).then((res) => res?.data?.data);
};

export const registerUser = (payload) => {
  return axios({
    url: "/v1/user/register",
    method: "post",
    data: payload,
  });
};

export const updateUserAddress = (payload) => {
  return axios({
    url: `/v1/user/update/address`,
    method: "post",
    data: payload,
  });
};

export const addUserAddress = (payload) => {
  return axios({
    url: `/v1/user/add/address`,
    method: "post",
    data: payload,
  });
};

export const deleteDocument = ({ document_id }) => {
  return axios({
    url: `/v1/user/document/delete`,
    method: "delete",
    params: { document_id },
  }).then((res) => res?.data?.data);
};

export const uploadDocument = ({ payload, document_type }) => {
  return axios({
    url: `/v1/user/document/new`,
    method: "post",
    data: payload,
    params: { document_type },
  }).then((res) => res?.data?.data);
};

export const forgotPassword = (phone) => {
  return axios({
    url: `/v1/user/reset/password`,
    method: "post",
    params: { phone },
  }).then((res) => res?.data?.data);
};
