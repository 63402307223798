import React, { useState, useEffect } from "react";
import { Button, Grid, Divider, Paper, Typography } from "@mui/material";
import { CalendarTodayOutlined } from "@mui/icons-material";
import spaceStore from "common/stores/space.store";
import bookingStore from "common/stores/booking.store";
import BookingList from "./booking-list";
import Item, { Price } from "./booth.component";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { useModal } from "mui-modal-provider";
import Calendar from "./booking.calendar";
import { useSpaceAvailableSpace } from "common/hooks/use-query/space.hook";
import Link from "common/components/link";
import { LoadingButton } from "@mui/lab";
import { ModalLogin } from "common/components/modal";
import shopStore from "common/stores/shop.store";
import { verifyShop } from "services/shop.service";
import { usePrimaryShopQuery } from "common/hooks/use-query";
import MobileDrawer from "./booking.mobile.drawer";
import ErrorModal from "common/modals/error";

export default function Booking(props) {
  const space = spaceStore((e) => e.space);
  const period = bookingStore((e) => e.period);
  const { showModal } = useModal();
  const { data: availableSpace, isLoading: availableSpaceIsLoading } =
    useSpaceAvailableSpace(space.id);
  const { data: primaryShop, refetch: primaryShopQuery } =
    usePrimaryShopQuery();

  return (
    <Paper variant="outlined" sx={{ borderRadius: 3 }}>
      <Grid container p={3}>
        <Item xs={12} fontSize={20} fontWeight="bold">
          จองพื้นที่{" "}
        </Item>
        {period?.[0] && (
          <>
            <Grid
              container
              justifyContent="space-between"
              py={0.5}
              alignItems="center"
            >
              <Item xs={8} fontSize={16} fontWeight="bold" color="grey.700">
                <CalendarTodayOutlined
                  sx={{ color: "primary.main", mr: 1, mb: -0.4, fontSize: 20 }}
                />
                วันจอง{" "}
                {period.length > 1 && (
                  <Typography
                    component="span"
                    color="primary.main"
                    fontSize={16}
                    sx={{ display: { xs: "contents", md: "none" } }}
                  >
                    ( {period.length} ช่วงเวลา )
                  </Typography>
                )}
              </Item>
              <Button
                vraint="text"
                sx={{ fontSize: 16 }}
                onClick={() => showModal(Calendar)}
              >
                เพิ่มวัน
              </Button>
            </Grid>
            <Grid item xs={12}>
              <BookingList />
            </Grid>
            <Divider component={Grid} item xs={12} />
          </>
        )}
        <Item
          xs={12}
          fontSize={20}
          fontWeight="bold"
          py={2}
          textAlign={{ xs: "center", md: "left" }}
        >
          <Price price={space?.booth_price} />
        </Item>
        {_.isEmpty(availableSpace) ? (
          <Button variant="contained" size="large" fullWidth disabled>
            เต็ม
          </Button>
        ) : (
          <BookableButton
            loading={availableSpaceIsLoading}
            primaryShop={primaryShop}
          />
        )}
      </Grid>
    </Paper>
  );
}

function BookableButton(props) {
  const { primaryShop } = props;
  const period = bookingStore((e) => e.period);
  const space = spaceStore((e) => e.space);
  const { showModal } = useModal();
  const navigate = useNavigate();
  const { checkAuth } = ModalLogin();

  function handleSubmit() {
    localStorage.setItem("from_to_date", JSON.stringify(period));
    return navigate(`/space/terms-and-conditions/${space?.id}`);
  }

  async function handleBooking(targetShop) {
    const fromService =
      space?.service_type === "shortterm_event"
        ? `/recruit/add-shop/?space=${space.id}`
        : `/recruit/add-shop/short-term/space/?space=${space.id}`;

    if (!checkAuth({ afterRegister: fromService })) {
      return;
    }

    if (_.isEmpty(targetShop)) {
      localStorage.setItem("from_service", `/space/mall/booth/${space.id}`);
      return navigate(fromService);
    }

    try {
      await handleMistype(targetShop);
      await checkSpaceType(targetShop);
      showModal(Calendar);
    } catch (err) {}
  }

  async function checkSpaceType(targetShop) {
    const required_documents = await verifyShop({
      shop_id: targetShop.id,
      service_type: space?.service_type,
    });

    if (required_documents) {
      localStorage.setItem("from_service", `/space/mall/booth/${space.id}`);
      navigate(`/recruit/add-shop/short-term/space/${targetShop.id}`);
      throw new Error("required more documents");
    }
  }

  const handleMistype = (targetShop) =>
    new Promise((resolve) => {
      if (targetShop?.business_type in space?.business_type) {
        resolve();
      } else {
        showModalError();
        return;
      }
    });

  function showModalError() {
    showModal(ErrorModal, {
      title: "ไม่สามารถจองได้",
      body: "เนื่องจากทประเภทธุรกิจของคุณไม่ตรงตามเงื่อนไข ของพื้นที่ ที่คุณเลือกกรุณาเลือกพื้นที่ใหม่",
      button: "พื้นที่ใหม่",
      onClick: () => navigate(`/space/mall/${space.mall_id}`),
      close: false,
    });
  }

  return period?.[0] ? (
    <ReponsiveButton
      disabled={props.loading}
      loading={props.loading}
      onClick={handleSubmit}
    />
  ) : (
    <LoadingButton
      variant="contained"
      size="large"
      fullWidth
      disabled={props.loading}
      loading={props.loading}
      onClick={() => handleBooking(primaryShop)}
    >
      เลือกวันจอง
    </LoadingButton>
  );
}

function ReponsiveButton(props) {
  const { showModal } = useModal();

  function handleSubmitMobile() {
    showModal(MobileDrawer, {
      submitButton: (
        <SubmitButton handleSubmit={props.onClick} {...props}>
          ยืนยัน
        </SubmitButton>
      ),
    });
  }

  return (
    <>
      <Grid width="100%" sx={{ display: { xs: "none", md: "flex" } }}>
        <SubmitButton handleSubmit={props.onClick} {...props} />
      </Grid>

      <Grid width="100%" sx={{ display: { xs: "flex", md: "none" } }}>
        <LoadingButton
          variant="contained"
          size="large"
          fullWidth
          disabled={props.loading}
          loading={props.loading}
          onClick={handleSubmitMobile}
        >
          จอง
        </LoadingButton>
      </Grid>
    </>
  );
}

function SubmitButton(props) {
  return (
    <LoadingButton
      variant="contained"
      size="large"
      fullWidth
      disabled={props.loading}
      loading={props.loading}
      onClick={props.handleSubmit}
    >
      {props.children ?? "จอง"}
    </LoadingButton>
  );
}
