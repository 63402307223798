import { Box, Modal, Typography } from "@mui/material";
import failedCreateShop from "assets/img/failedCreateShop.png";
import { MuiButton } from "common/components/button";
import { LazyLoadImage as Image } from "react-lazy-load-image-component";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 320,
  bgcolor: "background.paper",
  textAlign: "center",
  borderRadius: 3,
  py: 4,
  px: 2,
};

export default function ErrorModal(props) {
  function handleClick() {
    if (!!props.onClick) {
      props.onClick();
    }
    props.onClose();
  }

  return (
    <Modal {...props} onClick={() => {}}>
      <Box sx={style}>
        <Typography mb={4} fontSize={16} fontWeight="bold">
          {props.title}
        </Typography>
        <Image
          src={props.image ?? failedCreateShop}
          width="167px"
          height="142px"
        />
        <Typography mt={2} mb={2} fontSize={14}>
          {props.body}
        </Typography>
        <MuiButton
          fullWidth
          variant="contained"
          sx={{ fontSize: "16px" }}
          loading={props.loading}
          onClick={handleClick}
        >
          {props.button}
        </MuiButton>
        {props.close !== false && (
          <MuiButton
            fullWidth
            variant="text"
            color="info"
            sx={{ fontSize: "16px", mt: 1 }}
            onClick={props.secondOnClick}
          >
            {props.secondButton}
          </MuiButton>
        )}
      </Box>
    </Modal>
  );
}
