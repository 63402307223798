import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useSpaceInfo } from "common/hooks/use-query/space.hook";
import { useParams } from "react-router-dom";
import TermsAndConditions from "common/pages/terms-and-conditions";
import {
  useMallInfo,
  useConsentByCompany,
} from "common/hooks/use-query/mall.hook";

export default function VeryShortTC() {
  const navigate = useNavigate();
  const [termAndCondition, setTermAndCondition] = useState(null);

  const { spaceId } = useParams();
  const { data: space } = useSpaceInfo(spaceId);
  const { data: mall } = useMallInfo(space?.mall_id);

  useConsentByCompany({
    company_id: mall?.company_id,
    onSuccess: (data) => setTermAndCondition(data),
  });

  return (
    <TermsAndConditions
      text={termAndCondition}
      title="ข้อกำหนดและเงื่อนไข"
      tempAppBar={false}
      handleBack={() => navigate(-1)}
      disabledFooter
      bodyProps={{
        disabledTitle: true,
        mb: { xs: 90 },
      }}
    />
  );
}
