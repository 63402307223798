import create from "zustand";
import _ from "lodash";
import produce from "immer";

const initialTempFilter = {
  mall_name: "",
  company_name: "",
  province: "",
  business_type: "",
};


const initialGroupFilter = {
  group_id: "",
};

const defaultLocation = {
  //fortune town
  lat: 13.7581299,
  lng: 100.5648509,
};

const useStore = create((set) => ({
  mall: {},
  setMall: (mall) => set({ mall: mall }),
  malls: [],
  setMalls: (malls) => set({ malls: malls }),
  page: 1,
  setPage: (page) => set({ page: page }),
  nextPage: (page) => set((e) => ({ page: e.page + 1 })),
  size: 10,
  setSize: (size) => set({ size: size }),
  tempFilter: initialTempFilter,
  setTempFilter: (obj) =>
    set(
      produce((draft) => {
        _.map(obj, (value, key) => (draft["tempFilter"][key] = value));
      })
    ),
  filter: initialTempFilter,
  clearFilter: () => set({ tempFilter: initialTempFilter, filter: initialTempFilter }),
  setFilter: () => set((e) => ({ filter: e.tempFilter, page: 1 })),
  mallsDistance: [],
  setMallsDistance: (malls) => set((state) => ({ mallsDistance: malls })),
  curLat: defaultLocation.lat,
  setCurLat: (curLat) => set({ curLat }),
  curLng: defaultLocation.lng,
  setCurLng: (curLng) => set({ curLng }),
  center: null,
  setCenter: (center) => set({ center }),
  mallsGroup: [],
  setMallsGroup: (mall) => set({ mallsGroup: mall }),
}));

export default useStore;
