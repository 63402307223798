import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Chip,
  Grid,
  Divider,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import Carousel from "common/components/swiper";

export default function Header({ space }) {
  const images =
    space?.images?.[0] && space?.images?.map((e) => ({ img: e.image_url }));

  return (
    <Grid container pb={2}>
      <Container>
        <Typography fontSize={{ xs: 20, md: 24 }} fontWeight="bold" mb={1}>
          {space?.name}
        </Typography>
        <Carousel
          data={images}
          py={2}
          height={{ xs: 222, md: 450 }}
          width={{ xs: "inherit", md: 900 }}
          mx="auto"
          swiperProps={{
            objectFit: "cover",
          }}
        />
      </Container>
    </Grid>
  );
}
