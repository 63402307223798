import { Container, Typography } from "@mui/material";
import {
  PasswordForm,
  passwordRulesSchema,
} from "common/components/form/password";
import { AppBar } from "common/components/nav";
import Page from "common/components/page";
import { ValidatorHook } from "common/hooks/validator";
import formStore from "common/stores/form.store";
import _ from "lodash";
import { ProfileSidebar } from "modules/recruit/profile";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as authenService from "services/authen.service";
import {
  useMutateUser,
  useMutateShop,
  useMutatePrimaryShop,
} from "common/hooks/use-query";
export default function FillPassword(props) {
  const { ValidatorForm, setFormRef, formInvalid } = ValidatorHook();
  const [loading, setloading] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(true);
  const [form, setForm] = formStore((e) => [e.form, e.setForm]);
  const passwordRules = passwordRulesSchema(form);
  const requiredError = {
    validators: ["required"],
    errorMessages: ["โปรดระบุข้อมูล"],
  };

  const { mutateAsync: mutateUser } = useMutateUser();
  const { mutateAsync: mutateShop } = useMutateShop();
  const { mutateAsync: mutatePrimaryShop } = useMutatePrimaryShop();

  async function handleSubmit() {
    setloading(true);
    try {
      await authenService.changePassword({ password: form?.password });
      const from_service = localStorage.getItem("from_service");
      if (!from_service) {
        localStorage.setItem("from_service", "/recruit");
      }
      mutateShop();
      await mutateUser();
      await mutatePrimaryShop();
      props.nextStep();
    } catch (err) {
      console.log("err", err);
    } finally {
      setloading(false);
    }
  }

  function checkPasswordRules() {
    return _.every(passwordRules, (e) => e === true);
  }

  useEffect(() => {
    setPasswordInvalid(!checkPasswordRules());
  }, [form?.password, form?.repassword]);

  return (
    <Page>
      <AppBar tempAppBar={props?.tempAppBar} stepper>
        {props.title}
      </AppBar>
      <Container maxWidth="md">
        <Typography fontSize={24} color="434342" mb={4} fontWeight="bold">
          ตั้งรหัสผ่าน
        </Typography>
        <ValidatorForm ref={setFormRef} onSubmit={handleSubmit}>
          <PasswordForm
            requiredError={requiredError}
            formInvalid={formInvalid}
            loading={loading}
            passwordInvalid={passwordInvalid}
            buttonText="บันทึก"
          />
        </ValidatorForm>
      </Container>
    </Page>
  );
}
