import React, { useState, useEffect } from "react";
import notfound from "assets/img/404PAGE.png";
import { Image } from "semantic-ui-react";

export default () => {
  return (
    <>
      <body style={{ fontFamily: "'Kanit', sans-serif" }}>
        <div style={{ marginTop: "37px" }}>
          <Image
            src={notfound}
            style={{
              marginTop: "200px",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </div>
        <div style={{ marginTop: "50px", textAlign: "center" }}>
          <object
            style={{ color: "#F01C31", fontWeight: "bold", fontSize: "26px" }}
            onClick={() => (window.location.href = "/auth/login")}
          >
            กลับหน้าแรก
          </object>
        </div>
      </body>
    </>
  );
};
