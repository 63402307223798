import { usePrimaryShopQuery, useShopQuery, useUserQuery } from "common/hooks/use-query";
import { useTheme } from "common/hooks/use-query/theme.hook";
import themeStore from "common/stores/theme.store";
import userStore from "common/stores/user.store";
import { parseDomain } from "parse-domain";
import { useEffect, useMemo } from "react";
import { useRoutes } from "react-router-dom";
import authRoute from "./auth";
import businessMatchingRoute from "./business-matching";
import devRoute from "./dev";
import lineRoute from "./line";
import publicRoute from "./public";
import recruitRoute from "./recruit";
import settingsRoute from "./settings";
import spaceRoute from "./space";
import useLocalStorage from "use-local-storage";
import _ from "lodash";

const reactEnv = process.env.REACT_APP_ENV;

export default function Router() {
  const [token] = useLocalStorage("token", "");
  const { isLoading: isLoadingUser } = useUserQuery(token);
  useShopQuery();
  usePrimaryShopQuery();

  const { domain: urlDomain } = parseDomain(window.location?.host);
  // const mockDomain = "cpseeding";
  const mockDomain = "localhost";
  const domain = reactEnv === "dev" ? mockDomain : urlDomain;

  const { data: theme, isLoading: isLoadingDomain } = useTheme(domain);

  const routes = useAuthRoutes(theme?.modules);
  const routing = useRoutes(routes);

  if (isLoadingDomain || (!!token && isLoadingUser)) return <></>;
  return routing;
}

function getDefaultRoute(modules) {
  if (modules?.recruit) {
    return "/recruit";
  } else if (modules?.space) {
    return "/space";
  } else if (modules?.business_matching) {
    return "/business-matching/preview";
  } else {
    return "/auth/login";
  }
}

export function useAuthRoutes() {
  const { modules } = themeStore((e) => e.theme);
  const isAuthenticated = userStore((e) => e.isAuthenticated);
  const defaultRoute = useMemo(() => getDefaultRoute(modules), [modules]);

  //prettier-ignore
  const routes = useMemo(() => [
    ...spaceRoute(isAuthenticated,modules), 
    ...recruitRoute(isAuthenticated,modules), 
    ...settingsRoute(isAuthenticated,modules), 
    ...publicRoute(defaultRoute), 
    ...businessMatchingRoute(isAuthenticated,modules),
    ...lineRoute(), 
    ...devRoute(), 
    ...authRoute], [modules,defaultRoute,isAuthenticated]
  )

  return routes;
}
