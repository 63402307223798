import React from "react";
import { Link, useLocation } from "react-router-dom";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Drawer,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import recruitStore from "modules/recruit/recruit.store.js";

export default function Sidebar(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = useLocation();
  const { setMenu } = recruitStore();
  const drawer = (
    <Grid>
      {!_.isEmpty(props.menuList) && (
        <List>
          {props.menuList.map((menu, index) => (
            <ListItem
              button
              key={index}
              sx={{
                color: menu.text === props.selected ? "#DC1A17" : "#272626",
                bgcolor: menu.text === props.selected ? "#FAFAFC" : "#fff",
                borderRadius: 2.5,
              }}
              onClick={() => {
                setMenu(menu);
                navigate(menu.to);
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="left"
                alignItems="center"
                sx={{
                  fontSize: 11,
                  ...props.style,
                }}
                onClick={() => {
                  navigate(menu.to);
                }}
              >
                {menu.icon} <Typography ml={1}>{menu.text}</Typography>
              </Grid>
              {/* <ListItemIcon>{menu.icon}</ListItemIcon> */}
            </ListItem>
          ))}
        </List>
      )}
    </Grid>
  );

  return (
    <Box component="nav" sx={props?.sx}>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      {props.footer ? (
        <Grid>{drawer}</Grid>
      ) : (
        <Drawer
          variant="permanent"
          sx={{
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: props.width ?? 300,
              bgcolor: "#F8F9FB",
              // bgcolor: "#E9F7F6",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      )}
    </Box>
  );
}
