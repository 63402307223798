import { Box, Container } from "@mui/material";

export default function Page(props) {
  return (
    <Box
      sx={{
        minHeight: "80vh",
        display: "flex",
        flexDirection: "column",
        maxWidth: "100%",
        ...props?.style,
        ...props?.sx,
      }}
    >
      {props.disabledContainer ? (
        props.children
      ) : (
        <Container>{props.children}</Container>
      )}
    </Box>
  );
}
