import React, { useMemo } from "react";
import ListComponent from "./list.component";
import themeStore from "common/stores/theme.store";

export default function NestedList() {
  const menu = useMenuList();

  if (menu.length < 2) return <></>;

  return <ListComponent header="บริการ" items={menu} />;
}

export function useMenuList() {
  const { modules } = themeStore((e) => e.theme);
  const menu = [
    modules?.recruit && { label: "หน้าแรก", to: "/recruit" },
    modules?.space && { label: "เช่าพื้นที่ขายของ", to: "/space" },
    modules?.campaign && { label: "สมัครโครงการ", to: "/recruit/campaign" },
    modules?.business_matching && {
      label: "จับคู่ค้าธุรกิจ",
      to: "/business-matching/preview",
    },
  ].filter(Boolean);

  return menu;
}
