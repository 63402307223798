import _ from "lodash";
import moment from "moment";
import TruemoneyLogo from "assets/img/truemoney-logo.png";
import PromptpayLogo from "assets/img/promptpay-logo2.png";

export const BOOKING_STATUS = {
  approved: { label: "ผ่านการอนุมัติ", color: "#18A0FB" },
  disapproved: { label: "ไม่อนุมัติ", color: "#DC3546" },
  expired: { label: "การจองหมดอายุ", color: "#DC3546" },
  pending_approval: { label: "รออนุมัติ", color: "#DEB047" },
  cancelled: { label: "ยกเลิก-ไม่คืนเงิน", color: "#DC3546" },
  failed: { label: "ล้มเหลว", color: "#DC3546" },
};

export const TRANSACTION_STATUS = {
  paid: { label: "ชำระเงินแล้ว", color: "#29A746" },
  pending_payment: { label: "รอชำระเงิน", color: "#DEB047" },
  default: { label: "รอชำระเงิน", color: "#DEB047" },
  landlord_pending: { label: "ชำระเงินตรงกับเจ้าของพื้นที่", color: "#DEB047" },
};

const isToDate = (e) =>
  e.booking_date_range_info.every(
    (date) => date.to_date > moment().format("YYYY-MM-DD")
  );

const isPassedDate = (e) =>
  e.booking_date_range_info.every(
    (date) => moment().format("YYYY-MM-DD") > date.to_date
  );

export const BOOKING_CONDITION = {};

export const BOOKING_FILTER = {
  รอยืนยัน: (e) => e.booking_status === "pending_approval",
  สำเร็จ: (e) => e.booking_status === "approved",
  ไม่สำเร็จ: (e) => e.booking_status === "disapproved",
  ยกเลิก: (e) => e.booking_status === "cancelled",
};

export const BOOKING_INFORMATION = (e) => {
  if (!e.space_payment_service && e.transaction_status !== "paid")
    return {
      variant: "warning",
      title: "การชำระเงินตรงกับเจ้าของพื้นที่",
      description:
        "พื้นที่นี้จำเป็นต้องชำระเงินกับเจ้าของพื้นที่โดยตรง เจ้าของพื้นที่จะติดต่อท่านเพื่อทำการชำระเงินต่อไปผ่านทางโทรศัพท์หรืออีเมลที่ท่านได้แจ้งบนระบบ",
    };
  else if (e.booking_status === "pending_approval")
    return {
      variant: "warning",
      title: "การชำระเงิน สามารถทำได้เมื่อได้รับการอนุมัติ",
      description:
        "พื้นที่นี้ ต้องได้รับการอนุมัติจากเจ้าของพื้นที่ก่อนชำระเงินเมื่อได้รับการอนุมัติ ท่านจะได้รับการแจ้งเตือนการชำระเงิน และสามารถเข้ามาชำระเงินที่หน้า “การจอง” ได้",
    };
  else if (e.transaction_status === "expired")
    return {
      variant: "danger",
      color: "#F01C31",
      title: "ไม่ได้ชำระเงินในเวลาที่กำหนด",
      description:
        "เนื่องจากท่านไม่ชำระเงินในเวลาที่กำหนดของพื้นที่ สามารถสอบถามรายละเอียดเพิ่มเติมได้ที่: 02-123-4567",
    };
  else if (
    e.booking_status === "approved" &&
    e.transaction_status !== "paid" &&
    e.transaction_status !== "failed" &&
    !_.isEmpty(e.booking_expires_at)
  )
    return {
      variant: "danger",
      color: "#F01C31",
      title: `โปรดชำระเงินภายใน ${moment(e.booking_expires_at).format(
        "D MMMM YYYY"
      )}`,
      description:
        "หากท่านไม่ชำระเงินภายในเวลาที่กำหนดจะถือว่า ท่านสละสิทธิ์การจองพื้นที่ดังกล่าว",
    };
  else if (e.booking_status === "disapproved")
    return {
      variant: "warning",
      title: "ท่านไม่ผ่านการอนุมัติ",
      description:
        "เนื่องจากท่านไม่ผ่านเงื่อนไขและข้อกำหนดของพื้นที่ สอบถามรายละเอียดเพิ่มเติมได้ที่: 02-123-4567",
    };
  else if (e.booking_status === "cancelled")
    return {
      variant: "warning",
      title: "ไม่ได้ชำระเงินในเวลาที่กำหนด",
      description:
        "เนื่องจากท่านไม่ชำระเงินในเวลาที่กำหนดของพื้นที่ สามารถสอบถามรายละเอียดเพิ่มเติมได้ที่: 02-123-4567",
    };

  return { variant: "empty" };
};

export const PAYMENT = {
  truemoney: {
    src: TruemoneyLogo,
    name: "Truemoney wallet",
    type: "truemoney",
  },
  promptpay: { src: PromptpayLogo, name: "สแกนจ่ายเงิน", type: "promptpay" },
};

export const CONTRACT_TYPE = {
  short_term: "ระยะสั้น",
  long_term: "ระยะยาว",
  very_short_term: "ระยะพิเศษ",
};

export const AREA_TYPE = {
  indoor: "ในห้าง",
  outdoor: "นอกห้าง",
  "": "",
};
