import { axios, HeaderDefault, GetEnvironment } from "services/base.service";

export const login = (payload) => {
  return axios({
    url: `/v1/auth/login`,
    method: "post",
    data: payload,
  })
    .then((res) => {
      const data = res?.data?.data;
      localStorage.setItem("token", `"${data.token}"`);
      localStorage.setItem("refresh_token", `"${data.refresh_token}"`);
      return data;
    })
    .catch((err) => {
      throw new Error(err.response?.data?.data);
    });
};

export const logout = (payload) => {
  return axios({
    url: `/v1/auth/logout`,
    method: "post",
  }).then((res) => res?.data?.data);
};

export const changePassword = ({ password }) => {
  return axios({
    url: `/v1/user/update/info`,
    method: "post",
    data: { password },
  }).then((res) => res?.data?.data);
};

export const getOtp = ({ phone }) => {
  return axios({
    url: `/v1/user/otp/request`,
    method: "post",
    params: { phone },
  }).then((res) => res?.data?.data);
};

export const verifyOtp = (payload, is_reset_password) => {
  return axios({
    url: `/v1/user/otp/verify`,
    method: "post",
    params: { is_reset_password },
    data: payload,
  }).then((res) => res?.data?.data);
};

export const checkDuplicateUser = (payload) => {
  return axios({
    url: `/v1/user/duplicate/verification`,
    method: "post",
    data: payload,
  })
    .then((res) => res?.data?.data)
    .catch((err) => {
      throw new Error(err.response?.data?.data);
    });
};
