import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export function Item(props) {
  const bp = 12;

  return (
    <Grid
      item
      sx={{
        mt: props.mt,
        mr: props.mr,
        mb: props.mb,
        ml: props.ml,
        mx: props.mx,
        my: props.my,
      }}
      xs={props.xs ?? bp}
      onClick={props.onClick}
      alignSelf={props.alignSelf}
    >
      <Typography
        noWrap={props.ellipsis}
        fontSize={props.fontSize ?? 14}
        color={props.color ?? "auto"}
        fontWeight={props.bold ? "bold" : "normal"}
        sx={{
          ...(props.right ? { textAlign: "right" } : {}),
          ...(props.center ? { textAlign: "center" } : {}),
          ...(props.link ? { textDecoration: "underline" } : {}),
          lineBreak: "anywhere",
          ...props.sx,
        }}
      >
        {props.link ? (
          <Link to={props.to ?? "#"}>{props.children ?? "-"}</Link>
        ) : (
          props.children ?? "-"
        )}
      </Typography>
    </Grid>
  );
}

export function Title(props) {
  return (
    <Grid item xs={12} md={6}>
      <Typography color="grey.700" {...props} />
    </Grid>
  );
}

export function Description(props) {
  return (
    <Grid item xs={12} md={6} mb={props.mb ?? 2}>
      <Typography color="grey.700" fontSize={16} fontWeight="bold" {...props} />
    </Grid>
  );
}
