import React, { useEffect } from "react";
import { Box, Button, Grid, Typography, Modal } from "@mui/material";
import { FooterButton, MuiButton } from "common/components/button";
import { BorderLinearProgress } from "common/components/progress";
import { LazyLoadImage as Image } from "react-lazy-load-image-component";
import failedCreateShop from "assets/img/failedCreateShop.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 320,
  bgcolor: "background.paper",
  textAlign: "center",
  borderRadius: 3,
  py: 4,
  px: 2,
};

export default function ErrorModal(props) {
  function handleClick() {
    if (!!props.onClick) {
      props.onClick();
    }
    props.onClose();
  }

  return (
    <Modal {...props} onClick={() => {}}>
      <Box sx={style}>
        <Typography mb={4} fontSize={16} fontWeight="bold">
          {props.title}
        </Typography>
        <Image
          src={props.image ?? failedCreateShop}
          width="167px"
          height="142px"
        />
        <Typography mt={2} mb={2} fontSize={14}>
          {props.body}
        </Typography>
        <FooterButton
          sx={{ fontSize: "16px" }}
          mb={0}
          fullWidth
          color="primary"
          type="submit"
          backButton={props?.secondButton ?? props?.backButton}
          onClick={handleClick}
          loading={props?.loading}
          backOnClick={props?.secondOnClick ?? props?.backOnClick}
          {...props}
        >
          {props?.button ?? "ถัดไป"}
        </FooterButton>
      </Box>
    </Modal>
  );
}
