import {
  useShopQuery,
  usePrimaryShopQuery,
  useBusinessType,
  useShopInfoQuery,
  useMutateShop,
  useMutatePrimaryShop,
} from "common/hooks/use-query/shop.hook";
import { useUserQuery, useMutateUser } from "common/hooks/use-query/user.hook";

export default function useQueryHook(props) {
  const { refetch: userRefetch } = useUserQuery();
  const { refetch: shopsRefetch } = useShopQuery();
  const { refetch: primaryShopRefetch } = usePrimaryShopQuery();

  async function refetchStore() {
    await userRefetch();
    await primaryShopRefetch();
    shopsRefetch();
  }

  return { refetchStore };
}

export {
  useShopQuery,
  usePrimaryShopQuery,
  useBusinessType,
  useShopInfoQuery,
  useUserQuery,
  useMutateUser,
  useMutateShop,
  useMutatePrimaryShop,
};
