import React from "react";
import { Typography, Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Link from "common/components/link";

export default function Breadcrumbs(props) {
  return (
    <MuiBreadcrumbs
      aria-label="breadcrumb"
      sx={{
        fontSize: 12,
        mt: "15px",
        ".MuiBreadcrumbs-separator": { mx: "4px" },
        display: { xs: "none", md: "block" },

        ...props.sx,
      }}
    >
      {props.menu.map((e, index) =>
        e.to ? (
          <Typography component={Link} color="#272626" to={e.to} key={index}>
            {e.text}
          </Typography>
        ) : (
          <Typography color="grey.400" key={index}>
            {e.text}
          </Typography>
        )
      )}
    </MuiBreadcrumbs>
  );
}
