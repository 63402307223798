import loadable from "@loadable/component";
import SuccessShop from "modules/recruit/add-shop/success-shop";
import TermAndCondition from "common/pages/terms-and-conditions";
import FillPassword from "pages/settings/reset-password/fill-password";
const CPRegisterStatus = loadable(() => import("pages/business-matching/status"));
const DEV_MODE = process.env.NODE_ENV === "development";

const basePath = "dev";
const devRoute = (isAuthenticated) => {
  return DEV_MODE === true
    ? [
        { exact: true, path: `/${basePath}/success_shop`, element: <SuccessShop /> },
        { exact: true, path: `/${basePath}/terms-and-conditions`, element: <TermAndCondition /> },
        { exact: true, path: `/${basePath}/status`, element: <CPRegisterStatus /> },
        { exact: true, desktop: true, path: `/${basePath}/fill-password`, element: <FillPassword /> },
      ]
    : [];
};

export default devRoute;
