import React, { useState, useEffect } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import bookingStore from "common/stores/booking.store";
import dayjs from "dayjs";
import produce from "immer";
import _ from "lodash";
import "dayjs/locale/th";

dayjs.locale("th");

export default function BookingList(props) {
  return (
    <>
      <Grid sx={{ display: { xs: "flex", md: "none" } }}>
        <SingleList />
      </Grid>

      <Grid sx={{ display: { xs: "none", md: "flex" } }}>
        <MultiList />
      </Grid>
    </>
  );
}

export function MultiList(props) {
  const period = bookingStore((e) => e.period);
  if (_.isEmpty(period)) return <></>;

  return (
    <Grid container pb={2} spacing={props.spacing ?? 0}>
      {period.map((e, index) => (
        <PeriodComponent index={index} date={e} fontSize={props.fontSize} />
      ))}
    </Grid>
  );
}

function SingleList(props) {
  const period = bookingStore((e) => e.period);

  return (
    <Grid container pb={2}>
      <PeriodComponent date={period?.[0]} />
    </Grid>
  );
}

function PeriodComponent({ date, index, fontSize }) {
  const { removePeriod } = bookingStore();

  function handleRemove() {
    removePeriod(index);
  }

  return (
    <Grid item xs={12}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        rowSpacing={1}
      >
        <Typography fontSize={fontSize ?? 14}>
          {dayjs(date?.from_date).format("D MMM YY")} -{" "}
          {dayjs(date?.to_date).format("D MMM YY")} (
          {dayjs(date?.to_date).diff(date?.from_date, "day") + 1} วัน)
        </Typography>
        <IconButton onClick={handleRemove}>
          <Close sx={{ color: "grey.800" }} />
        </IconButton>
      </Grid>
    </Grid>
  );
}
