import React, { useState, useEffect } from "react";
import * as lineService from "services/line.service";
import {
  useLineUrlQuery,
  useLineFriendShipQuery,
} from "common/hooks/use-query/line.hook";
import userStore from "common/stores/user.store";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import NavigateHook from "common/hooks/router/navigate.hook";
import loadingStore from "common/stores/loading.store";

export default function AddedLineOa(props) {
  const { navigateFromService } = NavigateHook();
  const user = userStore((state) => state.user);

  const setLoading = loadingStore((state) => state.setLoading);
  const { isLoading: lineUrlIsLoading, data: lineUrl } = useLineUrlQuery();
  const { isLoading: friendShipIsLoading, data: friendShip } =
    useLineFriendShipQuery();

  function redirectToLineLogin() {
    localStorage.setItem("line_status", "/add-line-oa");
    window.location.href = lineUrl;
    return;
  }

  function checkAccessToken() {
    if (_.isNil(localStorage.getItem("access_token"))) {
      redirectToLineLogin();
      throw new Error("");
    }
  }

  async function handleLineStatus() {
    // if (_.isNil(user?.line_login_data)) {
    //   // try{
    //   //    await lineService.integrate();
    //   // }catch(err){
    //   redirectToLineLogin();
    //   throw new Error("");
    //   // }
    // }

    if (friendShip?.friend_flag === true) {
      return navigateFromService();
    } else {
      setLoading(false);
      localStorage.removeItem("line_status");
    }
  }

  async function checkLineStatus() {
    await handleLineStatus();
    return true;
  }

  useEffect(() => {
    setLoading(lineUrlIsLoading || friendShipIsLoading);
  }, [lineUrlIsLoading, friendShipIsLoading, setLoading]);

  return { lineUrl, checkLineStatus };
}
