import React, { useState, useEffect, memo } from "react";
import { Box, Container, Grid } from "@mui/material";
import { Summary } from "modules/space/booking/payment/footer";
import PaymentHook from "modules/space/booking/payment/payment.hook";
import { MuiButton } from "common/components/button";
import paymentStore from "common/stores/payment.store";
import bookingStore from "common/stores/booking.store";
import moment from "moment";
import useInterval from "use-interval";
import { useModal } from "mui-modal-provider";
import ErrorModal from "modules/settings/error/modal";
import _ from "lodash";

export default function FooterComponent(props) {
  const { handlePromptpayRemaining } = PaymentHook(props);

  const booking = bookingStore((state) => state.booking);

  useEffect(() => {
    handlePromptpayRemaining();
  }, [booking]);

  return (
    <Grid p={1}>
      <Summary {...props} />
      {!props.disabledButton && <HandleButton {...props} />}
    </Grid>
  );
}
const HandleButton = memo((props) => {
  const pendingPayment = paymentStore((e) => e.pendingPayment);

  return pendingPayment ? (
    <RemainingDurationButton {...props} />
  ) : (
    <SubmitPaymentButton {...props} />
  );
});

function RemainingDurationButton(props) {
  const { checkPaymenStatus, handlePromptpay, refetch } = PaymentHook(props);
  const booking = bookingStore((state) => state.booking);
  const [remainDuration, setRemainDuration, pendingPayment, setPendingPayment] =
    paymentStore((e) => [
      e.remainDuration,
      e.setRemainDuration,
      e.pendingPayment,
      e.setPendingPayment,
    ]);

  const status =
    booking?.transaction_payment_channel === "promptpay" &&
    booking?.transaction_status === "pending_payment" &&
    booking?.transaction_expires_at !== null;

  function pendingPaymentExpired() {
    if (pendingPayment) refetch();
    setPendingPayment(false);
  }

  useInterval(() => {
    remainDuration > 1000
      ? setRemainDuration(remainDuration - 1000)
      : pendingPaymentExpired();
  }, 1000);

  useInterval(async () => {
    if (status) {
      try {
        await checkPaymenStatus();
      } catch (err) {
        console.log(err);
      }
    }
  }, 10000);

  return (
    <MuiButton
      onClick={handlePromptpay}
      disabled={_.isNil(booking.transaction_payment_channel)}
      {...{ ...props, color: "yellow" }}
    >
      ดำเนินการชำระเงินต่อ {moment(remainDuration).format("mm:ss")}
    </MuiButton>
  );
}

const SubmitPaymentButton = (props) => {
  const { payment, handleSubmit } = PaymentHook(props);
  const [loading, setLoading] = useState(false);
  const { showModal } = useModal();

  async function wrapHandleSubmit() {
    try {
      setLoading(true);
      await handleSubmit();
    } catch (err) {
      showModal(ErrorModal, {
        title: "เกิดข้อผิดพลาด",
        body: "กรุณารอซักครู่แล้วลองใหม่อีกครั้ง",
        button: "ลองอีกครั้ง",
        onClick: () => wrapHandleSubmit(),
        close: false,
      });
      console.error(err);
    }
    setLoading(false);
  }

  return (
    <MuiButton
      onClick={wrapHandleSubmit}
      disabled={_.isNil(payment?.type)}
      loading={loading}
      {...props}
    >
      ชำระเงิน
    </MuiButton>
  );
};
