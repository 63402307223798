const ERROR = {
  PROMPTPAY: {
    TRANSACTION_NOT_EXIST: {
      title: "ไม่พบข้อมูลการชำระเงินของคุณ",
      body: `โปรดรอสักครู่แล้วลองใหม่อีกครั้งหรือ ติดต่อแจ้งปัญหาการใช้งานได้ที่`,
      button: "ลองใหม่อีกครั้ง",
      support: true,
    },
    FAILED_PROCESSING: {
      title: "ไม่สามารถทำการชำระเงินได้",
      subtitle: true,
      body: `เนื่องจากพบข้อผิดพลาดจากระบบการชำระเงิน โปรดลองใหม่อีกครั้งหรือรอสักครู่เพื่อให้ระบบคืนสู่สภาวะปกติ`,
      button: "เลือกการชำระเงินใหม่",
      close: false,
    },
  },
  TRUEMONEY: {
    PAYMENT_REJECT: {
      title: "ไม่พบข้อมูลการชำระเงินของคุณ",
      subtitle: true,
      body: `เนื่องจากรายการของคุณถูกปฏิเสธโดยธนาคารผู้ออกบัญชี ขอให้ท่านทำการติดต่อธนาคารเจ้าของบัญชีของคุณ เพื่อให้สามารถดำเนินการชำระเงินได้ตามปกติ`,
      button: false,
    },

    INVALID_ACCOUNT: {
      title: "ไม่สามารถทำการชำระเงินได้",
      subtitle: true,
      body: `เนื่องจากระบบไม่พบบัญชีที่สามารถชำระเงิน ผ่านช่องทางที่คุณเลือก โปรดทำการตรวจสอบ บัญชีการชำระเงินของคุณใหม่อีกครั้ง`,
      button: false,
    },
    INSUFFICIENT: {
      title: "ไม่สามารถทำการชำระเงินได้",
      subtitle: true,
      body: `วงเงินคงเหลือของคุณไม่เพียงพอ โปรดทำการเติมเงิน`,
      button: false,
    },
    FAILED_PROCESSING: {
      title: "ไม่สามารถทำการชำระเงินได้",
      subtitle: true,
      body: `เนื่องจากพบข้อผิดพลาดจากระบบการชำระเงิน โปรดลองใหม่อีกครั้งหรือรอสักครู่เพื่อให้ระบบคืนสู่สภาวะปกติ`,
      button: "ลองใหม่อีกครั้ง",
    },
  },
  GENARAL: {
    title: "ขออภัยในความไม่สะดวก",
    subtitle: true,
    body: `เนื่องจากพบข้อผิดพลาดจากระบบการชำระเงิน โปรดลองใหม่อีกครั้งหรือรอสักครู่เพื่อให้ระบบคืนสู่สภาวะปกติ`,
    button: "เลือกการชำระเงินใหม่",
    close: false,
  },
  EXPIRED: {
    title: "การชำระเงินของคุณหมดอายุ",
    body: `เนื่องจากเลยเวลาเวลาการชำระเงินที่กำหนดโปรดเลือกการชำระเงินใหม่อีกครั้ง`,
    button: "เลือกการชำระเงินใหม่",
    close: false,
  },
  CANCELLED: {
    title: "ต้องการยกเลิกการชำระเงิน?",
    body: `หากยกเลิกการชำระเงินแล้ว คุณจะต้องเลือกวิธีการชำระเงินใหม่ เพื่อทำการชำระเงิน`,
    button: "ต้องการ",
    secondButton: "ไม่ต้องการ",
  },
};

export default ERROR;
