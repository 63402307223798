import Loader from "common/components/spinner";
import lineIntegrate from "common/hooks/line/integrate.hook";
import _ from "lodash";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export default function LineAuthorizing(props) {
  const [urlSearchParams] = useSearchParams();
  const code = urlSearchParams.get("code");

  const { recieveCodeParam } = lineIntegrate();

  async function checkLineIntegrate() {
    if (!_.isNil(code)) await recieveCodeParam(code);
  }

  useEffect(() => {
    checkLineIntegrate();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <Loader />;
}
