import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import themeStore from "common/stores/theme.store";
import { isLaptop } from "common/utils/mediaQuery";

export default function Footer(props) {
  const stickyStyle = {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 3,
    background: "white",
    boxShadow: "0 -5px 10px -5px rgba(0, 0, 0, 0.15)",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  };

  const desktopFooter = {
    minWidth: { md: props.width ?? 600 },
  };

  return (
    <Grid
      id="Footer"
      sx={{
        display: "block",
        ...(isLaptop() ? desktopFooter : stickyStyle),
        ...props.style,
        ...props.sx,
      }}
    >
      <Content {...props} />
    </Grid>
  );
}

function Content(props) {
  return props.disabledPaper ? (
    props.children
  ) : (
    <Grid
      variant={props.border ? "outlined" : "none"}
      {...props?.contentProps}
      sx={{
        px: props.px ?? 2.5,
        pt: 0,
        pb: props.pb ?? 2.5,
        py: 1.5,
        ...props?.contentProps?.sx,
      }}
    >
      {props.children}
    </Grid>
  );
}
