import React, { useState, useEffect } from "react";
import axiosAPI from "services/axiosApi";
import { Modal, Select } from "antd";
import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import userStore from "common/stores/user.store";
import shopStore from "common/stores/shop.store";
import { useNavigate, useParams } from "react-router";
import _ from "lodash";
import * as bookingService from "services/booking.service";
import { useSpaceInfo } from "common/hooks/use-query/space.hook";
import Page from "common/components/page";
import { AppBar } from "common/components/nav";
import Loader from "common/components/spinner";
import BillingAddress from "modules/space/booking/billing-address";
import ShopInfo from "modules/space/booking/shop-info";
import Footer from "modules/space/booking/payment/footer";
import BookingDetail from "modules/space/booking/booking-detail";
import bookingStore from "common/stores/booking.store";
import Breadcrumbs from "common/components/breadcrumbs";
import PaymentMethodInfo from "modules/space/booking/payment-method-info";
import { HalfDrawer } from "common/components/drawer";

export default function PreviewPaymentScreen(props) {
  const { user } = userStore();
  const { primaryShop } = shopStore();
  const setBooking = bookingStore((state) => state.setBooking);
  const { spaceId } = useParams();
  const { data: space, isLoading } = useSpaceInfo(spaceId);
  const fromToDate = JSON.parse(localStorage.getItem("from_to_date"));
  const totalDays = _.sumBy(fromToDate, "total_days");
  const [address, setAddress] = useState('');
  const [errorAddress, setErrorAddress] = useState(false);
  const [textErrorAddress, setTextErrorAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  if (user === null || isLoading) {
    return <Loader />;
  }

  async function handleSubmit() {
    setLoading(true);
    if(address.length > 1500) {
      setErrorAddress(true)
      setTextErrorAddress('กรอกที่อยู่ได้เพียง 1500 อักขระเท่านั้น')
    } else {
      const payload = {
        shop_id: primaryShop.id,
        space_id: space?.id,
        booking_days: fromToDate,
        booking_agreement: true,
        billing_address : address,
      };
      try {
        const booking = await bookingService.createBooking(payload);
        setBooking(booking);
        navigate("/space/booking/success");
      } catch (error) {
        Modal.error({
          title: "ผิดพลาด",
          content: error.response.data,
        });
      }
    }
    setLoading(false);
  }

  return (
    <>
      <AppBar link={`/space/mall/booth/${spaceId}`}>
        ตรวจสอบการจองพื้นที่
      </AppBar>
      <Grid container>
        <Container>
          <Breadcrumbs
            menu={[
              { text: "พื้นที่เช่า", to: "/space" },
              { text: space?.name },
            ]}
            sx={{ pb: 2 }}
          />
        </Container>
      </Grid>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} pb={{ xs: "420px", md: 0 }}>
            <Grid component={Paper} container p={3} borderRadius={3}>
              <Box display={{xs: "none", md: "block"}} mb={{xs: "0", md: "10px"}}>
                <Typography fontSize={18} fontWeight="bold">ตรวจสอบการจองพื้นที่</Typography>
              </Box>
              <ShopInfo {...user} {...primaryShop} />
              <BillingAddress 
                isInputBillingAddress={true}
                setAddress={setAddress}
                address={address}
                handleSubmit={handleSubmit}
                errorAddress={errorAddress}
                setErrorAddress={setErrorAddress}
                textErrorAddress={textErrorAddress}
                setTextErrorAddress={setTextErrorAddress}
              />
              <Divider component={Grid} xs={12} sx={{ my: 3 }} />
              <BookingDetail {...space} fromToDate={fromToDate} />
            </Grid>
          </Grid>

          <Grid item sx={{ display: { xs: "none", md: "block" } }} md={4}>
            <FooterContent handleSubmit={handleSubmit} loading={loading} space={space} totalDays={totalDays} />
          </Grid>

          <Grid sx={{ display: { xs: "block", md: "none" } }}>
            <HalfDrawer sx={{ pb: 0 }}>
              <FooterContent handleSubmit={handleSubmit} loading={loading} space={space} totalDays={totalDays} />
            </HalfDrawer>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

function FooterContent(props) {
  return (
    <Grid component={Paper} container p={2} borderRadius={3}>
      <Footer
        onClick={props.handleSubmit}
        loading={props?.loading}
        isRequireApprove
        boothPrice={props?.space?.booth_price}
        totalDays={props?.totalDays}
        totalPrice={Number(props?.totalDays * props?.space?.booth_price).toFixed(2)}
        informationComponent={<PaymentMethodInfo {...props?.space} />}
        {...props?.space}
        {...props}
      />
    </Grid>
  );
}
