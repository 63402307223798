import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Toolbar,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  IconButton,
  Divider,
  ListItemText,
} from "@mui/material";
import { Title, Description } from "common/components/form";
import {
  ChevronRight,
  Help,
  ChatBubbleOutline,
  CancelOutlined,
  Inbox,
  Mail,
  Close,
} from "@mui/icons-material";
import { Group } from "../index";
import { InformationBox } from "../../information";
import ConfirmModal from "./confirm.modal";
import { useModal } from "mui-modal-provider";
import PromptModel from "./prompt.modal";

export default function CancelList(props) {
  const { showModal } = useModal();

  function handleOpenDrawer() {
    const modal = showModal(PromptModel, {
      onClose: () => modal.hide(),
    });
  }

  return (
    <>
      <Group disabledRight onClick={handleOpenDrawer}>
        <CancelOutlined sx={{ color: "#DC3546" }} />
        <Description ml={1} color="#DC3546">
          ยกเลิกการจอง
        </Description>
      </Group>
    </>
  );
}
