import React, { useState } from "react";
import {
  Divider,
  Grid,
  ListSubheader,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

export default function NestedList(props) {
  return (
    <List
      sx={{ width: "100%", bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          <b>{props.header}</b>
        </ListSubheader>
      }
    >
      {props.items.map((e, i) => (
        <Grid>
          {i > 0 && <Divider variant="middle" sx={{ px: 2 }} />}
          <ListItem key={e.label} disablePadding>
            <CustomListItem {...e} />
          </ListItem>
        </Grid>
      ))}
    </List>
  );
}

function CustomListItem(props) {
  const haveIcon = !!props.icon;

  return (
    <ListItemButton
      component={Link}
      to={props.to ?? "#"}
      onClick={props.onClick}
      sx={{
        ":hover": {
          color: "primary.main",
        },
      }}
    >
      {haveIcon && (
        <ListItemIcon
          sx={(theme) => ({
            pl: 2,
            color: "primary.main",
            "& svg": { fill: theme.palette.primary.main },
          })}
        >
          {props.icon}
        </ListItemIcon>
      )}
      <ListItemText
        primary={props.label}
        sx={{
          py: { xs: 0.7, md: 0.3 },
          ...(haveIcon ? { pr: 2 } : { px: 2 }),
        }}
      />
    </ListItemButton>
  );
}
