import React, { useState, useEffect } from "react";
import { Autocomplete, Button, TextField, InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";
import mallStore from "common/stores/mall.store";
import SearchIcon from "@mui/icons-material/Search";
import { useMall } from "common/hooks/use-query/mall.hook";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

export default function SearchBar(props) {
  const {
    setPage,
    tempFilter,
    setTempFilter,
    setFilter,
    setCurLat,
    setCurLng,
  } = mallStore();
  const { data } = useMall();
  const { result_data: malls } = { ...data };

  const optionsMall = malls?.[0]
    ? malls?.map((e) => ({
        type: "เช่าพื้นที่ขายของ",
        label: e.mall_name,
        to: `/space/mall/${e.id}`,
        lat_lng: e.lat_lng,
      }))
    : [];

  const options = [...optionsMall];

  function handleClick(e, value) {
    if (!_.has(value, "lat_lng")) return;

    const { lat_lng } = { ...value };
    const { lat, lng } = { ...lat_lng };

    setCurLat(lat);
    setCurLng(lng);
  }

  return (
    <Autocomplete
      placeholder="หาพื้นที่เช่า"
      fullWidth
      value={tempFilter?.mall_name}
      onChange={handleClick}
      groupBy={(option) => option.type}
      options={options?.[0] ? options : [{ label: "1" }]}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="หาพื้นที่เช่า"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          sx={{
            borderRadius: 3,
            boxShadow: "0px 6px 10px -1px rgba(6, 25, 56, 0.07)",
            "& legend": { width: "auto" },
            "& fieldset": {
              border: "1.5px solid",
              borderColor: "#EBEBEB",
            },
          }}
        />
      )}
      sx={{
        bgcolor: "white",
      }}
    />
  );
}
