import create from "zustand";
import initialTheme from "common/styles/themes";

console.log("initialTheme", initialTheme);
const useStore = create((set) => ({
  theme: {
    muiTheme: initialTheme,
    logo: "/logo/origin-label.png",
  },
  setTheme: (theme) => set({ theme }),
  setMaxWidth: (maxWidth) => set({ maxWidth }),
  desktopRoute: [],
  setDesktopRoute: (desktopRoute) => set({ desktopRoute }),
}));

export default useStore;
