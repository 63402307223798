import { Logout, Portrait } from "@mui/icons-material";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { ReactComponent as ShopImage } from "assets/icon/home/shop.svg";
import { ReactComponent as JournalBookmark } from "assets/icon/outlined/journal-bookmark.svg";
import { ReactComponent as Newspaper } from "assets/icon/outlined/newspaper.svg";
import { ReactComponent as Telephone } from "assets/icon/outlined/telephone.svg";
import logoutImage from "assets/img/main-page/logout.png";
import { useMutateLogout } from "common/hooks/use-query/auth.hook";
import userStore from "common/stores/user.store";
import _ from "lodash";
import ErrorModal from "modules/settings/error/modal";
import { useModal } from "mui-modal-provider";
import ListComponent from "./list.component";

export default function AvatarMenu() {
  return (
    // <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
    <List>
      <UserAvatar />
    </List>
  );
}

export function UserAvatar(props) {
  const user = userStore((e) => e.user);

  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: "primary.main", textTransform: "uppercase" }}>
          {user?.email?.[0]}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography fontSize={14} fontWeight="bold">
            คุณ {user?.first_name} {user?.last_name}
          </Typography>
        }
        secondary={<Typography>{user?.email}</Typography>}
      />
    </ListItem>
  );
}

export function ProfileList(props) {
  const profileRoutes = [
    {
      label: "ข้อมูลส่วนตัว",
      to: "/settings/profile",
      icon: <Portrait />,
    },
    {
      label: "ร้านค้า",
      to: "/settings/store",
      icon: <ShopImage />,
    },
  ];

  return <ListComponent header="บัญชี" items={profileRoutes} />;
}

export function OtherList(props) {
  const user = userStore((e) => e.user);
  const { showModal } = useModal();
  const { mutate: logout } = useMutateLogout();

  const otherRoutes = [
    {
      label: "ข้อกำหนดและเงื่อนไข",
      icon: <JournalBookmark />,
      to: "/terms-and-condition",
    },
    { label: "วิธีใช้การงาน", icon: <Newspaper />, to: "how-to" },
    {
      label: "ช่องทางติดต่อ",
      icon: <Telephone />,
      to: "/contactus",
    },
    {
      label: "ออกจากระบบ",
      icon: <Logout />,
      onClick: () => {
        const logoutModal = showModal(ErrorModal, {
          title: "การออกจากระบบ",
          subtitle: false,
          body: "ท่านต้องการออกจากระบบใช่หรือไม่?",
          image: logoutImage,
          button: "ยืนยัน",
          onClick: () => {
            logout();
            props.onClose();
          },
          secondButton: "ยกเลิก",
          secondOnClick: () => logoutModal.hide(),
        });
      },
    },
  ];

  const noAuthOtherRoutes = [
    {
      label: "ข้อกำหนดและเงื่อนไข",
      icon: <JournalBookmark />,
      to: "/terms-and-condition",
    },
    { label: "วิธีใช้การงาน", icon: <Newspaper />, to: "how-to" },
    {
      label: "ช่องทางติดต่อ",
      icon: <Telephone />,
      to: "/contactus",
    },
  ];

  return (
    <ListComponent
      header="อื่นๆ"
      items={!_.isEmpty(user) ? otherRoutes : noAuthOtherRoutes}
    />
  );
}
