import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Typography, Modal, TextField } from "@mui/material";
import { MuiButton } from "common/components/button";
import { LazyLoadImage as Image } from "react-lazy-load-image-component";
import confirmCancel from "assets/img/confirm-cancel.png";
import * as bookingService from "services/booking.service";
import bookingStore from "common/stores/booking.store";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 320,
  bgcolor: "background.paper",
  textAlign: "center",
  borderRadius: 3,
  py: 4,
  px: 2,
};

export default function ConfirmModal(props) {
  const [note, setNote] = useState("");
  const booking = bookingStore((e) => e.booking);
  const navigate = useNavigate();

  function handleChange(event) {
    setNote(event.target.value);
  }

  async function handleSubmit() {
    try {
      await bookingService.cancelBooking({
        id: booking.booking_id,
        note: props.option.key === "other" ? note : props.option.text,
      });
      navigate("cancel/success");
    } catch (err) {
      console.log(err);
    }
  }

  console.log("props", props);

  return (
    <Modal
      {...props}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography mb={4} fontSize={16} fontWeight="bold">
          ยืนยันการยกเลิกการจองใช่หรือไม่?
        </Typography>
        <Image src={confirmCancel} width="167px" height="142px" />
        <Typography mt={2} mb={2} fontSize={14}>
          {props.option.key !== "other"
            ? `เนื่องจาก${props.option.text}`
            : "โปรดระบุเหตุผลการยกเลิกของคุณ"}
        </Typography>
        {props.option.key === "other" && (
          <Box sx={{ mx: 2, mb: 3 }}>
            <TextField
              id="note"
              label="เหตุผล"
              variant="outlined"
              multiline
              fullWidth
              rows={3}
              onChange={handleChange}
            />
          </Box>
        )}
        <MuiButton
          variant="contained"
          sx={{ fontSize: "16px" }}
          onClick={handleSubmit}
        >
          ยืนยันการยกเลิก
        </MuiButton>
        <MuiButton
          variant="text"
          color="info"
          sx={{ fontSize: "16px", mt: 2 }}
          onClick={props.onClose}
        >
          ย้อนกลับ
        </MuiButton>
      </Box>
    </Modal>
  );
}
