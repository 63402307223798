import React, { useState, useEffect } from "react";
import { Box, Chip, Grid, Typography, Container, Tab } from "@mui/material";
import { LocationOn, ChevronRight } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import BottomNav from "modules/space/bottom-nav";
import EmptyReserved from "modules/space/empty-reserved";
import zonepic from "assets/img/zone.png";
import zonepic_mini from "assets/img/zonepic_mini.png";
import { LazyLoadImage as Image } from "react-lazy-load-image-component";
import moment from "moment";
import { useNavigate } from "react-router";
import shopStore from "common/stores/shop.store";
import * as bookingService from "services/booking.service";
import _ from "lodash";
import {
  BOOKING_STATUS,
  TRANSACTION_STATUS,
  BOOKING_FILTER,
} from "common/constants/booking";
import Loader from "common/components/spinner";
import { CONTRACT_TYPE } from "common/constants/booking";
import paymentStore from "common/stores/payment.store";
import TransactionStatus from "modules/space/booking/transaction-status";
import Breadcrumbs from "common/components/breadcrumbs";
import { AppBar } from "common/components/nav";
import Link from "common/components/link";
import userStore from "common/stores/user.store";
import { ModalLogin } from "common/components/modal";

export default function Booking() {
  const primaryShop = shopStore((e) => e.primaryShop);
  const [isLoading, setIsLoading] = useState(false);
  const [booking, setBooking] = useState([]);
  const { setPayment, setRemainDuration, setPendingPayment } = paymentStore();
  const [tabValue, setTabValue] = useState("1");
  const { checkAuth } = ModalLogin();

  useEffect(() => {
    setRemainDuration(0);
    setPendingPayment(false);
    setPayment({});
    checkAuth();
  }, []);

  useEffect(() => {
    async function getBooking() {
      try {
        setIsLoading(true);
        const data = await bookingService.getBookingAll();
        const filterStore = data.filter((bk) => bk.shop_id === primaryShop.id);
        setBooking(filterStore);
        setIsLoading(false);
      } catch (error) {
        console.log(error.response);
        setBooking([]);
        setIsLoading(false);
      }
    }
    getBooking();
  }, [primaryShop?.id]);

  if (isLoading) return <Loader />;

  return (
    <Container>
      <AppBar link="/space" menu>
        การจองของฉัน
      </AppBar>
      <Grid>
        <Breadcrumbs menu={[{ text: "การจองของฉัน" }]} />
        <Typography fontSize={20} fontWeight="bold">
          การจอง
        </Typography>
      </Grid>
      <Grid mt={2} pb={{ xs: "100px", md: 3 }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={(event, newValue) => setTabValue(newValue)}>
              <Tab label="รอยืนยัน" value="1" />
              <Tab label="สำเร็จ" value="2" />
              <Tab label="ไม่สำเร็จ" value="3" />
              <Tab label="ยกเลิก" value="4" />
            </TabList>
          </Box>

          <TabDetail
            value="1"
            condition="รอยืนยัน"
            booking={
              !_.isEmpty(booking)
                ? booking.filter(BOOKING_FILTER["รอยืนยัน"])
                : []
            }
          />
          <TabDetail
            value="2"
            condition="สำเร็จ"
            booking={
              !_.isEmpty(booking)
                ? booking.filter(BOOKING_FILTER["สำเร็จ"])
                : []
            }
          />
          <TabDetail
            value="3"
            condition="ไม่สำเร็จ"
            booking={
              !_.isEmpty(booking)
                ? booking.filter(BOOKING_FILTER["ไม่สำเร็จ"])
                : []
            }
          />
          <TabDetail
            value="4"
            condition="ยกเลิก"
            booking={
              !_.isEmpty(booking)
                ? booking.filter(BOOKING_FILTER["ยกเลิก"])
                : []
            }
          />
        </TabContext>
      </Grid>
      <BottomNav selected="การจอง" />
    </Container>
  );
}

function TabDetail(props) {
  return (
    <TabPanel value={props.value} sx={{ p: 0 }}>
      {_.isEmpty(props.booking) ? (
        <EmptyReserved />
      ) : (
        <Grid container spacing={2}>
          {props.booking.map((book) => (
            <BookingDetail {...book} condition={props.condition} />
          ))}
        </Grid>
      )}
    </TabPanel>
  );
}

function BookingDetail(props) {
  const [dateRange, setDateRange] = useState([]);

  function BookingStatus() {
    let payload = BOOKING_STATUS[props.booking_status];

    if (props.condition === "ยกเลิก") {
      //prettier-ignore
      if (props.booking_status === "expired") payload = BOOKING_STATUS["expired"];
      //prettier-ignore
      if (props.booking_status === "pending_approval") payload = BOOKING_STATUS["disapproved"];
    }
    try {
      return (
        <Typography fontSize={16} fontWeight="bold" color={payload.color}>
          {payload.label}
        </Typography>
      );
    } catch (err) {
      console.log(err);
      console.log(payload);
    }
  }

  useEffect(() => {
    setDateRange([...props.booking_date_range_info]);
  }, [props.booking_date_range_info]);

  return (
    <Grid
      item
      component={Link}
      xs={12}
      // sm={6}
      md={4}
      mt={2}
      to={`/space/booking/${props.booking_id}`}
    >
      <Grid border="1px solid #DBE8E7" borderRadius="16px">
        <TransactionStatus {...props} />
        <Box
          component={Image}
          height={{ xs: 143, md: 168 }}
          width="100%"
          sx={{
            borderRadius: "16px 16px 0px 0px",
            objectFit: "cover",
          }}
          src={props.mall_images?.[0]?.image_url ?? zonepic}
        />
        <Grid p="11px">
          <Grid
            container
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Typography
              item
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "gray.200",
              }}
            >
              {moment(dateRange?.[0]?.from_date).format("ll")} -{" "}
              {moment(dateRange?.[0]?.to_date).format("ll")}{" "}
              {dateRange.length > 1 ? <>+{dateRange.length - 1}</> : null}
            </Typography>
            <Grid item>{props.booking_number}</Grid>
          </Grid>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography fontSize="16px" mt={1} fontWeight="bold">
              {props.mall_name}
            </Typography>
            <BookingStatus />
          </Grid>
          <Grid container direction="row" columnSpacing={1.5} mt="14px">
            <Grid item xs="auto">
              <Box
                component={Image}
                height={{ xs: 62, md: 67 }}
                width={{ xs: 78, md: 90 }}
                sx={{
                  borderRadius: "12px",
                  objectFit: "cover",
                }}
                src={
                  props.space_images[0]
                    ? props.space_images[0].image_url
                    : zonepic_mini
                }
              />
            </Grid>
            <Grid item xs zeroMinWidth>
              <Grid container direction="column" rowSpacing={1}>
                <Typography noWrap width="inherit">
                  <LocationOn
                    sx={{ mb: -0.5, mr: 0.5, color: "primary.main" }}
                  />
                  {props.space_name}
                </Typography>
                <Grid item>
                  <Chip
                    label={CONTRACT_TYPE[props.space_contract_type]}
                    color="error"
                    size="small"
                    variant="outlined"
                    sx={{ borderRadius: 1.5 }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1} sx={{ textAlign: "right", alignSelf: "center" }}>
              <ChevronRight
                sx={{
                  color: "primary.main",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
