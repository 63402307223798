import { useQuery } from "react-query";
import * as lineService from "services/line.service";

export const useLineLoginQuery = () => {
  return useQuery({
    queryKey: "getLineLogin",
    queryFn: lineService.getLineLogin,
  });
};

export const useLineUrlQuery = () => {
  return useQuery({
    queryKey: "getLineUrl",
    queryFn: lineService.getUrl,
  });
};

export const useLineProfileQuery = () => {
  return useQuery({
    queryKey: "getLineProfile",
    queryFn: lineService.getLineProfile,
  });
};

export const useLineFriendShipQuery = () => {
  return useQuery({
    queryKey: "getLineFriendShip",
    queryFn: lineService.getFriendShip,
    retry: false,
    // enabled: !!isIntegated,
  });
};
