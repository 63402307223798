import NumberFormat from "react-number-format";

export default function Price(props) {
  return (
    <NumberFormat
      displayType="text"
      value={props.value}
      thousandSeparator
      decimalScale={2}
      fixedDecimalScale
    />
  );
}
