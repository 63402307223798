import { useState } from "react";
import { css } from "@emotion/react";
import FadeLoader from "react-spinners/FadeLoader";
import { Box, Container, Grid } from "@mui/material";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function Loader(props) {
  return props.disabledContainer ? (
    <FadeLoaderBody />
  ) : (
    <Container>
      <FadeLoaderBody />
    </Container>
  );
}

export function FadeLoaderBody(props) {
  const color = "#ED4556";

  return (
    <Grid
      container
      textAlign="center"
      justifyContent="center"
      sx={{
        ...(props.style || props.sx),
      }}
    >
      <Grid
        item
        sx={{
          transform: "scale(1.5)",
        }}
      >
        <FadeLoader
          color={color}
          loading
          css={override}
          size={props.size ?? 150}
        />
      </Grid>
    </Grid>
  );
}
