import { Grid, Typography } from "@mui/material";
import LineContent from "assets/img/line/content-01.png";
import { MuiButton as Button } from "common/components/button";
import ModalLineLogin from "common/components/modal/line-login";
import Page from "common/components/page";
import { lineOaUrl } from "common/constants/line";
import addedLineOa from "common/hooks/line/added-oa.hook";
import NavigateHook from "common/hooks/router/navigate.hook";
import loadingStore from "common/stores/loading.store";
import themeStore from "common/stores/theme.store";
import userStore from "common/stores/user.store";
import _ from "lodash";
import { useState } from "react";
import { LazyLoadImage as Image } from "react-lazy-load-image-component";

export default function AddLineOa(props) {
  const { checkLineStatus, lineUrl } = addedLineOa();
  const [loading, setLoading] = loadingStore((state) => [
    state.loading,
    state.setLoading,
  ]);
  const { navigateFromService } = NavigateHook();
  const accessToken = localStorage.getItem("access_token");
  const user = userStore((state) => state.user);
  const theme = themeStore((e) => e.theme);

  const [open, setOpen] = useState(false);

  async function handleOnClick() {
    if (_.isNil(accessToken) || _.isNil(user?.line_login_data)) {
      setOpen(true);
    } else {
      const passed = await checkLineStatus();
      if (passed) {
        window.open(lineOaUrl, "_blank");
        navigateFromService();
      }
    }
  }

  function handleClickLineLogin() {
    localStorage.setItem("line_status", "/add-line-oa");
    window.location.href = lineUrl;
  }

  return (
    <Page>
      <Grid xs={12} textAlign="center">
        <Typography variant="h6" mt="15%" mb={5}>
          <b>เพิ่มเราเป็นเพื่อน</b>
        </Typography>
        <Image alt="line-content-01" height={180} src={LineContent} />
        <Typography variant="h6" mt={5} mb={2}>
          <b>ไม่พลาดทุกความเคลื่อนไหว</b>
        </Typography>
        <Typography variant="h6" mb={8}>
          {" "}
          เพิ่ม <b> {theme.title} </b>
          เป็นเพื่อนของคุณได้แล้ววันนี้เพื่อสามารถติดตามทุกสถานะบริการของคุณได้อย่างรวดเร็วพร้อมรับข่าวสารใหม่ๆ
          ก่อนใคร ฟรี! ไม่ค่าใช้จ่าย
        </Typography>
        <Grid mx={1}>
          <Button
            variant="contained"
            size="large"
            color="red"
            fullWidth
            loading={loading}
            onClick={handleOnClick}
            sx={{
              fontSize: "14px",
              borderRadius: "12px",
              py: "16px",
              mb: 1.5,
            }}
          >
            เพิ่มเราเป็นเพื่อน
          </Button>
          <Button
            variant="text"
            size="large"
            fullWidth
            onClick={navigateFromService}
            sx={{
              fontSize: "15px",
              color: "#A1A6AA",
              py: "12px",
            }}
          >
            เอาไว้ก่อน
          </Button>
        </Grid>
      </Grid>
      <ModalLineLogin
        open={open}
        setOpen={setOpen}
        onClick={handleClickLineLogin}
      />
    </Page>
  );
}
