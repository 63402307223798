import create from "zustand";
import _ from "lodash";
import produce from "immer";
import dayjs from "dayjs";

const initialTempFilter = {
  floor: "",
  mall_name: "",
  company_name: "",
  province: "",
  business_type: "",
  contract_type: "",
  booth_price: { min: "", max: "" },
  date: null,
  booth_length: { min: "", max: "" },
  booth_width: { min: "", max: "" },
};

const useStore = create((set) => ({
  space: {},
  setSpace: (space) => set({ space: space }),
  spaces: [],
  setSpaces: (spaces) => set({ spaces: spaces }),
  availableSpace: [],
  setAvailableSpace: (e) => set({ availableSpace: e }),
  tempFilter: initialTempFilter,
  setTempFilter: (obj) =>
    set(
      produce((draft) => {
        _.map(obj, (value, key) => (draft["tempFilter"][key] = value));
      })
    ),
  filter: initialTempFilter,
  clearFilter: () => set({ tempFilter: initialTempFilter, filter: initialTempFilter }),
  setFilter: () =>
    set(
      produce((draft) => {
        draft["filter"] = draft["tempFilter"];
        if (!_.isEmpty(draft.tempFilter.date)) {
          draft["filter"]["from_date"] = dayjs(draft.tempFilter.date[0]).format("YYYY-MM-DD");
          draft["filter"]["to_date"] = dayjs(draft.tempFilter.date[1]).format("YYYY-MM-DD");
          delete draft["filter"]["date"];
        }
      })
    ),
  spaceGrouping: {},
  setSpaceGrouping: (spaceGrouping) => set({ spaceGrouping: spaceGrouping }),
  spaceGroupingInfo: {},
  setSpaceGroupingInfo: (spaceGroupingInfo) => set({ spaceGroupingInfo: spaceGroupingInfo }),
}));

export default useStore;
